<style lang="less" >
.pd-embed-control{
  position: relative;
  height:100%;
  width:100%;
  overflow: hidden;
  .ivu-date-picker,.ivu-date-picker-rel{
    height:100%;
  }
  .ivu-input-wrapper{
    height:100%;
    position: relative;
  }
  .ivu-input{
    background:none;
    width:100%;
    border:none;
    height:100%;
    margin:0;
    border-radius: 0;
    padding:0 10px;
    height:100%;
    
  }

  .ivu-input-group-append{
    background:#33333333;
    font-size:12px;
    height:100%;
  }

  .ivu-input-disabled{
    background:#ddd !important;
    
  }
}
</style>
<template>
  <div class="pd-embed-control">
  <template v-if="editable">
    
    <DatePicker transfer :value="value" @on-change="handleChange" :disabled="!editable" :placeholder="option && option.placeholder?option.placeholder: '选择日期'" show-week-numbers :options="options" >
     
    </DatePicker>
   
  </template>
  <template v-else>
    <span>{{moment(value).format("YYYY-MM-DD")}}</span>
  </template>
  </div>
</template>

<script>
  export default {
    props:['value','editable','option'],
    methods:{
      handleChange(e){
        this.$emit('input',e)
      }
    },
    computed:{
      options(){
        let date = new Date()
        return {
          shortcuts:[{
            text:"今天",
            value:()=>date
          },{
            text:"7天后",
            value:()=>new Date(date.getTime() + 3600*1000*24*7)
          }]
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>