<template>
  <div class="container" style="padding:10px;background:linear-gradient(to bottom,var(--bg3),var(--bg2))">
    <div class="flex-wrap flex-between tool-box">
     <div class="flex-wrap">
      <ButtonGroup style="">
        <Button @click="viewmode=0" :type="0==viewmode?'primary':'default'" >项目列表</Button>
        <Button @click="viewmode=1" :type="1==viewmode?'primary':'default'" >开票列表</Button>
        <Button @click="viewmode=2" :type="2==viewmode?'primary':'default'" >到账列表</Button>
      </ButtonGroup>

      <Input search style="width:220px;margin-left:10px;" v-model="filter.searchText" placeholder="请输入项目名称查询" />
      
      <Select v-model="filter.timeregion" clearable style="width:200px;margin-left:10px;" placeholder="时间范围" v-if="viewmode>0">
        <Option v-for="t in timeRegions" :key="t.key" :value="t.key">
          {{ t.name }}
        </Option>
      </Select>
      <span style="color:var(--subtext3);margin-left:10px;" v-if="viewmode==0">
        <BaseIcon icon="info" /> 录入开票信息和到账信息请点击项目名称</span>
      </div>

      <div class="flex-wrap"> 
        <Button @click="exportExcel">导出Excel <BaseIcon icon="ios-arrow-forward" /></Button>
        <Button @click="getData" style="margin-left:10px">刷新</Button>
      </div>
      
    </div>
    <div style="height:calc(100% - 40px);position:relative;margin-top:10px;">
      <template v-if="viewmode==0">
        <BaseTable :columns="columns_project" :loading="loading" :data="filteredProjects" :option="{summary:true}" />
      </template>
      <template v-else-if="viewmode==1">
        <BaseTable :columns="columns_bill" :loading="loading" :data="filteredBills" :option="{summary:true}" />
      </template>
      <template v-else-if="viewmode==2">
        <BaseTable :columns="columns_transfer" :loading="loading" :data="filteredTransfers" :option="{summary:true}" />
      </template>
    </div>

    <Drawer v-model="showProject" title="项目收费详情" :width="900">
      <Row  style="padding:0 15px">
        <Col :span="24">
          <BaseSegment :title="project.name" :desc="project.business_type">

<ProjectInfo v-model="project" />
</BaseSegment>

<BaseSegment title="收费节点" desc="合同的主要收费节点">
<div class="flex-wrap split5" slot="extra">
  <BaseBoardButton v-if="editingItem!='conditions'" icon="md-create" name="编辑" @on-click="handleEdit('conditions')" />

  <template v-else>
    <BaseBoardButton icon="save" :loading="loading == 'conditions'" name="保存" type="primary" @on-click="handleSave('conditions')" />

    <BaseBoardButton icon="save" name="取消" @on-click="editingItem=null" />
  </template>

</div>
<ProjectCondition :value="project" v-if="editingItem != 'conditions'" />
<ProjectConditionEditor ref="conditions-editor" v-model="formData" v-else />
</BaseSegment>

<BaseSegment title="重要事项及风险提示" desc="合同的其他重要事项及风险提示">
<div class="flex-wrap split5" slot="extra">
  <BaseBoardButton v-if="editingItem!='memos'" icon="md-create" name="编辑" @on-click="handleEdit('memos')" />

  <template v-else>
    <BaseBoardButton icon="save" :loading="loading == 'memos'" name="保存" type="primary" @on-click="handleSave('memos')" />

    <BaseBoardButton icon="save" name="取消" @on-click="editingItem=null" />
  </template>

</div>
<ProjectMemos :value="project" v-if="editingItem != 'memos'" />
<ProjectMemosEditor ref="memos-editor" v-model="formData" v-else />

</BaseSegment>
<BaseSegment title="调整金额记录" desc="涉及到合同金额调整的相关描述">

<ProjectAdjustRecords v-model="project" />
</BaseSegment>
        <ProjectBillAnalysis :value="project" />
        <BaseSegment title="收费计划" desc="合同的收费计划">

          <ProjectPayplans :value="project" />
        </BaseSegment>
        <BaseSegment title="项目开票" desc="请在此处录入票据信息">
          <div class="flex-wrap" slot="extra">

            <BaseBoardButton v-if="editingItem!='conditions'" icon="md-add" name="新增开票" @on-click="$refs['bill-editor'].add()" />
          </div>
          <ProjectBillEditor ref="bill-editor" v-model="project" @update="updateProject" />
        </BaseSegment>

        <BaseSegment title="项目到账" desc="请在此处录入到账信息">
          <div class="flex-wrap" slot="extra">

            <BaseBoardButton v-if="editingItem!='conditions'" icon="md-add" name="新增到账" @on-click="$refs['transfer-editor'].add()" />
          </div>
          <ProjectTranferEditor  ref="transfer-editor" v-model="project"  @update="updateProject" />
        </BaseSegment>
        </Col>
      </Row>

    </Drawer>
  </div>
</template>

<script>

import moment from 'moment'
import ProjectInfo from '@/components/cm/ProjectInfo'
import ProjectPayplans from '@/components/cm/Payplans'
import ProjectCondition from '@/components/cm/Conditions'
import ProjectConditionEditor from "@/components/cm/EditorConditions"
import ProjectBillEditor from "@/components/cm/EditorBills"
import ProjectMemosEditor from "@/components/cm/EditorMemos"
import ProjectMemos from '@/components/cm/Memos'
import ProjectAdjustRecords from '@/components/cm/AdjustRecords'
import ProjectBillAnalysis from '@/components/cm/project/ProjectBillAnalysis'
import ProjectTranferEditor from '@/components/cm/EditorTransfers'
import { pick, cloneDeep } from "lodash"
import CMR from "../render"
import UTIL from '@/utils'
import {mapGetters} from 'vuex'
import XLSX from 'xlsx'
export default {
  data() {
    return {
      showProject: false,
      viewmode: false,
      filter: {
        searchText: "",
        timeregion:'year'
      },
      formData: {},
      items: [],
      bills: [],
      transfers:[],
      project: {},
      loading: false,
      editingItem: null
    }
  },
  components: {
    ProjectInfo, ProjectCondition, ProjectMemosEditor, ProjectMemos, ProjectAdjustRecords,
    ProjectConditionEditor, ProjectBillEditor, ProjectPayplans, ProjectBillAnalysis, ProjectTranferEditor
  },
  mounted() {
    this.getData()
  },
  methods: {
    exportExcel() {
      let columns = []
      if(this.viewmode == 0)
        columns =  this.columns_project
      else if(this.viewmode == 1)
        columns = this.columns_bill
      else if(this.viewmode == 2)
        columns = this.columns_transfer
      const FixData = v => {
        let res = []
        columns.filter(v => !v.unexport).forEach(c => {
          if (c.formatter) {
            res.push(c.formatter(v[c.key]))
            return
          }

          if (c.type == 'state') {
            if (c.option && c.option.states) {
              res.push(c.option.states[v[c.key]])
            }
          } else if (c.type == 'number' && c.option && c.option.type == 'percent') {
            res.push(parseInt(v[c.key] * 100) / 100 + '%')
          } else if (c.type == 'time') {
            if (v[c.key] && !v[c.key].includes('1900') && !v[c.key].includes('0000'))
              res.push(moment(v[c.key]).format('YYYY/MM/DD'))
            else
              res.push("")
          }else if(c.type == 'user'){
            let id = v[c.key]
            res.push((this.$store.getters['session/users'].find(v=>v.id == id) || {name:id}).name)
          } else {
            res.push(v[c.key])
          }
        })

        return res
      }

      let filteredData = []
      if(this.viewmode == 0)
        filteredData =  this.filteredProjects
      else if(this.viewmode == 1)
        filteredData = this.filteredBills
      else if(this.viewmode == 2)
        filteredData = this.filteredTransfers

      let data = filteredData.map((v, i) => {
        v.index = i + 1

        return FixData(v)
      })
      let date = moment().format("YYYYMMDD")
      const sheet_names = ['项目信息','开票信息','到账信息']
      const filename = `${sheet_names[this.viewmode]}表${date}.xlsx`;

      const workbook = XLSX.utils.book_new()

      let Caption = [filename]
      let title = columns.filter(v => !v.unexport).map(v => v.title)

      let worksheet = XLSX.utils.aoa_to_sheet([Caption, title, ...data])


      XLSX.utils.book_append_sheet(workbook, worksheet, '明细表');

      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const blob = new Blob([wbout], { type: 'application/octet-stream' });
      // save file
      let link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      setTimeout(function () {
        // 延时释放掉obj
        URL.revokeObjectURL(link.href);
        link.remove();
      }, 500);

    },
    getData() {
      this.loading = true
      let url = "cm/projects?q=bill"
      if(this.my_role.extra)
        url += '&dep='+this.my_role.extra
      this.$api.get(url).then(res => {
        let items = res.data.data
        items.sort((a,b)=>{
          if(a.last_bill && b.last_bill){
            return moment(a.last_bill.billed_at).isAfter(b.last_bill.billed_at)?-1:1
          }else if(!b.last_bill){
            return -1
          }else{
            return 1
          }
        })
        this.items = items

      }).finally(() => {
        this.loading = false
      })

      url = "cm/bills"
      if(this.my_role.extra)
        url += '?dep='+this.my_role.extra
      this.$api.get(url).then(res => {
        let bills = res.data.data
        
        this.bills = bills.reverse()
      })

      url = "cm/transfers"
      if(this.my_role.extra)
        url += '?dep='+this.my_role.extra
      this.$api.get(url).then(res => {
        let transfers = res.data.data
        
        this.transfers = transfers.reverse()
      })
    },
    handleCheck(e){
      this.$api.patch("cm/bills/"+e.id+'?q=verify').then(res=>{
        let updateInfo = res.data.data
        updateInfo.id = e.id
        updateInfo.checked_at = moment().format()
        updateInfo.checked_by = this.$store.getters['session/session'].id
        UTIL.updateItem(this.bills,updateInfo)
      })
    },
    updateProject(){
       this.$api.get("projects/" + this.project.id + "?q=cm").then(res => {
        this.project = res.data.data
        UTIL.updateItem(this.items,this.project)
      })
    },
    handleOpen(e) {
    
      this.$api.get("projects/" + e.id + "?q=cm").then(res => {
        
        this.project = res.data.data
        this.showProject = true
      })
    },
    handleEdit(mod) {
      this.formData = cloneDeep(pick(this.project, ['id', 'amount', 'business_type', 'splited', mod]))
      this.editingItem = mod
    },
    handleSave(mod) {
      // local validation
      let modEditor = this.$refs[mod + '-editor']
      if (!modEditor)
        return
      if (!modEditor.validate()) {
        this.Error(modEditor.getError())
        return
      }

      // patching
      const validFields = {
        "conditions": ["business_type", "percent", "amount", "detail", "order"],
        "memos": ["business_type", "detail", "note"]
      }

      let updateData = UTIL.compare_patched(this.project[mod], this.formData[mod], validFields[mod])
      if (updateData.empty) {
        this.editingItem = null
        return
      }

      this.loading = mod
      this.$api.patch(`cm/projects/${this.project.id}?m=${mod}`, updateData).then(res => {
        let updateInfo = res.data.data
        let item = { id: this.project.id, [mod]: updateInfo }
        this.$set(this.project, mod, updateInfo)
        UTIL.updateItem(this.items, item)
        this.editingItem = null
      }).catch(e => this.Error(e)).finally(() => this.loading = null)
    }
  },
  computed: {
    ...mapGetters("cm",['my_role']),
    filteredProjects() {
      return this.items.filter(v => {
        let searchText = this.filter.searchText.trim()
        if (searchText && !v.name.includes(searchText) && !v.code.includes(searchText)) {
          return false
        }
        return true
      })
    },
    timeRegions(){
      return [{
        key:'lastweek',
        name:'最近一周',
        filter:d=>d && moment(d).isAfter(moment().startOf('week'))
      },{
        key:'lastmonth',
        name:'最近一月',
        filter:d=>d && moment(d).isAfter(moment().startOf('month'))
      },{key:'year',
        name:'今年',
        filter:d=>d && moment(d).isAfter(moment().startOf('year'))
      }]
    },
    filteredBills() {
      const keys = ['project_name', '']
      let timeregion = this.timeRegions.find(v=>v.key == this.filter.timeregion)
      return this.bills.filter(v => {
        let searchText = this.filter.searchText.trim()
        if (searchText && !v.name.includes(searchText)) {
          return false
        }
        if(timeregion && timeregion.filter(v.billed_at) == false)
          return false
        return true
      })
    },
    filteredTransfers() {
      const keys = ['project_name', '']
      let timeregion = this.timeRegions.find(v=>v.key == this.filter.timeregion)
      return this.transfers.filter(v => {
        let searchText = this.filter.searchText.trim()
        if (searchText && !v.name.includes(searchText)) {
          return false
        }
        if(timeregion && timeregion.filter(v.transferred_at) == false)
          return false
        return true
      })
    },
    in_dep(){
      return this.my_role.extra
    },
    columns_bill() {
      let fields = [{
        type: "index",
        key:"index",
        title: "序",
        width: 60
      }, {
        type: "time",
        key: "billed_at",
        title: "开票时间",
        width: 80,
        option: {
          type: "date"
        }
      }, {
        type: "text",
        key: "project_name",
        title: "项目名称",
        minWidth: 200
      }, {
        type: "number",
        key: "amount",
        title: "开票金额",
        width: 120,
        option: {
          type: "fullAmount"
        }
      }, {
        type: "number",
        key: "trans_amount",
        title: "已到账",
        width: 120,
        option: {
          type: "fullAmount"
        }
      }, {
        type: "number",
        key: "untrans_amount",
        title: "未到账",
        width: 120,
        option: {
          type: "fullAmount"
        }
      }, {
        type: "number",
        key: "trans_percent",
        title: "到账进度",
        width: 120,
        option: {
          type: "percent",
          percentToValue:100
        }
      }, {
        type: "text",
        key: "condition_detail",
        title: "付款依据",
        width: 220,
      }, {
        type: "text",
        key: "note",
        title: "备注",
        width: 120,
      }, {
        key: "created_at",
        title: "录入时间",
        group: "管理",
        width: 80,
        type: "time",
      },
      {
        key: "created_by",
        title: "录入人",
        group: "管理",
        width: 100,
        type: "user",
        option: {
          getters: "session/users",
        },
      }, {
        key: "verified_at",
        title: "复核日期",
        group: "管理",
        width: 100,
        type: "time",
        render: (h, { row }) => {
          if (!row.verified_at)
            return h("Button", { props: { size: "small", type: "primary" },on:{click:()=>{
              this.handleCheck(row)
            }} }, "复核")
          else
            return h("span", moment(row.verified_at).fromNow())
        }
      }, {
        key: "verified_by",
        title: "复核人",
        group: "管理",
        width: 100,
        type: "user",
        option: {
          getters: "session/users",
        },
      },]

      if(this.my_role.extra)
        return fields.slice(0,-2)
      return fields
    },
    columns_transfer() {
      let fields = [{
        type: "index",
        title: "序",
        
        key:"index",
        width: 60
      }, {
        type: "time",
        key: "transferred_at",
        title: "到账时间",
        width: 80,
        option: {
          type: "date"
        }
      }, {
        type: "text",
        key: "project_name",
        title: "项目名称",
        width: 300
      }, {
        type: "text",
        key: "condition_detail",
        title: "票据信息",
        minWidth: 300,
      }, {
        type: "text",
        key: "note",
        title: "备注",
        width: 120,
      },  {
        type: "number",
        key: "amount",
        title: "到账金额",
        width: 120,
        option: {
          type: "fullAmount"
        }
      }, {
        key: "created_at",
        title: "录入时间",
        group: "管理",
        width: 80,
        type: "time",
      },
      {
        key: "created_by",
        title: "录入人",
        group: "管理",
        width: 100,
        type: "user",
        option: {
          getters: "session/users",
        },
      }]


      return fields
    },
    columns_project() {
      return [{
        type: 'text',
        key: "code",
        title: "编号",
        width: 80,
        group: '项目',
        option: {
          align: "center",
        }
      }, {
        type: 'text',
        title: "名称",
        tree: true,
        key: "name",
        minWidth: 200,
        render: CMR.renderName(this.handleOpen)
      }, {
        type: 'number',
        title: "总金额",
        key: "amount",
        width: 100,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "调整金额",
        key: "adjust_amount",
        width: 100,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "已开票",
        key: "bill_amount",
        width: 100,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "尚可开",
        key: "unbill_amount",
        width: 100,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "开票比例",
        key: "bill_percent",
        width: 80,
        group: '合约',
        option: {
          type: "percent",
          percentToValue:100,
        },
      }, {
        type: 'number',
        title: "已到账",
        key: "trans_amount",
        width: 100,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: "到账比例",
        key: "trans_percent",
        width: 80,
        group: '合约',
        option: {
          percentToValue:100,
          type: "percent"
        },
      }, {
        type: 'number',
        title: moment().year() + "年开票",
        key: "year_bill_amount",
        width: 100,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),
          type: "fullAmount"
        },
      }, {
        type: 'number',
        title: (moment().month() + 1) + "月开票",
        key: "month_bill_amount",
        width: 100,
        group: '合约',
        option: {
          sumable: true,
          formatter: e => UTIL.formatSalary(e),
          type: "fullAmount"
        },
      }, {
        type: 'text',
        title: "上一笔",
        key: "last_bill",
        sortable:false,
        width: 160,
        group: '合约',
        render: (h, { row }) => {
          let bill = row.last_bill
          if (!bill)
            return h("span", "-")

          let domContent = h("span", { style: "text-align:right" }, bill.note || '-')
          let date = bill.billed_at
          let domDate = date ? h('span', moment(date).format("YYYY-MM-DD")) : "-"
          let domAmount = h("BaseAmount", { props: { value: bill.amount } })
          let domSplit = h("Divider", { props: { type: "vertical" } })
          let domTop = h("div", { class: "flex-wrap flex-between", }, [domDate, domSplit, domAmount])
          return h("div", { class: "flex-col align-end" }, [domTop, domContent])
        }
      }, {
        type: 'text',
        title: "下一笔",
        key: "next_plan",
        
        sortable:false,
        width: 160,
        group: '合约',
        render: (h, { row }) => {
          let bill = row.next_plan
          if (!bill)
            return h("span", "-")

          let domContent = h("span", { style: "text-align:right" }, bill.note || '-')
          let date = bill.plan_finished_at || bill.finished_at
          let domDate = date ? h('span', { style: { color: moment(date).isAfter(moment()) ? 'var(--success)' : 'var(--error)' } }, moment(date).format("YYYY-MM-DD")) : "-"
          let domAmount = h("BaseAmount", { props: { value: bill.amount } })
          let domSplit = h("Divider", { props: { type: "vertical" } })
          let domTop = h("div", { class: "flex-wrap flex-between", }, [domDate, domSplit, domAmount])
          return h("div", { class: "flex-col align-end flex-start", style: "padding-right:5px;" }, [domTop, domContent])
        }
      },]


    }
  }
}
</script>
