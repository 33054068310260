<script>
  export default {
    props:{
      data:{
        type:String,
        default:()=>({})
      },
      fields:{
        type:Object,
        default:()=>({})
      },
      value:{
        type:Object,
        default:()=>({})
      }
    },
    computed:{
      groups(){
        return this.$store.getters["session/deps"] || []
      }
    },
    methods:{
      renderField(h,field){
        return h(field.control,{
          props:{
            value:this.value[field.key],
            size:"small",
            placeholder:field.name,
            ...field.option
          },
          style:"width:100px;margin:0 6px"
        })
      },
      renderContent(h,str){
         let [type,content] = str.split(':')
        let [name,link,open] =  content.split(',')
        if(type == 'link'){
          return h('a',{on:{click:()=>{
            this.RouteTo(link,open)
          }}},name)
        }else if(type == 'form-item'){
          let field = this.fields[name]
          if(!field)
            return h('span',`{${name}}`)
          return this.renderField(h,field)
        }else if(type == 'download'){
          return h('a',{on:{
            click:()=>{
              this.Download(link)
            }
          }},name)
        }else if(type == 'event'){
          return h('a',{on:{
            click:()=>{
              this.$bus.$emit(link,open)
            }
          }})
        }else if(type == 'user'){
          return h("BaseNameBoard",{props:{uid:content,size:20,hideAvatar:true},style:{display:"inline-block",position:"relative",margin:"0 5px"}})
        }else if(type == 'project'){
          return h('a',{ style:"margin:0 5px;font-weight:bold;",on:{click:()=>{
            this.RouteTo("/core/projects/"+link,{},true)
          }}},name)
        }else if(type == 'group'){
          let group = this.groups.find(v=>v.id == name)
          return h("a",{ style:"margin:0 5px;font-weight:bold;",on:{click:()=>{
            this.RouteTo("/core/groups/"+name,{},true)
          }}},group.name)
        }
      },
      parse(h,str){
        let matched = /\$\{(.*?)\}/gi.exec(str)
        let segments = []
        if(!matched)
          return [str]
        let ref = this.renderContent(h,matched[1])
        let pre = str.slice(0,matched.index)
        let post = str.slice(matched.index + matched[0].length)
        return [pre,ref,...this.parse(h,post)]
      },
    },
    
    render(h){
      return h('span',this.parse(h,this.data))
    }
  }
</script>