<template>
  <div>
    <BaseUserSelectGrouped :value="value" v-bind="option" :included="included" :excluded="excluded" @input="$emit('input',$event)" v-if="!included_remote || included.length>0" />
    
      <div v-else  >
        
        <div class="flex-wrap" style="height:30px;background:var(--bg2);border:1px solid var(--border);border-radius:3px;width:120px;padding:0 10px;">不可用</div>

        <div style="color:var(--error);margin-left:5px;">暂无可用人员证书,请查看锁定状态进行解锁操作</div>
      </div>
  </div>
  </template>
  
  <script>
  export default {
    props:{
      value:{
          type:Array|String|Number
      },
      option:{
          type:Object,
          default:()=>({})
      },
      included_remote:{
        type:String | Object,
        default:""
      },
      excluded_remote:{
        type:String | Object,
        
        default:""
      }
    },
    data(){
      return {
        included:[],
        excluded:[]
      }
    },
    mounted(){
      if(this.included_remote){
        this.$api.afterLogin(()=>{
          let url = ''
          let key = 'id'
          if(typeof this.included_remote == 'object'){
            url = this.included_remote.url
            key = this.included_remote.key
          }else{
            url = this.included_remote
          }
          this.$api.get(url).then(res=>{
            let users =res.data.data
            this.included = users.map(v=>v[key])
          })
        })
      }
      if(this.excluded_remote){
        this.$api.afterLogin(()=>{
          let url = ''
          let key = 'id'
          if(typeof this.excluded_remote == 'object'){
            url = this.excluded_remote.url
            key = this.excluded_remote.key
          }else{
            url = this.excluded_remote
          }
          this.$api.get(url).then(res=>{
            let users =res.data.data
            this.excluded = users.map(v=>v[key])
          })
        })
      }
    },
  }
  </script>
  
  <style>
  
  </style>