<style lang="less">
.apr-modal-wrap{
  .ivu-modal-close{
    i{
    color:var(--text1) !important;
    font-weight:bold;
    border:2px solid var(--border);
    border-radius: 5px;
    }
  }
}
</style>
<template>
  <App>
    <AppMaskLoading
      title="考核管理 - 评分/管理"
      :loading="loadingAppraisals"
    />
    <AppBar style="box-shadow:0 -1px 2px var(--border) inset;">
      <AppTitle icon="checkreport3" :name="`考核管理${current?(': '+current.name):''}`" />
      当前账号：{{ isAdmin?'系统管理员':(isOB?'系统查看':(isDepAdmin?'事业部管理':'相关责任人')) }} / {{ session.name }} <span v-if="isDepAdmin">归属事业部: {{ deps.filter((v,i)=>permission.deps.includes(i)).join(',') }}</span> 
      <div class="flex-wrap">
        <Select v-model="appraisal_id" style="width:200px" @on-select="handleChangeAppraisal">
        <template v-for="a in appraisals">
          <Option :key="a.id" :value="a.id" :label="a.name">{{ a.name }}</Option>
        </template>
      </Select>
      <Button @click="openAdminConfig" v-if="isAdmin" style="margin-left:10px;padding:0 7px"><BaseIcon icon="config" color="var(--text2)" /></Button>
      </div>
      
    </AppBar>

   

    <AppClient style="overflow:hidden">
      <div
        class="flex-wrap flex-between"
        style="height:70px;border-bottom:1px solid var(--border);width:100%;background:linear-gradient(to right,var(--bg2),var(--bg3))"
      >
        <div class="flex-wrap">
          <div class="flex-wrap apr-btn apr-btn-actived  m-light" style="background:var(--primary);box-shadow:1px 1px 2px var(--subtext2);height:51px;padding-left:20px;" v-if="firstInstances.length > 0 && selected.state == 1" @click="checkFirst=true;showEvaluateEditor=true">
            <div style="font-size:14px;color:var(--hover-text)">责任人评分({{ firstInstances.length }})</div>
              <BaseIcon icon="md-create" style="margin-left:5px;" size="15" color="var(--hover-text)" />
            </div>
            <div class="flex-wrap apr-btn apr-btn-actived m-light" style="background:var(--primary);box-shadow:1px 1px 2px var(--subtext2);height:51px;padding-left:20px;" v-if="isDepAdmin && selected.state == 1" @click="checkFirst=false;showEvaluateEditor=true">
              <div style="font-size:14px;color:var(--hover-text)">事业部评分</div>
                <BaseIcon icon="md-create" style="margin-left:5px;" size="15" color="var(--hover-text)" />
            </div>
            
          <BaseNumboard class="apr-btn" :class="{'apr-btn-actived':fstate==-1,'apr-btn-empty':instances.length == 0}" @click.native="fstate=-1" name="全部人员" :value="instances.length" value-style="color:var(--primary)" reverse />
          <Divider type="vertical" />
          <BaseNumboard class="apr-btn" name="自评中" :class="{'apr-btn-actived':fstate==1,'apr-btn-empty':counts.state[1] == 0}"  @click.native="SelectState(1)" :value="counts.state[1]" reverse />
          <BaseNumboard class="apr-btn" name="评分中" :class="{'apr-btn-actived':fstate==2,'apr-btn-empty':counts.state[2] == 0}"  @click.native="SelectState(2)" :value="counts.state[2]" reverse />

          <BaseNumboard class="apr-btn" name="考核完成" :class="{'apr-btn-actived':fstate==3}"  @click.native="SelectState(3)" value-style="color:var(--success)" :value="counts.state[3]" reverse />
          <Divider type="vertical" />
          <template v-for="(d,i) in deps">
            <BaseNumboard class="apr-btn" :class="{'apr-btn-actived':fdep==i,'apr-btn-empty':dep_counts[i]==0}" :name="d" @click.native="SelectDep(i)" :key="'dep'+i" :value="dep_counts[i]" reverse />
          </template>
          <Divider type="vertical" />
          <template v-for="(p,i) in positions">
            <BaseNumboard class="apr-btn" :class="{'apr-btn-actived':fpos==i,'apr-btn-empty':position_counts[i]==0}" :name="p" @click.native="fpos=i;saveState()" :key="'pos'+i" :value="position_counts[i]" reverse />
          </template>
        </div>
        <div class="flex-wrap">
          <div class="flex-col align-center apr-btn" @click="ExportXLSX_Detail">
            <BaseIcon icon="md-download" :size="30" color="var(--text2)" />分类导出
          </div>
          <div class="flex-col align-center apr-btn" @click="ExportXLSX">
            <BaseIcon icon="md-download" :size="30" color="var(--text2)" />导出
          </div>
          <div class="flex-col align-center apr-btn" @click="showConfig=true;getConfigUsers()" v-if="isAdmin">
            <BaseIcon icon="ios-settings" :size="30" color="var(--text2)" />配置
          </div>
        </div>
      </div>
      <div class="flex-wrap" style="height:40px;border-bottom:1px solid var(--border);box-shadow:0 0 1px  var(--subtext3);position:relative;z-index:5;">
        <div class="flex-wrap split5">
            <Input clearable v-model="searchText" size="small" style="width:160px;margin-right:0;border-top-right-radius:0;border-bottom-right-radius:0;" placeholder="按姓名查找" />
            <Button type="primary" size="small" icon="md-search" style="margin-left:0;border-top-left-radius:0;border-bottom-left-radius:0;padding-right:0"></Button>
            <ButtonGroup>
                <Button size="small" :type="showScore?'default':'primary'" @click="showScore=false;saveState()">员工信息</Button>
                <Button size="small" :type="!showScore?'default':'primary'" @click="showScore=true;saveState()">考核评分</Button>
            </ButtonGroup>
            <ButtonGroup>
                <Button size="small" :type="showSelf?'primary':'default'" @click="showSelf = !showSelf;saveState()">自评</Button>
                <Button size="small" :type="showFirst?'primary':'default'" @click="showFirst = !showFirst;saveState()">第一</Button>
                <Button size="small" :type="showFirstExtra?'primary':'default'" @click="showFirstExtra = !showFirstExtra;saveState()">平行</Button>
                <Button size="small" :type="showSecond?'primary':'default'" @click="showSecond = !showSecond;saveState()">事业部</Button>
                <Button size="small"  @click="showSelf=showFirst=showFirstExtra=false;;saveState()">清除</Button>
            </ButtonGroup>
        </div>
      </div>
      <div style="height:calc(100% - 110px);width:100%;background:#fff">
        <BaseTable 
            :columns="computedColumns" 
            :loading="loading"
            :data="filteredData"
            @event="handleTableEvent"
        ></BaseTable>
      </div>
    </AppClient>
    
    <Drawer v-model="showMigrateModal" title="系统管理" width="400" footer-hide>
        <BaseBoardSubTitle name="权限管理" />
        <AppRole mod="appraisal" />
        <BaseBoardSubTitle name="数据迁移" style="margin-top:10px" />
        <div class="flex-col align-center" style="border:1px solid var(--border);padding:40px;">
            <Button @click="startMigrate">开始导入</Button>
            <div style="font-size:12px;margin-top:10px;color:var(--subtext2)">
                {{ migrateMessage  || '请点击按钮导入历史考核数据' }}
            </div>
        </div>
    </Drawer>

    <Drawer class='l-drawer' :title="`述职报告 ${current?' - '+current.name:''}`" :transfer="false" inner v-model="showPreview" width="800">
   
   <div slot='close' style='font-size:16px;line-height:33px;margin-right:10px;'>
     <a v-if="reportURL"  :href="reportURL" >
       <Icon type='md-download' style='margin-right:5px;' />下载文件
     </a>
     <span v-else>未上传文件</span>
   </div>
  
   <BasePreview :url="reportURL" style="margin:0;" />
 </Drawer>

    <Modal 
      v-model="showEvaluateEditor" 
      :styles="{padding:0}" 
      padding="0" 
      class-name="apr-modal-wrap" 
      fullscreen 
      :transfer="false" 
      footer-hide
      @on-visible-change="handleUpdateScore"
    >
      <ScoreEditor 
        ref="scoreEditor"
        :type="checkFirst?'first':'dep'" 
        :data="checkFirst?firstInstances:depInstances" 
        :editDeps="editDeps" 
      />
    </Modal>

    <Modal v-model="showConfig" footer-hide title="考核配置" :width="1100">
      <div>
        
       
        <div class="flex-wrap">
          <BaseBoardSubTitle name="参加人员范围"></BaseBoardSubTitle>
         
        </div>
        <Input search v-model="config_search" size="small" clearable style="width:200px" placeholder="输入名字搜索" />
        <div class="flex-wrap flex-between" style="margin:10px 0;">
          
        <ButtonGroup>
          <Button size="small" :type="config_dep_id!==-1?'default':'primary'" @click="config_dep_id=-1;">全部 {{ config_users.length }}</Button>
          <template v-for="(d,i) in deps">
                <Button size="small" :type="config_dep_id!=i?'default':'primary'" @click="config_dep_id=i;">{{ d }} {{ config_users.filter(u=>u.dep == i).length }} </Button>
              </template>
            </ButtonGroup>
            <div class="flex-wrap"><Button type="primary" icon="md-add" @click="importExcel" size="small" style="margin-right:10px">从Excel导入</Button><Button type="primary" icon="md-add" @click="showConfigAddUsers=true" size="small">添加</Button></div>
          </div>
          <div class="flex-wrap">
            <ButtonGroup>
          <Button size="small" :type="config_position_id!==-1?'default':'primary'" @click="config_position_id=-1;">全部 {{ filtered_config_users.length }}</Button>
          <template v-for="(d,i) in positions">
                <Button size="small" :type="config_position_id!=i?'default':'primary'" @click="config_position_id=i;">{{ d }} {{ filtered_config_users.filter(u=>u.position == i).length }} </Button>
              </template>
            </ButtonGroup>

          </div>
          <div class="flex-wrap flex-center" style="padding:10px;border:1px solid #ddd;border-radius:5px;margin-top:15px;flex-wrap:wrap;max-height:500px;overflow-y:auto;">
            <BaseLoading v-if="config_loading" />
            <template v-else>
            <BaseEmpty v-if="filtered_config_users_by_positions.length == 0">
            <div>
              空空如也, 点击<a style="margin:0 3px;display:inline" @click="showConfigAddUsers=true">新增人员</a></div>
            </BaseEmpty>

            <template v-else>
              <Row align="middle" :gutter="20" style="width:100%;background:var(--border);">
                <Col :span="4" align="center" style="font-size:12px;font-weight:bold;">
                  姓名
                </Col>
                <Col :span="3" align="center" style="font-size:12px;color:var(--primary);">
                  事业部
                </Col>
                <Col :span="3" align="center" style="font-size:12px;color:var(--primary);">
                  职位
                </Col>
                <Col :span="4" align="center" style="font-size:12px;color:var(--primary);">
                  第一责任人
                </Col>
                <Col :span="6" align="center" style="font-size:12px;color:var(--primary);">
                  平行责任人
                </Col>
                <Col :span="4" align="center" >    
                  操作
                </Col>

                </Row>
            <Row v-for="u in filtered_config_users_by_positions" :key="u.id" align="middle"  :gutter="20"   style="width:100%;margin-top:5px;border-bottom:1px solid #ddd;padding-bottom:5px;">
              <Col :span="4"  style="font-size:12px;font-weight:bold;">
              <BaseNameBoard :uid="u.user_id" style="margin-left:10px;"/></Col>
              <Col :span="3" align="center" style="font-size:12px;color:var(--primary);">
                <template v-if="config_editing_id == u.id">
                <Select v-model="configFormData.dep" transfer  size="small">
                  <Option v-for="(p,i) in deps" :key="p" :value="i" size="small">{{ p }}</Option>
                </Select>
                </template>
                <template v-else>
                  {{ u.dep!==-1?deps[u.dep]:'无' }}
            </template>
             </Col>
              <Col :span="3" style="font-size:12px;color:var(--primary);">
              <template v-if="config_editing_id == u.id">
                <Select v-model="configFormData.position" transfer size="small">
                  <Option v-for="(p,i) in positions" :key="p"  :value="i">{{ p }}</Option>
                </Select>
                </template>
                <template v-else>
              {{ u.position!==-1?positions[u.position]:'无' }}
            </template></Col>
            <Col :span="4"  style="font-size:12px;color:var(--primary);">
              <template v-if="config_editing_id == u.id">
                <div style="height:25px;position:relative">
                  <BaseUserSelectGrouped v-model="configFormData.first_to" size="small" />
                </div>
               
                </template>
                <template v-else>
                  <BaseNameBoard :uid="u.first_to" style="margin-left:10px;"/>
            </template></Col>
            <Col :span="6"  style="font-size:12px;color:var(--primary);">
              <template v-if="config_editing_id == u.id">
                <div style="height:25px;position:relative">
                  <BaseUserSelectGrouped :option="{multiple:true}" v-model="configFormData.second_to" size="small" />
                </div>
               
                </template>
                <template v-else>
                  
                  <BaseNameBoards :uids="u.second_to" showName style="margin-left:10px;"/>
            </template></Col>
              <Col :span="4" >           
              <div class="flex-wrap flex-center">
              <template v-if="config_editing_id == u.id">
             
              <Button icon="md-checkmark" size="small" type="success" @click="patchUser(u.id)" />
              <Button icon="md-close" size="small" @click="config_editing_id=null" style="margin-left:5px;" />
            </template>
            <template v-else>
              <Button icon="md-create" @click="handleConfigEdit(u)" size="small"  />
                <Button icon="md-trash" size="small" @click="removeConfigUser(u.id)" style="margin-left:5px;" /></template>
              </div>
              </Col>
            </Row>
          </template>
        </template>
          </div>

        </div>
      </div>
    </Modal>

    <BaseImportExcel 
      v-model="showConfigImporter"
      ref="importer"
      :columns="importColumns"
      @on-submit="handleImportData"
    />

    <BaseUserSelectGroupedModal 
      v-model="showConfigAddUsers"
      multiple
      :excluded="this.config_users.map(u=>u.user_id)"
      @on-select="addConfigUsers"
    />
   
      
  </App>
</template>

<script>
import App from "@/components/app/app";
import AppBar from "@/components/app/bar";
import AppClient from "@/components/app/client";
import AppTitle from "@/components/app/title";
import AppMaskLoading from "@/components/app/mask-loading";
import moment from 'moment'
import AppLeft from "@/components/app/left";
import XLSX from "xlsx";
import Appraisal2023 from "@/components/appraisals/2024.js";
import UTIL from '@/utils'
import { mapGetters } from "vuex";
import AppRole from '@/components/app/role'
import ScoreEditor from '@/components/appraisals/score_editor'
export default {
  components: { App, AppBar, AppClient, AppTitle, AppMaskLoading, AppLeft,AppRole,ScoreEditor },
  data() {
    return {
      appraisal_id: 2024,
      showMigrateModal:false,
      showConfigImporter:false,
      showConfig:false,
      showScore:true,
      isOB:false,
      importErrors:[],
      permission:{
                deps:[0]
            },
      appraisals: [
        {
          id: 1,
          name: "2024年度员工考核"
        }
      ],
      editDeps:[],
      current:{},
      instances: [],
      loading: false,
      ready: false,
      fstate:-1,
      readed:{},
      fdep:0,
      fpos:0,
      reportURL:"",
      showPreview:false,
      loadingAppraisals:false,
      migrateMessage:'',
      checkFirst:false,
      searchText:'',
      showSelf:true,
      showFirst:true,
      showFirstExtra:true,
      showSecond:true,
      isDepAdmin:false,
      edit_instances:[],
      isAdmin:false,
      counts:{
        state:[0,0,0,0,0],
        dep:[0,0,0,0,0,0,0,0],
        position:[0,0,0,0]
      },
      showEvaluateEditor:false,
      config_users:[],
      config_dep_id:-1,
      config_search:'',
      showConfigAddUsers:false,
      config_editing_id:null,
      config_position_id:-1,
      configFormData:{},
      config_loading:false
    };
  },
  mounted() {
    this.getAppraisals()
   
  },
  computed: {
    ...mapGetters("session", ["users", "session"]),
    ...Appraisal2023.computed,
    importColumns(){
      return [{key:'index',title:'序号',width:60},{
        key:'name',
        title:'姓名',
        width:100
      },{
        key:'dep',
        title:'事业部',
        width:100
      },
      {
        key:'position',
        title:'职位',
        width:100
      },{
        key:'first_to',
        title:'第一责任人(必选)',
        width:140
      },{
        key:'second_to',
        title:'平行责任人(推荐)',
        width:200
      },{
        key:'second_to2',
        title:'平行责任人2(推荐)',
        width:200
      }]
    },
    filtered_config_users(){
      let fusers =  this.config_users.filter(u=>{
        if(this.config_dep_id !== -1 && u.dep !== this.config_dep_id){
          return false
        }
       return true
      })
      if(this.config_search){
        fusers = fusers.filter(u=>u.name.includes(this.config_search))
      }

      return fusers
     
    },
    filtered_config_users_by_positions(){
      if(this.config_position_id == -1)
        return this.filtered_config_users

      return this.filtered_config_users.filter(u=>u.position == this.config_position_id)
    },
    firstInstances(){
      return this.instances.filter(v=>{
        return v.first_to == this.session.id || (v.others_to && v.others_to.includes(this.session.id))
      })
    },
    depInstances(){
      return this.instances.filter(v=>{
        return this.editDeps.includes(v.dep)
      })
    },
    headColumns(){
      var that = this
      return [{ type: "index", title: "序号", fixed: "left" },
        {
          type: "text",
          key: "name",
          width: 50,
          title: "标记",
          sortable: false,
          option: { align: "left" },
          fixed: "left",
          render(h, param) {
            return h(
              "Button",
              {
                props: {
                  size: "small",
                  type: that.readed[param.row.id] ? "success" : "default"
                },
                on: {
                  click: () => {
                    that.$set(that.readed, param.row.id, !that.readed[param.row.id]);
                    that.saveReadState(param.row.id, that.readed[param.row.id]);
                  }
                }
              },
              that.readed[param.row.id] ? "已阅" : "待阅"
            );
          }
        },
        {
          type: "text",
          key: "name",
          width: 80,
          title: "姓名",
          option: { align: "left" },
          fixed: "left",
          render(h, param) {
            return h("span", { style: { fontSize: "14px" } }, [param.row.name]);
          }
        },
       ]
    },
    infoColumns(){
      return [  {
          type: "state",
          key: "dep",
          width: 120,
          title: "部门",
          roles:[],
          option: { align: "center" },
          option: {
            states: [
              "行政综合",
              "房建监理",
              "市政监理",
              "建设管理",
              "装修管理",
              "造价咨询",
              "BIM咨询",
              "总师室",
              '经营部'
            ],
            colors: [
              "#990066",
              "#FFCC00",
              "#009999",
              "#003399",
              "#009966",
              "#663399",
              "#666699",
              "#FF6666",
              "#FF6666",
              "#FF6666"
            ]
          }
        },
        {
          type: "state",
          key: "position",
          width: 120,
          title: "职务",
          option: { align: "center" },
          option: {
            states: [
              "经理/总监(含副)",
              "经理助理/总代",
              "工程师级",
              "助理级/员级"
            ],
            colors: ["#339933", "#CC9900", "#003366", "#336699"]
          }
        },{
          type: "text",
          cat: "person",
          key: "rank",
          width: 120,
          title: "职称",
          option: { align: "center" }
        },
        {
          type: "text",
          cat: "person",
          key: "project",
          width: 200,
          title: "所在项目",
          option: { align: "left" }
        },
        {
          type: "time",
          cat: "person",
          title: "入职时间",
          key: "hire_date",
          width: 120,
          option: { type: "date" }
        },
        {
          type: "text",
          cat: "person",
          key: "certificate",
          width: 200,
          title: "岗位证书",
          option: { align: "center" }
        },
        {
          type: "text",
          cat: "person",
          key: "education",
          width: 200,
          title: "学历",
          option: { align: "center" }
        },
        {
            title:"创建时间",
           type:'time',
           cat:'flow',
            width:100,
           key:"created_at",
           option:{
            type:"date"
           }
          },]
    },
    scoreColumns(){
      var that = this
      return [{
          type: "text",
          key: "report",
          sortable: false,
          width: 120,
          title: "述职报告",
          render(h, param) {
            if (!param.row.report) return h("span", "未上传");
            
            let btnDownload = h(
                "Button",
                {
                  props:{
                    size:"small"
                  },
                  on: {
                    click() {
                      that.current = param.row;
                      let url = param.row.report.url
                      // Histroy Data Fixtrue
                      if(Array.isArray(url)){
                        url = url[0].url
                      }
                      that.DownloadWithName(url,param.row.name+'-述职报告');
                    }
                  }
                },
                "下载"
              );
            let btnPreview = h(
              "Button",
              {
                props:{
                  size:"small"
                },
                on: {
                  click() {
                    that.current=param.row;
                    that.reportURL = param.row.report.url
                    that.showPreview=true;
                  }
                }
              },
              "预览"
            );
            return h('div',{class:'flex-wrap flex-center split5'},[btnDownload,btnPreview])
          }
        },{
            key:"commit",
            type:'text',
            title:"考核评分",
            sortable:false,
            cat:"detail",
            width:620,
            renderHeader(h){
              let titles = ['道德','Q2','Q3','Q4','Q5','Q6','Q7','Q8','Q9','总分']
               let dep = that.fdep
               let pos = that.fpos

              let sheet = Appraisal2023.getQASheet(dep,pos)
              if(sheet)
                sheet.shorts.forEach((v,i)=>{
                  titles[i+1] = sheet.shorts[i]
                })

              titles = titles.map((v,i)=>i<9?(h('Tooltip',{props:{transfer:true,content:i==0?'职业道德':(sheet?sheet.cats[i-1]:v)}},v)):v)

              let nodeDom = h('div',{style:`width:50px;min-width:50px;height:20px;color:#333;text-overflow:eclipse;`},'填报人')
               let nodeDom2 = h('div',{style:`width:50px;min-width:50px;height:20px;color:#333;text-overflow:eclipse;`},'姓名')
              let  colDoms = [0,1,2,3,4,5,6,7,8,9].map((v,i)=>h('div',{
                    style:`width:40px;min-width:40px;height:20px;color:#666;`
                    },[titles[i]]))
              return  h('div',{class:'cell-row',style:"margin:0 5px"},[nodeDom,nodeDom2,...colDoms])
            },
            render(h,param){
              let answers = param.row.answers || []
              let item = param.row
               let nodesName = ['自评','第一','平行','平行','平行','事业部']
               let nodesKey = ['n1','n2','n2','n2','n2','n4']
              let sheets = ['E0','E1','E2','E3','E4','E5']
              let options =  ['10','9.5','9','8.5','8','7.5','7','6.5','6','5.5','5↓']
              let values = [10,9.5,9,8.5,8,7.5,7,6.5,6,5.5,5]
              let sheetObj =  Appraisal2023.getQASheet(param.row.dep,param.row.position)


              let lineDoms = item.executors.map((e,j)=>{

                let node = answers.find(a=>a.q4 == nodesKey[j] && a.created_by == e) || {}
            
                 let op = that.users.find(v=>v.id == e)
                if(!that.showSelf && j==0){
                  return null
                }else  if((!that.showFirst || item.position===0) && j==1)
                  return null
                else if(((op == null|| item.position===0) || !that.showFirstExtra) && (j>1 && j<5))
                  return null
                else if(!that.showSecond && (j == 5))
                  return null
            

            if(!that.isAdmin && !that.isOB && (!that.isDepAdmin) && j ==5)
                  return null


                let score = []
                let nodeDom = h('div',{style:`width:50px;min-width:50px;height:20px;color:#fff;background:#aaa;text-overflow:eclipse;`},nodesName[j])
                let nodeDom2 = h('div',{style:`width:50px;min-width:50px;height:20px;color:#fff;background:${op?'#333':'#ddd'};text-overflow:eclipse;`},op?op.name:'未录入')

                let arr = new Array(10)
                for(let x in node.q1){

                  arr[x] = node.q1[x]
                }
                for(let i=0;i<sheets.length;i++){
                  let sheet = sheets[i]
                  if(Array.isArray(node.q1)){
                    score = arr
                    let sum = 0
                    score.forEach((v,i)=>{
                      if(i==0){
                        if(v)
                          sum = -50
                        else
                          sum = 0
                      }else{
                        sum += (values[v] || 0) * ((sheetObj.heavy[i-1] || 0) / 10)
                      }
                    })
                    score[10] = parseInt(sum)
                    return h('div',{class:'cell-row'},[nodeDom,nodeDom2,score.map((v,vi)=>{
                      if(vi == 0){
                          return v===undefined?'无':(['合格','不合格'][v?v:0])
                      }else if(vi==10){
                        return v===undefined?'无':v
                      }else{
                        return (v===undefined?'无':options[v || 0])
                      }
                    }).map((s,si)=>{
                  return h('div',{
                    style:`width:40px;min-width:40px;height:20px;color:#fff;background:${s=='无'?'#ddd':'#5a6'};filter:hue-rotate(${si<9?(score[si]?score[si]*30:0):((100-score[si])*8)}deg);`
                    },s)
                    })
                    ])
                  }

                }
                for(let n=0;n<10;n++)
                    score[n] = score[n]
                return h('div',{class:'cell-row'},[nodeDom,nodeDom2,score.map((v,vi)=>{
                  if(vi == 0){
                    return '无'
                  }else{
                    return (v===null || v===undefined?'无':options[v])
                  }

                }).map((s,si)=>{
                  return h('div',{
                    style:`width:40px;min-width:40px;height:20px;color:#fff;background:${s=='无'?'#ddd':'darkgreen'};`
                    },s)
                })])
              }).filter(s=>s)
              return h('div',{class:'cell-row-wrapper',style:'margin:0 5px'},[lineDoms])
            }
          },
          {
            title:"调查评估",
            sortable:false,
            cat:"detail",
            width:720,
             renderHeader(h){
              let titles=['饱满度','Q2','Q3','Q4','Q5','Q6','Q7','Q8']
              let pos = that.fpos
              let node = that.showSelf?'n1':(that.showFirst?'n2':'n4')
              let sheet = Appraisal2023.getEVSheet(pos,node)

              if(sheet){
                sheet.questions.forEach((v,i)=>{
                  titles[i] = sheet.questions[i].title
                })

              }
              let  colDoms = titles.map((v,i)=>h('div',{
                    style:`width:80px;min-width:80px;height:20px;color:#333;`
                    },[titles[i]]))
              return  h('div',{class:'cell-row'},[...colDoms])
            },
            render(h,param){
              let nodes=['n1','n2','n2','n2','n2','n4']
              let sheets = Appraisal2023.getEVSheets(param.row,nodes)
                let item = param.row
            let nodesKey = ['n1','n2','n2','n2','n2','n4']
                if(!(item.executors))
                  return h('span','配置失效')



              let lineDoms =item.executors.map((e,j)=>{
                 let op = that.users.find(v=>v.id == e)
                let node = item.answers.find(a=>a.q4 == nodesKey[j] && a.created_by == e) || {}
                 if(!that.showSelf && j==0){
                  return null
                }else  if((!that.showFirst || item.position===0) && j==1)
                  return null
                else if((op == null|| item.position===0 || !that.showFirstExtra) && (j>1 && j<5))
                  return null
                else if(!that.showSecond && (j == 5))
                  return null

                if(!that.isAdmin && !that.isOB && (!that.isDepAdmin) && j ==5)
                  return null
                
                 let score = []

                  let sheet = sheets[j]

                  if(Array.isArray( node.q3) && op){
                    let rawscore = node.q3


                    score = new Array(sheet.questions.length)
                    for(let n=0;n<score.length;n++)
                      score[n] = rawscore[n]

                    return h('div',{class:'cell-row'},[score.map((v,vi)=>{

                        return (typeof v != 'number'?'无':sheet.questions[vi].options[v || 0])


                    }).map((s,si)=>{
                  return h('div',{
                    style:`width:80px;min-width:80px;height:20px;color:#fff;background:${s=='无'?'#dfdfdf':'#5a6'};filter:hue-rotate(${score[si]?score[si]*(300/sheet.questions[si].options.length):0}deg);`
                    },s)
                    })
                    ])


                }
                for(let n=0;n<8;n++)
                    score[n] = score[n] || null
                return h('div',{class:'cell-row'},[score.map((v,vi)=>{

                    return (v===null || v===undefined?'无':options[v])


                }).map((s,si)=>{
                  return h('div',{
                    style:`width:80px;min-width:80px;height:20px;color:#fff;background:${s=='无'?'#ddd':'darkgreen'};`
                    },s)
                })])
              }).filter(s=>s)
              return h('div',{class:'cell-row-wrapper'},[lineDoms])
            }
          },
          {
            key:"commit",
            type:'text',
            title:"考核评优/推荐",
            sortable:false,
            width:450,
             renderHeader(h,param){
              let titles = ['评级','推荐','评语']
              let  colDoms = [0,1,2].map((v,i)=>h('div',{
                    style:`width:60px;min-width:60px;height:20px;color:#333;`
                    },[titles[i]]))
              return  h('div',{class:'cell-row'},[...colDoms])
            },
            render(h,param){

              let nodesKey = ['n1','n2','n2','n2','n2','n4']
              let executors =param.row.executors
              if(!(executors))
                return h('span','配置失效')
             let item = param.row
              let fields = ['CommitLevel','CommitPride','Commit']
                 let lineDoms =item.executors.map((e,j)=>{
                   if(j==0)
                    return null



                 let op = that.users.find(v=>v.id == e)
                let node = item.answers.find(a=>a.q4 == nodesKey[j]&& a.created_by == e)  || {}

                  if(!that.isAdmin && (!that.editDeps || !that.editDeps.includes(item.dep)) && node &&  node.q4=='n4')
                  return null
                 if(!that.showSelf && j==0){
                  return null
                }else  if((!that.showFirst || item.position===0) && j==1)
                  return null
                else if((op == null || !that.showFirstExtra || item.position===0) && (j>1 && j<5))
                  return null
                else if(!that.showSecond && (j == 5))
                  return null
                  if(!that.isAdmin && !that.isOB && (!that.isDepAdmin) && j ==5)
                  return null

                let scores = []

                for(let i=0;i<fields.length;i++){
                  let f = fields[i]

                  if(node[f]){
                    scores[i] = node[f]
                  }else{
                    scores[i] ='无'
                  }

                }

                if(scores.length > 0)
                  return h('div',{class:'cell-row'},[
                        scores.map(v=>v===null?'无':v).map((s,si)=>{
                          if(si < 2){
                            return h('div',{style:`width:60px;min-width:60px;height:20px;color:#fff;background:${s=='无'?'#ddd':that.mapColor(s[0])}`},s)
                          }else{
                            return h('Tooltip',{props:{maxWidth:400,minWith:400,content:s,transfer:true}},[h('div',{style:`max-width:200px;text-overflow:ellipsis;overflow:hidden;height:20px;ecllipse;text-align:left;padding:0 5px;white-space:nowrap;line-height:25px;`},s)])
                          }

                      })])
              }).filter(s=>s)


              return h('div',{class:'cell-row-wrapper',style:{alignItems:"flex-start"}},that.showSelf?[h('div',{class:'cell-row'},''),lineDoms]:[lineDoms])

            }
          },
          
]
    },
    computedColumns() {
      return [
        ...this.headColumns,
        ...(this.showScore?this.scoreColumns:this.infoColumns)        
      ];
    },
    selected(){
      return this.appraisals.find(a=>a.id == this.appraisal_id) || {}
    },
    filteredDataByState(){
      if(this.fstate !== -1)
        return this.instances.filter(v=>v.state == this.fstate)
      else
        return this.instances
    },
    
    filteredDataByDep(){
      let records = this.filteredDataByState
      
       return records.filter(v=>{
          if(this.searchText && (!v.name || !v.name.includes(this.searchText)))
            return false
          if(this.fdep !== v.dep){
            return false
          }
          return true
       })
    },
    dep_counts(){
      let counts = [0,0,0,0,0,0,0,0,0,0]
        this.filteredDataByState.forEach(v=>{
          counts[v.dep]++
        })
        return counts
    },
    position_counts(){
      let counts = [0,0,0,0,0,0,0,0]
        this.filteredDataByDep.forEach(v=>{
          counts[v.position]++
        })
        return counts
    },
    filteredData(){
      return this.filteredDataByDep.filter(v=>{
         if(this.fpos != v.position){
          return false
        }
        return true
      })
    },
  },
  methods: {
    importExcel(){
      this.$refs.importer.importExcel()
      this.showConfigImporter = true
      this.importErrors = []
      
    },
    handleImportData(items){
      const positions = ['经理/总监(含副)', '经理助理/总代', '工程师级','助理级/员级']
      const deps = ['公司行政系统','房建监理事业部','市政监理事业部','建设管理事业部','装修管理事业部', '造价咨询事业部', 'BIM','总师室','经营部']
      let formattedItems = []
      items.forEach(e=>{
        let item = {appraisal_id:this.appraisal_id}
        let user = this.users.find(v=>e.name == v.name)
        if(!user){
          this.importErrors.push(`${e.name}:用户不存在`)
          return
        }
        item.user_id = user.id
        item.name = user.name
        let dep = deps.findIndex(d=>d == e.dep)
        if(dep == -1){
          this.importErrors.push(`${e.name}:部门${e.dep}无法匹配`)
          return
        }
        item.dep = dep
        let position = positions.findIndex(p=>p == e.position)
        if(position == -1){
          this.importErrors.push(`${e.name}:职位${e.position}无法匹配`)
          return
        }
        item.position = position
        if(e.first_to){
          
          let first_to = this.users.find(u=>u.name == e.first_to)
          if(!first_to){
            this.importErrors.push(`${e.name}:第一责任人${e.first_to}无法匹配`)
          }else{
            item.first_to = first_to.id
          }
        }

        let second_tos = []
        if(e.second_to){
          let second_to = this.users.find(u=>u.name == e.second_to)
          if(!second_to){
            this.importErrors.push(`${e.name}:第二责任人${e.second_to}无法匹配`)
          }else{
            second_tos.push(second_to.id)
          }
        }
        if(e.second_to2){
          let second_to2 = this.users.find(u=>u.name == e.second_to2)
          if(!second_to2){
            this.importErrors.push(`${e.name}:第二责任人${e.second_to2}无法匹配`)
          }else{
            second_tos.push(second_to2.id)
          }
        }
        if(second_tos.length > 0)
          item.second_to = second_tos
        formattedItems.push(item)
      })
      this.Confirm(`存在以下问题,<div style="height:100px;overflow-y:auto;">${this.importErrors.map(v=>`<div style="color:var(--error);font-size:12px;">${v}</div>`).join('')}<div>是否继续(忽略问题数据)?</div>`,()=>{
        this.$api.post('oa/appraisal-users',formattedItems).then(res=>{
          this.Success('导入成功')
          this.showConfigImporter = false
          this.getConfigUsers()
        })
      })
    },
    getConfigUsers(){
      this.config_loading = true
      this.$api.get('oa/appraisal-users?appraisal_id='+this.appraisal_id).then(res=>{
        this.config_users = res.data.data
      }).finally(()=>{
        this.config_loading=false
      })
    },
    addConfigUsers(user_ids){
      
      this.$api.post('oa/appraisal-users?q=ids',{user_ids,appraisal_id:this.appraisal_id}).then(res=>{
        let updateInfo = res.data.data
        this.config_users = this.config_users.concat(updateInfo)
      })
    },
    handleConfigEdit(u){
      this.configFormData = {
        dep:u.dep,
        position:u.position,
        first_to:u.first_to,
        second_to:u.second_to
      }
      this.config_editing_id=u.id;
    },
    removeConfigUser(id){
      this.$api.delete('oa/appraisal-users/'+id).then(res=>{
        let index = this.config_users.findIndex(t=>t.id === id)
        this.config_users.splice(index,1)
      })
    },
    patchUser(id){
      let model = this.configFormData
      if(Array.isArray(model.first_to)){
        model.first_to = null
      }
      this.$api.patch('oa/appraisal-users/'+id,model).then(res=>{
        let index = this.config_users.findIndex(t=>t.id == id)
        let item = Object.assign({},this.config_users[index],model)
        this.config_users.splice(index,1,item)
        this.Success("修改成功")
        this.config_editing_id = null
      })
    },
    saveAppraisalState(){
      localStorage.setItem('appraisal'+this.session.id,JSON.stringify({appraisal_id:this.appraisal_id}))
    },
    restoreAppraisalState(){
      let savedState = localStorage.getItem('appraisal'+this.session.id)
      if(savedState){
        savedState = JSON.parse(savedState)
        if(savedState.appraisal_id && this.appraisals.find(a=>a.id == savedState.appraisal_id)){
          this.appraisal_id = savedState.appraisal_id
          return
        }
      }

      if(this.appraisals.length > 0){
          this.appraisal_id = this.appraisals[this.appraisals.length-1].id
      }
    },
    saveState(){
      let savedState = {
        
        fpos:this.fpos,
        fstate:this.fstate,
        fdep:this.fdep,
        showScore:this.showScore,
        showSelf:this.showSelf,
        showFirst:this.showFirst,
        showFirstExtra:this.showFirstExtra,
        showSecond:this.showSecond
      }
  
    localStorage.setItem('appraisal'+this.session.id+this.appraisal_id,JSON.stringify(savedState))
    },
    saveReadState(id,state){
      if(state)
        this.readed[id] = state
      else
        delete this.readed[id]
      localStorage.setItem('appraisal_state',JSON.stringify(this.readed))
    },
    restoreState(){

      let readed = localStorage.getItem('appraisal_state')
      try{
        readed = JSON.parse(readed) || {}
      }catch(e){
        readed = {}
      }
      this.readed = readed

      let savedState = localStorage.getItem('appraisal'+this.session.id+this.appraisal_id)
      if(savedState){
        savedState = JSON.parse(savedState)
        for(let key in savedState){
          if(savedState[key] != -1 || key =='fstate')
            this[key] = savedState[key]
        }
        
        if(this.fdep != -1){
          this.SelectDep(this.fdep)
          return
        }
      }

      for(let i=0;i<this.dep_counts.length;i++){
        if(this.dep_counts[i] > 0){
          this.SelectDep(i)
          return
        }
      }
    },
    handleChangeAppraisal(e){
      
      this.saveState()
      this.appraisal_id = e.value
      this.saveAppraisalState()
      this.getData()
    },
    getAppraisals(){
        this.loadingAppraisals = true
        this.$api.get('/oa/appraisals').then(res=>{
            this.appraisals = res.data.data
            this.restoreAppraisalState()
            this.getData()
        }).finally(()=>{
          setTimeout(()=>{
            this.loadingAppraisals = false
          },1000)
        })
    },
    getData() {
      this.$api.get(`oa/appraisals/${this.appraisal_id}`).then(res=>{
        this.current = res.data.data
        this.getPageData()
      })
    },
    SelectState(s){
      this.fstate = s
      if(this.fdep == -1 || this.dep_counts[this.fdep] == 0){
        for(let i=0;i<this.dep_counts.length;i++){
          if(this.dep_counts[i] > 0){
            this.SelectDep(i)
            return
          }
        }
        this.fdep = -1
      }
      
      this.saveState()
    },
    SelectDep(d){
      this.fdep = d
      if(this.fpos == -1 || this.position_counts[this.fpos] == 0){
        for(let i=0;i<this.position_counts.length;i++){
          if(this.position_counts[i] > 0){
            this.fpos = i
            this.saveState()
            return
          }
        }
        this.fpos = -1
      }
      this.saveState()
    },
    mapColor(ch){
      return UTIL.mapColor(ch)
    },
    handleUpdateScore(){
      let changed = this.$refs.scoreEditor.changed
      if(changed)
        this.getPageData()
    },
    getPageData(){
        this.loading = true
        let uid = this.session.uid
        this.$api.get(`oa/appraisal_instances?related=answers,others_to&appraisal_id=${this.appraisal_id}`).then(res=>{
            let {is_admin,is_ob,is_dep_admin,deps,instances} = res.data.data
            this.isDepAdmin = is_dep_admin
            this.permission.deps = deps
            this.isAdmin = is_admin
            this.isOB = is_ob
            this.editDeps = deps
            let counts = {
              state:[0,0,0,0]
            }
            instances.forEach(v=>{
              counts.state[v.state]++
              if(!v.others_to)
                v.others_to = []
              if(Array.isArray(v.answers)){
                v.answers.sort((a,b)=>a.q1 > b.q1?1:-1)
                v.executors = v.answers.map(v=>v.created_by)
              }
              else{
                v.executors = []
                v.answers = []
              }

            v.ops = {}
            v.opusers = {}
            v.depName = ['行政综合', '房建监理', '市政监理', '建设管理', '装修管理', '造价咨询', 'BIM咨询','经营部'][v.dep]
            v.posName = ['经理/总监(含副)', '经理助理/总代', '工程师级', '助理级/员级'][v.position]

     
   
            if (v.answers) {
              v.answers.forEach(n => {
                n[n.q0] = n.q1
                n[n.q2] = n.q3
                n.nodeName = n.q4
              })
            }

      let firstAnswer = v.answers.find(a=>a.created_by == v.first_to && a.created_by == uid)
      if(!firstAnswer)
        firstAnswer = v.answers.find(a=>v.others_to.includes(a.created_by) &&  a.created_by == uid)
      if(firstAnswer){
        v.Commitn2 = firstAnswer.Commit
        v.CommitLeveln2 = firstAnswer.CommitLevel
        v.CommitPriden2 = firstAnswer.CommitPride
        v.hasN2 = true
      }
      let self = v.answers.find(a=>a.created_by == v.created_by)
      if(self){
        v[self.q0] = self.q1
        v[self.q2] = self.q3
      }

      let SecondAnswer =  v.answers.find(a=>a.q4 == 'n4')
      if(SecondAnswer){
      v.Commitn4 = SecondAnswer.Commit
      v.CommitLeveln4 = SecondAnswer.CommitLevel
      v.CommitPriden4 = SecondAnswer.CommitPride
      v.hasN4 = true
      }

      let executors = [v.created_by,v.first_to]
              if(Array.isArray(v.others_to) && v.others_to.length > 0)
                executors = executors.concat(v.others_to)
              executors[5] = null
      let depanswer = v.answers.find(v=>v.q4 == 'n4')
      if(depanswer){
        executors[5] = depanswer.created_by
        v[depanswer.q0] = depanswer.q1
        v[depanswer.q2] = depanswer.q3
      }
            
      v.executors = executors
          let qasheet = Appraisal2023.getQASheet(v.dep, v.position)

          v.scores = v[qasheet.key+'n4'] || new Array(9)
      //     console.log(n,qasheet.key+n.nodeName,n.scores)
          v.totalScore = Appraisal2023.CalcScore(qasheet, v.scores) || '-'
          const scores = [10, 9.5, 9, 8.5, 8, 7.5, 7, 6.5, 6, 5.5, 5]
          v.scoresDesc = v.scores.map(v => typeof v == 'number' ? scores[v] : '-')
          v.scoresDesc[0] = v.scoresDesc == 9.5 ? '不合格' : '合格'
        
          v.QN4 = new Array(8)
          if (v.dep2mgr || v.dep2mem)
            Object.assign(v.QN4, v.dep2mgr || v.dep2mem)
          let evsheet = Appraisal2023.getEVSheet(v, 'n4')
          v.evDesc = v.QN4.map((q, i) => typeof q == 'number' ? evsheet.questions[i].options[q] : '-')

     


    })
            
            this.counts = counts
            this.instances = instances
            this.restoreState()
        
            
        }).finally(()=>{
            this.loading = false
        })
    },
    ExportXLSX(){
      let title = this.selected.name
      const filename = `${title}汇总表.xlsx`;

      const workbook = XLSX.utils.book_new()
      let filteredData = []
      let filteredTitles = []
       let Caption = [`${title} - 汇总表`]
    this.dep_counts.forEach((v,i)=>{
        if(v > 0){
          let depusers = this.instances.filter(v=>v.dep == i)
          let title = ['序号','姓名','部门','职务','所在项目','职称','岗位证书','学历','入职时间']
          this.positions.forEach((p,pi)=>{
            let pos_name = this.positions[pi]
            let users = depusers.filter(v=>v.position == pi)
            if(users.length <= 0)
              return
            let scoreTitle = ['职业道德',...Appraisal2023.getQASheet(i,pi).cats,"总分"]
            let qaTitle = Appraisal2023.getEVSheet(p,'n4').questions.map(v=>v.title)
            let cmtTitle = ['评级','推荐','评语']
            let SheetTitle = [...title,...scoreTitle,...qaTitle,...cmtTitle]
            let data = users.map((v,i)=>[i+1,v.name,this.deps[v.dep],this.positions[v.position],v.project,v.rank,v.certificate,v.education,moment(v.hire_date).format('YYYY-MM'),...v.scoresDesc,v.totalScore,...v.evDesc,v.CommitLeveln4,v.CommitPriden4,v.Commitn4])

            filteredTitles = [...SheetTitle.slice(0,9),SheetTitle[23],SheetTitle[27],SheetTitle[28]]
            filteredData = filteredData.concat(data.map(v=>[...v.slice(0,9),v[23],v[27],v[28]]))




          })

        }
      })

      filteredData.forEach((v,i)=>{
        v[0] = i+1
      })
      let worksheet = XLSX.utils.aoa_to_sheet([Caption,filteredTitles,...filteredData])

            worksheet["!merges"]=[{s:{
              c:0,r:0
            },e:{
              c:28,r:0
            }}]
            worksheet["A1"].s = {
              font:{sz:24,bold:true},
              alignment:{horizontal:"center",vertical:"center",wrap_text:true}

            }
     XLSX.utils.book_append_sheet(workbook, worksheet,'汇总表');

     const wbout = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
      const blob = new Blob([wbout], {type: 'application/octet-stream'});
      // save file
      let link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      setTimeout(function() {
          // 延时释放掉obj
          URL.revokeObjectURL(link.href);
          link.remove();
      }, 500);
    },
    ExportXLSX_Detail(){
      let title = this.selected.name
      const filename = `${title}分类明细表.xlsx`;

      const workbook = XLSX.utils.book_new()
      this.dep_counts.forEach((v,i)=>{
        let dep_name = this.deps[i]
        if(v > 0){
          let depusers = this.instances.filter(v=>v.dep == i)
          let caption = "年终考核分类明细表"
          let title = ['序号','姓名','职务','所在项目','职称','岗位证书','学历','入职时间']
          
          this.positions.forEach((p,pi)=>{
            let pos_name = this.positions[pi]
            let users = depusers.filter(v=>v.position == pi)
            if(users.length <= 0)
              return

            let scoreTitle = ['职业道德',...Appraisal2023.getQASheet(i,pi).cats,"总分"]

            let qaTitle = Appraisal2023.getEVSheet(p,'n4').questions.map(v=>v.title)
            let cmtTitle = ['评级','推荐','评语']
            let SheetTitle = [...title,...scoreTitle,...qaTitle,...cmtTitle]

           

            let data = users.map((v,i)=>[i+1,v.name,this.positions[v.position],v.project,v.rank,v.certificate,v.education,moment(v.hire_date).format('YYYY-MM'),...v.scoresDesc,v.totalScore,...v.evDesc,v.CommitLeveln4,v.CommitPriden4,v.Commitn4])

      

            let Caption = [`${caption} - (${dep_name+'-'+pos_name})`]
            let worksheet = XLSX.utils.aoa_to_sheet([Caption,SheetTitle,...data])
            pos_name = pos_name.replace('/','-')
            // console.log(worksheet)
            worksheet["!merges"]=[{s:{
              c:0,r:0
            },e:{
              c:28,r:0
            }}]
            worksheet["A1"].s = {
              font:{sz:24,bold:true},
              alignment:{horizontal:"center",vertical:"center",wrap_text:true}

            }
         
            XLSX.utils.book_append_sheet(workbook, worksheet, dep_name+'-'+pos_name);
          })

        }
      })


     const wbout = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
      const blob = new Blob([wbout], {type: 'application/octet-stream'});
      // save file
      let link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
      setTimeout(function() {
          // 延时释放掉obj
          URL.revokeObjectURL(link.href);
          link.remove();
      }, 500);
    },
    openAdminConfig(){
      this.showMigrateModal=true
     
    },
    startMigrate(){
        this.migrateMessage = '正在导入'
        this.$api.post('oa/system/migrate').then(res=>{
            this.migrateMessage = '导入完毕'
            this.showMigrateModal = false
            this.appraisal_id = res.data.data
            this.getData()
        }).catch((e)=>{
          this.Error(e)
        })
    },
    handleTableEvent(){

    },
    
    getCountAll() {},
    getReportDownload(name) {
      this.Download()
    }
  }
};
</script>

<style lang="less">
.apr-btn{
    padding:5px 10px;
    margin-left:5px;
    border-radius:5px;
    color:var(--subtext2);
    font-size:12px;
    .l-value{
        font-family: PingFang-SC;
        font-size:16px;
        font-weight: bold;
    }
}

.apr-btn:hover{
    box-shadow:1px 1px 1px var(--border);
    filter:brightness(1.1);
    cursor: pointer;
    background:var(--bg2);
    
}

.apr-btn:active{
    position:relative;
    bottom:-1px;
    right:-1px;
}

.apr-btn-actived,.apr-btn-actived:hover{
    background:var(--warning);
    color:var(--hvoer-text);
    .l-name{
        color:var(--hover-text) !important;
    }
    .l-value{
        color:var(--hover-text) !important;
    }
    
    box-shadow: 1px 1px 3px 5px var(--border) inset;
    border-left:1px solid var(--border);
    border-top:1px solid var(--border);
}

.apr-btn-actived{
  
}

.apr-btn-empty,.apr-btn-empty:hover{
    filter:none;
    background:none;
    opacity:0.5;
    .l-value{
        font-weight: normal;
    }
    pointer-events: none !important;
}

.cell-row{
  height:30px;
  border-bottom:1px solid #dfdfdf;
  display: flex;
  align-items: center;
  >*{
    margin-right:10px;
  }

  .cell-node{
    background:#aaa;
    padding:0 5px;
    border-radius: 3px;
    color:#fff;
    margin-right:10px;
  }
}

.ap-table-wrap{
  .ivu-table-fixed-body{
    background:#fff;
  }
}

.cell-row:last-child{
  border:none;
}

.cell-row-wrapper{

}

.ivu-table-wrapper{
  overflow-x:auto;
}

.apr-modal-wrap{
  left:42px !important;
  .ivu-modal-body{
    padding:0;
    height:100%;
    position: relative;
  }
}
</style>