<template>
  <div
    class="l-task flex-wrap flex-between flex-col align-start"
    :class="{'l-task-finished':t.state==1}"
    :style="`--idelay:${index*0.1}s;`"
    :key="t.id"
  >
    <div class="flex-wrap flex-line flex-between" style="width:100%">
      <div class="flex-wrap" style="flex-grow:1">
        <!-- code -->
        <div class="l-code">{{index+1}}</div>
        <!-- priority -->
        <div
          class="flex-wrap flex-center l-code"
          :style="`width:40px;text-align:center;background:${priorities[t.priority || 0].color};color:#fff;margin:0 10px`"
        >{{priorities[t.priority || 0].name}}</div>

        <!-- nameboard -->
        <BaseNameBoard  v-if="multiUsers" :uid="t.charger_id || t.created_by" :size="12" style="width:75px;padding-left:10px;margin-right:10px;border-radius:5px;box-shadow:1px 1px 2px 1px var(--border);font-size:10px;" :style="`background:${self==(t.charger_id || t.created_by)?'var(--bg2)':'var(--primary)'}`" :styles="`color:${self==(t.charger_id || t.created_by)?'var(--text3)':'var(--hover-text)'}`"  />
        
        <!-- title -->
        <div class="flex-wrap">
        <div class="l-tag" style="color:var(--active);margin-right:10px;" v-if="t.task_id">复合计划</div>
        <div>
         
          <div style="font-size:14px;padding:0 5px;">{{t.content}}
            <template v-for="tag in t.tags">
              <BaseTag2 :key="t.id + tag">{{ tag }}</BaseTag2>
            </template>
            <div class="l-tag hover-lighter" style="display:inline-block;box-shadow:none;border:1px solid var(--border);margin-left:5px;"  @click="$emit('event','open')">
              
              <BaseIcon color="var(--primary)"  :icon="self==t.created_by || self == t.charger_id?'md-create':'md-search'" size="12" />
              </div>
          </div>
          
        </div>
        <template v-if="t.types">
          <template v-for="type in t.types">
            <BaseIcon
              :key="type.name"
              :icon="type.icon"
              color="var(--primary)"
              style="margin-left:5px"
            ></BaseIcon>
          </template>
        </template>

        <div style="margin-left:10px;width:100px;" v-if="t.percent">
          <Progress :percent="t.percent" status="active" />
        </div>
        </div>
      </div>
      

      <div class="flex-wrap flex-right" style="flex-shrink:0;flex-grow:0;">
        <template v-if="t.task_id">
          <div class="flex-wrap flex-right" style="width:520px;flex-shrink:0;">
          <Button size="small" @click="RouteTo('/core/itasks/'+t.id+'/dashboard')" type="primary">
          
            <BaseIcon icon="ios-folder-open" style size="15"></BaseIcon>
            打开
          </Button>
          <Button
            size="small"
            style="margin-left:10px"
            v-if="self == t.created_by"
            @click="RouteTo('/core/itasks/'+t.id+'/editor')"
          >
          <BaseIcon icon="ios-create" style size="15"></BaseIcon>
            编辑
            
          </Button>
          </div>
        </template>
        <template v-else>
          <div class="flex-wrap flex-left" style="width:370px;flex-shrink:0;">
            <div
              class="flex-wrap flex-center"
              :style="`height:24px;width:100px;padding: 10px;color:${t.plan_started_at?'':'var(--subtext3)'};font-size:12px;`"
            >{{t.plan_started_at ? moment(t.plan_started_at).format("L") : '未限制'}}</div>
            <BaseIcon icon="ios-arrow-forward"  color="var(--primary)" />
            <div
              class="flex-wrap flex-center"
              :style="`height:24px;width:100px;color:${t.deadline?'':'var(--subtext3)'};padding: 10px;margin-right:10px;font-size:12px;`"
            >{{t.deadline ? moment(t.deadline).format("L") : '未限制'}}</div>
            <div
              class="flex-wrap flex-between"
              v-if="t.deadline && !t.finished"
              :style="`height:24px;flex-shrink:0;padding: 0px 5px;white-space:nowrap;width:130px;border-radius:3px;border:1px solid ${moment(t.deadline).isAfter(moment())?'var(--border)':'var(--error)'};background:linear-gradient(to right,#ffffff00,#ffffff00,${moment(t.deadline).isAfter(moment())?'var(--border)':'var(--error)'});margin-right:10px;font-size:12px;`"
            >
              <div class="flex-wrap flex-left" :style="`width:80px;flex-shrink:0;border-right:1px solid ${moment(t.deadline).isAfter(moment())?'var(--border)':'var(--error)'};margin-right:5px;`">
                <BaseIcon icon="clock" style="margin-right:5px" :size="14" />
                {{moment(t.deadline).fromNow()}}
              </div>
              <div
                :style="`text-align:center;flex-grow:1;color:${!moment(t.deadline).isAfter(moment())?'var(--hover-text)':'var(--text3)'}`"
              >{{ moment(t.deadline).isAfter(moment())?'截止':'逾期' }}</div>
            </div>
            <div
              v-if="t.finished"
              class="flex-wrap flex-center"
              :style="`height:24px;padding: 0px 5px;white-space:nowrap;width:130px;border-radius:3px;border:1px solid var(--success);background:linear-gradient(to right,#ffffff00,#ffffff00,var(--success));margin-right:10px;font-size:12px;`"
            >
            <div class="flex-wrap flex-left" :style="`width:90px;flex-shrink:0;border-right:1px solid var(--success);margin-right:5px;`">
                <BaseIcon icon="clock" style="margin-right:5px" :size="14" />
                {{moment(t.finished_at).format('YYYY-MM-DD')}}
              </div>
             <div
                :style="`color:#fff;text-align:center;flex-grow:1`"
              >{{ moment(t.deadline).isAfter(moment(t.finished))?'':'' }}完成</div></div>
        </div>

        <div class="flex-wrap" style="width:70px;flex-shrink:0;">
          <template v-if="t.finished == false">
            
            <Button type="primary" size="small" v-if="t.charger_id==self || t.created_by == self" @click.stop="$emit('event','finish')">
              <BaseIcon icon="md-checkmark" style size="15"></BaseIcon>
              
             
            </Button>
            <Button
              type="success"
              style="margin-left:10px"
              v-if="t.outdate"
              size="small"
              @click.stop="$emit('event','reset')"
            >重新载入</Button>
            
          </template>
          <template v-else>
            
            <Button size="small" @click.stop="$emit('event','cancel')" v-if="self==t.created_by || self == t.charger_id">
              <BaseIcon icon="md-undo" size="15" />
            </Button>
          </template>
          <Dropdown trigger="click" transfer @on-click="$emit('event',$event)" v-if="self == t.created_by">
            <a>
              <Button style="margin-left:10px;" size="small">
                <BaseIcon icon="other" />
              </Button>
            </a>
            <DropdownMenu slot="list">
              <DropdownItem name="edit">
                <div class="flex-wrap">
                  <BaseIcon icon="md-create" style="margin-right:5px" size="15"></BaseIcon>编辑
                </div>
              </DropdownItem>
              <!-- <DropdownItem name="close" v-if="t.finished != 2">
                <div class="flex-wrap">
                  <BaseIcon icon="lock" style="margin-right:5px"></BaseIcon>关闭
                </div>
              </DropdownItem> -->
              <DropdownItem name="unclose" v-if="t.finished == 2">
                <div class="flex-wrap">
                  <BaseIcon icon="unlock" style="margin-right:5px"></BaseIcon>启用
                </div>
              </DropdownItem>
              <DropdownItem name="delete">
                <div class="flex-wrap" style="color:var(--error);">
                  <BaseIcon icon="md-trash" size="18" style="margin-right:4px"></BaseIcon>删除
                </div>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
        </template>
        
      </div>
    </div>
    <div
      style="width:100%;flex-shrink:0;padding-left:38px;color:var(--primary);margin-top:5px;font-size:12px;"
      v-if="t.group_name && !hideGroup || t.project_name && !hideProject || t.last_comment"
    ><div class="flex-wrap"  style="display:inline-block;min-width:140px;">
            <div class="l-tag l-project" v-if="t.group_name && !hideGroup">[{{t.group_name}}]</div>
            <div class="l-tag l-project" v-if="t.project_name && !hideProject">{{t.project_name}}</div>
          </div>
          <template v-if="t.last_comment">
            <BaseIcon icon="md-return-right" style="margin-left:5px;margin-right:5px;" /> 
           {{moment(t.last_comment_at).fromNow()}}：{{FormatComment(t.last_comment)}}
          </template>
           </div>
         
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    index: {
      type: Number,
      default: 0
    },
    multiUsers:{
        type:Boolean,
        default:false
    },
    hideGroup:{
        type:Boolean,
        default:false
    },
    hideProject:{
        type:Boolean,
        default:false
    },
    self:{
        type:String
    }
  },
  computed: {
    t() {
      return this.value;
    },
    priorities() {
      return [
        {
          name: "普通",
          color: "var(--bg1)"
        },
        {
          name: "重要",
          color: "var(--primary)"
        },
        {
          name: "紧急",
          color: "var(--error)"
        }
      ];
    },
    types() {
      return {
        total: "计划",
        date: "每日计划",
        year: "年度计划",
        week: "每周计划",
        month: "月度计划"
      };
    }
  },
  methods:{
    FormatComment(t){
        var re = new RegExp('<[^<>]+>','g');
        var re2 = /&[a-zA-Z0-9]+;/g
        return t.replace(re ,"").replace(re2,"");
    }
  }
};
</script>

<style lang="less" scoped>
.l-task {
  padding: 5px 10px;
  background: linear-gradient(to right,var(--bg2),var(--bg3));
  margin: 1px;
  border-bottom: 1px solid var(--border);

  .l-code {
    background: var(--bg3);
    color: var(--text3);
    width: 20px;
    height: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    box-shadow: 1px 1px 1px var(--border);
    margin-right: 8px;
  }
  .l-project {
    color: var(--success) !important;
    font-size: 10px;
    margin-right: 5px;
    margin-bottom:3px;
  }

  .l-tag {
    color: var(--text2);
    text-align:center;
    background:var(--bg2);
    font-weight: bold;
    white-space: nowrap;
    padding:0px 5px;
    border-radius: 3px;
    box-shadow: 1px 1px 2px 1px var(--border);
  }
}

.l-task:nth-child(odd){
    background:var(--bg3);
}

.l-task-finished {
  .l-code {
    background: var(--bg1);
    color: var(--text1);
  }

  background: var(--bg2);
}

.l-task:hover {
  box-shadow: 0 0px 2px 1px var(--border);
  transition:all 0.5s;
}
</style>