<style lang="less">
.app-bar{
  width:42px;
  height:100%;
  background:var(--bg1);
  position: relative;
  border:none;
}

</style>
<template>
  <div class="app-bar">
           <BaseIconButton :item="{
        name:'菜单',
        path:'/core/apps',
        icon:'md-apps'
      }" 
      :actived="isRouteActive('/core/apps')" />
      <BaseIconButton :item="{
        name:'主页',
        path:'/core/dashboard',
        icon:'md-desktop'
      }" 
      :actived="isRouteActive('/core/dashboard')"  />
      <div class="hover-lighter" style="border-bottom:1px solid var(--border);height:20px;text-align:center;line-height:18px;" @click="threadIndex--" v-if="
      threadIndex>0">
        <BaseIcon icon="ios-arrow-up" color="var(--text1)" />
      </div>
      <div style="overflow:hidden;height:calc(100% - 296px);">
        <template v-for="t in threads.slice(threadIndex)">
           <BaseIconButton :key="t.path" :item="t" 
            @on-right-click="onContextmenu"
            :actived="isRouteActive(t.url,t.base_url)" />
        </template>
      </div>
      <div  class="hover-lighter" style="border-top:1px solid var(--border);height:20px;text-align:center;height:20px;line-height:18px;" @click="threadIndex++" v-if="threads.slice(threadIndex).length > 16" >
        <BaseIcon icon="ios-arrow-down" color="var(--text1)" />
      </div>
       <BaseIconButton :item="{
        name:'同事',
        icon:'contact',
        size:24
      }" 
      @on-click="showChat=!showChat"
      style="position:absolute;bottom:130px;left:0;border-top:1px solid var(--border)"
     />
      <BaseIconButton :item="{
        name:'主题',
        icon:'ios-color-palette',
        size:24
      }" 
      @on-click="showThemePanel=!showThemePanel"
      style="position:absolute;bottom:88px;left:0;border-top:1px solid var(--border)"
     />
       <BaseIconButton :item="{
        name:'个人中心',
        path:'/core/self',
        avatar:session.avatar
      }" 
      style="position:absolute;bottom:44px;left:0;"
      :actived="isRouteActive('/core/self','/core/self')" />

       <BaseIconButton :item="{
        name:'登出',
        icon:'md-power'
      }" 
      @on-click="logout"
        style="position:absolute;bottom:0;left:0;"
       />
      <Drawer  width="700"  transfer v-model="showChat" footer-hide  >
        <ChatBox :chat="true" style="height:100%;" />
      </Drawer>
      <Modal title="自定义主题" transfer  v-model="showThemePanel" footer-hide :z-index="99999">
       
        <div class="flex-wrap flex-between" style="overflow:hidden;width:100%;flex-wrap:wrap;">
          <template v-for="t in  themes">
          <div class="pm-theme-item" 
          :class="{
            'pm-theme-item-active':t.key === theme
          }"
          @click="$store.commit('session/change_theme',t.key);$emit('close')"
        :key="t.id">
          <img :src="'https://nbgzfiles-1257839135.cos.ap-shanghai.myqcloud.com/assets/'+t.key+'.png'">

          </img>
          <div>
            {{t.name}}
          </div>
        </div>
          </template>
        </div>
      </Modal>
  </div>
</template>

<script>
  import BaseIconButton from '@/components/base/IconButton'
  import ChatBox from '@/components/app/chatbox'
  import routeMixin from '@/mixins/route'
  import {mapGetters} from 'vuex'
  export default {
    data(){
      return {
        showThemePanel:false,
        showChat:false,
        threadIndex:0
      }
    },
    components:{
      BaseIconButton,ChatBox
    },
    computed:{
      ...mapGetters('session',['session','darkmode','theme','themes','threads'])
    },
    mixins:[routeMixin],
    mounted(){
      if(!this.session.id){
        this.$store.dispatch('session/whoami').then(()=>{
          this.$bus.$emit('login')
        })
      }
    },
    methods:{
      onContextmenu({event,detail:thread}){
       
        if(!thread)
          return
        this.$contextmenu({
        items: [
          {
            label: "关闭",
            onClick: () => {
              this.remove(thread)
            }
          },
          {
            label: "关闭其他",
            onClick: () => {
              this.removeThreads(this.threads.filter(v=>v !== thread))
            }
          },
          {
            label: "关闭所有",
            onClick: () => {
              this.removeThreads(this.threads)
            }
          }
        
        ],
        event,
        //x: event.clientX,
        //y: event.clientY,
        customClass: "custom-class",
        zIndex: 9999,
        minWidth: 100
      });
      return false;
      },
      setDarkMode(darkmode){
        this.$store.commit('session/switch_darkmode',darkmode)
      },
      remove(t){
        let index = this.threads.findIndex(v=>v.base_url == t.base_url)
        this.$store.commit("session/remove",index)
        if(this.isRouteActive(t.url,t.base_url)){
          if(this.threads.length > 0){
            if(index > 0)
              this.RouteTo(this.threads[index-1].path)
            else
              this.RouteTo(this.threads[0].path)
          }else{
            this.RouteTo('/core/dashboard')
          }
        }
      },
      removeThreads(tr){
        let needToRoute = false
        tr.forEach(t=>{
         
          if(this.isRouteActive(t.url,t.base_url)){
            needToRoute = true
          }
        })

        this.$store.commit("session/remove_threads",tr)

        if(needToRoute){
          if(this.threads.length > 0){
            if(index > 0)
              this.RouteTo(this.threads[index-1].path)
            else
              this.RouteTo(this.threads[0].path)
          }else{
            this.RouteTo('/core/dashboard')
          }
        }
      },
      logout(){
        this.$store.dispatch('session/logout')
        this.RouteTo('/')
      }
    }
  }
</script>

<style lang="less">


  .pm-theme-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding:20px;
    min-width:50%;
    cursor: pointer;
    color:var(--text2);
    img{
      background:#333;
      border-radius: 2px;
      border:1px solid var(--border);
      width:80%;
      height:100px;
      margin-bottom:10px;
    }
  }


  .pm-theme-item:hover{
    background:var(--hover);
    color:var(--hover-text);
  }

  .pm-theme-item-active,.pm-theme-item-active:hover{
    background:var(--active);
     color:var(--hover-text);
  }



.draggable-modal{
  .ivu-modal-content{
    height:500px;
    box-shadow:none;
  }
  .ivu-modal{
    
    .ivu-modal-header{
      position: absolute;
      z-index:10;
      top:240px;
      height:300px;
      left:0;
      bottom:0;
      width:50px;
      border:none;
      opacity:0;
    }
  }
}

/* custom */
.custom-class{
  background:var(--bg1) !important;
  padding:2px 0  !important;
  border-radius:2px;
  box-shadow: 0 2px 4px var(--border);
}
.custom-class .menu_item{
  background: var(--bg1) !important;
  color: var(--hover-text) !important;
}
.custom-class .menu_item__available:hover,
.custom-class .menu_item_expand {
  background: var(--bg1) !important;
  filter:brightness(1.1);
  color: var(--hover-text) !important;
}

</style>