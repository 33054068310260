<template>
  <Container>
    <div class="flex-wrap flex-between" style="padding:0 10px">
      <Toolbar 
        :items="tools"
        v-model="filter"
        :counts="counts"
        @on-event="handleEvent" 
      />
        
      <div class="flex-wrap">
      <Button style="margin-right:5px" icon="md-add" type="primary" @click="showCreateForm">新建项目</Button>
      <Button style="margin-left:5px" icon="md-download" @click="exportExcel" /> 
      </div>
    </div>
    
    <div class="table-wrap" style="flex-grow:1;position;relative">
      <BaseTable 
        ref="table"
        :columns="columns"
        :data="items"
        :page="page"
        :page-size="pagesize"
        @event="handleEvent"
        :loading="loading"
        :count="total"
      />
    </div>

    <Editor
      ref="editor" 
      v-model="showEditor"
      :data="current"
      model="BU_Bid_Work_Project"
      @on-event="handleEvent"
    />
  </Container>
</template>

<script>
import Container from '@/components/app/container'
import Toolbar from '@/components/app/toolbar'
import Editor from '@/components/model/editor'
import moment from 'moment'
export default {
  routerOption: {
    as_default: true
  },
  components:{Container,Toolbar,Editor},
  data(){
    return {
      filter:{
        status:"草稿"
      },
      showEditor:false,
      items:[],
      loading:false,
      current:{},
      formData:{},
      page:1,
      pagesize:20,
      total:10,
      counts:{
        "草稿":0,
        "审核中":0,
        "审核通过":0,
        "不通过":0
      },
      order:{}
    }
  },
  methods:{
    showCreateForm(){
      this.current = {}
      this.formData = {}
      this.showEditor = true
    },
    Edit(item){
      this.curent = item
      this.formData = {}
      this.showEditor = true
    },
    getStatusCounts(){
      this.$api.getCounts("/business/bid_work_projects",{
        count_by:'status'
      }).then(res=>{
        this.counts = Object.assign(this.counts,res.data.data)
      })
    },
    getData(){
      this.loading = true
      this.$api.getPaged("/business/bid_work_projects",{
        page:this.page,
        pagesize:this.pagesize,
        order:this.order,
        filter:this.filter,
        related:['services','building_categories']
      }).then(res=>{
        let {count,list} = res.data.data
        this.items = list
        this.total = count
        this.loading = false
      })
    },
    createItem(model){
      this.$api.post('/business/bid_work_projects',model).then(res=>{
        this.$Notice.success({title:"创建成功"})
        this.showEditor = false
        this.getData()
      }).finally(()=>{
        this.$refs.editor.stopLoading()
      })
    },
    handleDelete(item){
      this.Confirm(`即将删除此项目<span style="color:var(--error)">${item.name}</span>，是否继续?`,()=>{
        this.$api.delete("/business/bid_work_projects/"+item.id).then(res=>{
          this.$Notice.success({title:"删除成功"})
          this.getData()
        })
      })
    },
    handleOpen(project){
      this.$store.commit("session/push_project",{
        id:project.id,
        name:project.name
      })
      this.RouteTo('/core/projects/'+project.id+'/bid')
    },
    handleEvent({type,key,value,data}){
      const eventMap = {
        add:this.showCreateForm,
        search:this.getData,
        create:this.createItem,
        refresh:()=>{
          this.getStatusCounts()
          this.getData()

        },
        update:this.updateItem,
        'tab-change':this.getData,
        'page-change':()=>{
          this.page = data
          this.getData()
        },
        'page-size-change':()=>{
           this.pagesize = data
          this.getData()
        },
        'sort':()=>{
           if(!data || !data.key)
            this.order = null
          else
            this.order = data
          this.getData()
        }
      }


      let eventHandler = eventMap[type]
      if(eventHandler){
        eventHandler(value)
      }
    },
  },
  computed:{
    tools(){
      return [{
        key:'search',
        placeholder:"输入项目名称/招标单位/搜索",
        type:"search",
        maxWidth:200
      },{
        key:"is_bid",
        type:"tabs",
        subs:[{
          key:false,
          icon:"date",
          countable:true,
          name:"已中标"
        },{
          key:true,
          icon:"file",
          countable:true,
          name:"未中标"
        }]
      },{
        key:"is_exception",
        type:"tabs",
        subs:[{
          key:true,
          icon:"date",
          countable:true,
          name:"投标异常"
        }]
      },{
        key:'refresh',
        icon:'md-refresh',
        type:"icon-button"
      },]
    },
    columns(){
      var that = this
      return [
          {type:'index',width:80,title:"序号"},
          {key:'charger_id',type:"user",width:120,title:"负责人",sortable:"custom",
            
          },
          {key:'name',width:200,title:"项目名称",sortable:"custom",
            render:(h,{row})=>{
              let icon = h('BaseIcon' ,{props:{icon:'Building-',size:16,color:'var(--primary)'},style:'margin-left:10px;margin-right:5px;'})
              let tag = h('img',{attrs:{
                 src: "https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/beauty/new.gif"
              },style:'width:30px;height:15px;margin-left:5px;margin-right:10px;'})
              if(moment().subtract(3,'days').isAfter(moment(row.created_by)))
                tag = ''
              let name = h('div',{attrs:{title:row.name},style:"white-space:nowrap;text-overflow:ellipsis;width:calc(100% - 50px;);overflow:hidden;"},row.name)
              return h('div',{
                class:"flex-wrap",
                style:{
                  color:"var(--primary)",
                  fontWeight:'bold',
                  textAlign:"left",
                  cursor:'pointer'
                },
                on:{
                  click:()=>{
                   this.Edit(row)
                  }
                }
              },[icon,name,tag])
            }
          },
          
          {key:'published_started_at',width:120,title:"公告起始日期",sortable:"custom",
            render:(h,{row})=>{

              return h('span',moment(row.created_at).format("YYYY-MM-DD"))
          },},
          {key:"bid_type",width:100,title:"投标类型"},
          {key:'target_type',width:100,title:"标的类型"},
        
          {key:"bid_eval_method",width:120,title:"评标办法",sortable:"custom",},
          {
            key:'bid_unit_count',width:100,title:"总投标家数",sortable:"custom",
          },
          {
            key:'tech_bid_order',width:100,title:"技术标排名",sortable:"custom",
          },
          {
            key:'is_bid',width:100,title:"是否中标",sortable:"custom",type:'boolean'
          },
          {
            key:'is_exception',width:100,title:"投标异常",sortable:"custom",type:'boolean'
          },
          {
            key:'deposit_amount',type:'number',option:{type:'amount'},width:100,title:"投标保证金",sortable:"custom"
          },
          {
            key:'bid_file',type:'file',width:100,title:"投标文件",sortable:"custom",
          },
          {
            key:'open_bid_record',type:'file',width:100,title:"开标记录",sortable:"custom",
          },
          {key:"tools",width:140,title:"操作",sortable:false,
            render:(h,{row})=>{
              let buttons = [
                h('Button',{props:{size:"small"},on:{click:()=>{that.Edit(row)}}},"修改"),
                h('Button',{props:{size:"small"},on:{click:()=>{that.handleDelete(row)}}},"删除")
              ]
              return h('div',{class:"flex"},[buttons])
            }
          },
        ]
    }
  },
  mounted(){
    this.getData()
    this.getStatusCounts()
    this.$nextTick(()=>{
      if (this.$refs && this.$refs.table && this.$refs.table.calcTableHeight)
        this.$refs.table.calcTableHeight()
      
    })
  },
}
</script>

<style lange="less">
.filter-box{
  height:50px;
}

.table-wrap{
  height:calc(100% - 50px);
  position: relative;
}

</style>