<template>
  <div class="container">
    <div class="flex-wrap flex-between" style="width:100%;height:30px;font-weight:bold;">
        <div class="flex-wrap">
      <BaseIcon icon="md-apps" style="margin-right:5px" />
      我的待办
    </div>
    <div class="flex-wrap">
      <span style="margin-right:10px;"><BaseIcon icon="ios-more" :size="20" /></span>
    </div>
    </div>
    <div style="height:calc(100% - 30px);position:relative;width:calc(100% - 10px);background:var(--bg3);border:2px solid var(--border);">
        <BaseEmpty v-if="items.length == 0" msg="您目前没有待办事项"></BaseEmpty>
        <template v-for="item in items">
            <div class="list-item" style="height:50px"  :key="item.id" @click="openTask(item)">
             <div class="flex-wrap flex-between " style="height:100%;width:100%;">
                 <div style="max-width:calc(100% - 80px);"><div class="title" style="font-weight:bold;">{{item.node }}</div>
                 <div style="font-size:12px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{ item.name  }}</div></div>
                 <div class="state" :style="`font-size:12px;border-radius:5px;background:${item.color};color:var(--hover-text);`">{{ item.state }}</div>    
             </div>
             
            </div>
         </template>
         
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            items:[]
        }
    },
    methods:{
        openTask(item){
            let url = `/core/flows/${item.id}/instance`
            this.$store.commit('session/push_flow',{
                id:item.id,
                name:item.name
            })
            this.RouteTo(url)
        }
    }
}
</script>

<style lang="less">
.list-item{
    .title{
        color:var(--primary);
        font-weight: bold;
    }
}
.list-item:hover{
    .title{
        color:var(--hover-text);
    }
}
</style>
<style>

</style>