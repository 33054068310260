import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import {PageRouters} from '@/utils/router_generator'
import iView from 'view-design'
import 'view-design/dist/styles/iview.css';

Vue.use(Vuex)
Vue.use(VueRouter)

let page_router = new VueRouter({
  mode: 'history',
  routes: [
   
   ...PageRouters,
    {
      path: '*',
      redirect: '404'
    }
  ]
})

page_router.beforeEach((to, from, next) => {
  if (to.path === from.path && to.path !== "/" && (!to.query || Object.keys(to.query).length == 0)) {
    // Avoid navigation duplication
    next(false);
  }else{
    iView.LoadingBar.start();
    next();
  }

});

page_router.afterEach((route) => {
  iView.LoadingBar.finish()
  if (route.path != '/')
    localStorage.setItem('last-route-path', route.fullPath)
});

export default page_router
