<template>
    <div>
    <RadioGroup v-if="items.length !== 0"  :value="value" v-bind="option" @input="$emit('input',$event)">
        <Radio v-for="o in items" :label="o.id" :key="o.id">
            <div style="display:inline-flex;margin-left:10px;">
                <div>{{ o.certType.name }}</div>
                <div style="font-size:12px;color:var(--subtext2);margin-left:10px;">有效期至{{ moment(o.expired_at).format("YYYY-MM-DD") }}</div>    
            </div>
        </Radio>
    </RadioGroup>
    <div v-else>
        无有效证书
    </div>
    </div>
</template>

<script>
export default {
    props:{
        value:{
            type:String
        },
        db:{
            type:Object,
            default:()=>({})
        },
        option:{
            type:Object,
            default:()=>({})
        }
    },
    data(){
        return {
            items:[]
        }
    },
    watch:{
        user_id:{
            handler(v,old){
                if(v!== old)
                    this.getData()
            },
            immediate:true
        }
    },
    computed:{
        user_id(){
            return this.db?.user_id
        }
    },
    methods:{
        getData(){
            if(!this.user_id)
                return
        this.timer = this.$api.afterLogin(()=>{
            
            this.$api.get(`oa/certifications?related=certType&employee_id=${this.user_id}`).then(res=>{
                this.items = res.data.data
            })
        },this.timer)
        }
    }

}
</script>

<style>

</style>