<template>
    <BaseMultiForm 
        :value="value"
        v-bind="option"
        @input="$emit('input',$event)"
    />
  </template>
  
  <script>
  export default {
    props:{
      value:{
          type:Boolean|String|Number
      },
      option:{
          type:Object,
          default:()=>({})
      },
    },
    computed:{
      booleanValue(){
        return this.value?true:false
      }
    }
  }
  </script>
  
  <style>
  
  </style>