var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Form',{ref:"form",staticClass:"ugo-form",attrs:{"label-position":"top","model":_vm.model,"rules":_vm.rules,"inline":""}},[(!_vm.form.id && _vm.fields.length == 0)?_c('BaseEmpty',{attrs:{"msg":"表单配置错误, 请联系管理员"}}):_vm._e(),(_vm.form.html)?[_c('BaseForm',{attrs:{"form":{
            layout:_vm.form.html,
            def:_vm.formDef,
            option:{
              hideTools:true
            }
          },"editable":_vm.editable},on:{"submit":function($event){_vm.formData=$event}},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})]:[_vm._l((_vm.fields),function(p){return [_c('div',{key:p.id,staticClass:"form-item"},[(_vm.isContainer(p.control))?[_c(p.control,_vm._b({tag:"component"},'component',p.option || {},false))]:[_c('FormItem',{attrs:{"label":p.name,"required":p.required,"prop":p.key}},[_c(p.control,_vm._b({tag:"component",attrs:{"editable":_vm.editable,"label":p.name,"value":_vm.model[p.key]==undefined?_vm.old[p.key]:undefined},on:{"input":function($event){_vm.model[p.key] = $event}}},'component',p.option || {},false))],1)]],2)]})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }