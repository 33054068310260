<template>
  <FormItem :label="label" :required="required">
  
    <RadioGroup v-model="value" @input="$emit('input',$event)">
      <template v-for="o in options">
          <div :key="o.key" style="margin-left:15px">
          <Radio :label="o.name" :key="o.key" :disabled="!editable">
            <Icon v-if="o.icon" :type="o.icon"></Icon>
            <span>{{o.name}}</span>
        </Radio>
          </div>
      </template>
    </RadioGroup>
  
 </FormItem>
</template>

<script>
export default {
  cname:"单选框",
  icon:'danxuan',
  properties:[{
    label:"选项",
    key:'options',
    control:"SelectOption"
  }],
 props:['fkey','config','value','editable','label','required'],
 initModel(e){
  e.config = {
    options:['选项1','选项2']
  }
 },
  computed:{
    options(){
      let options = this.config.options
      if(Array.isArray(options)){
        if(options && typeof options[0] != 'object')
          options = options.map((v,i)=>({
            key:i,
            name:v
          }))
        
      }
      return options
    }
  }
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>