<template>
    <div class="container" style="background:var(--bg2);overflow-y:auto;">
      <div style="width:1200px;margin:40px auto;">
        <h1>2024年度人员考核定级及评优工作 - {{ current && current.id?'修改':'新建' }}</h1>
        <template v-if="state==-1">
          <Spin fix>
            <div class="flex-col">
              <h2 style="font-size:40px;color:var(--subtext2)">2024年终考核 - 加载中</h2>
              <div class="flex-wrap" style="margin-top:10px">
                <BaseIcon icon="nbgz" color="var(--subtext2)" size="40" />
                <BaseLoading style="margin:0" />
                <BaseIcon icon="supervisor" color="var(--subtext2)" size="40" />
              </div>
            </div>
          </Spin>
        </template>
        <template v-else-if="state == 0">
          <pre style="color:var(--subtext3);font-size:12px;white-space:prewrap;word-break:break-all;">一、考核对象
  2024年11月1日前入职且在岗的正式员工。
  二、考核组织
  综合部负责2024年度人员考核的总体部署与实施，各事业部总经理分别负责事业部的年度人员考核具体实施，综合部负责汇总考核信息及参与考核。
  造价咨询事业部、装修管理事业部、BIM咨询事业部与公司行政系统述职报告同公司统一要求，考核指标可另自行制定。
  三、考核办法
  1.本年度员工考核全部通过线上信息平台完成（平台网址：https://www.inbgz.com/login），所有考核对象须于12月5日前将个人年度述职报告上传至信息平台并完成自评（在选择事业部及岗位后会自动跳转至对应自评表，个人可岗位可向事业部征求意见）。
  2.员工第一考核责任人为当前项目/部门负责人，也可同时选择本年度工作的其他项目/部门负责人（事业部也可直接指定平行考核人），第二考核责任人原则上为事业部总经理。
  3.各项目/部门负责人请于12月8日前完成本项目/部门人员考核及评优推荐工作，各事业部请于12月15日前完成事业部人员考核及评优推荐工作。
  4.本次考核由综合部、事业部总经理、项目部或部门负责人通过平台进行线上综合评定，了解项目部/部门成员日常工作成绩与工作状态，对考核指标进行打分，并就员工2024年综合表现进行定性评价，汇总形成《2024年度员工考核汇总表》。
          </pre>
  
          <div
            class="flex-wrap flex-between align-start"
            style="padding:20px;margin:10px 0;border:1px solid var(--border);background:var(--bg3);"
          >
            
          <div style="border:1px solid var(--border);width:300px;">
              <div style="font-weight:bold;margin:5px;margin-bottom:0">可以在此处修改账号信息</div>
              <UserProfile />
              <UserCompany />
            </div>
            <div>
            <div style="margin:0 30px;background:var(--bg2);padding:20px;">
              <div style="font-weight:bold">
                Step1:
                完善个人信息
              </div>
              <ol style="color:var(--subtext2);padding:10px 20px;font-size:12px;">
                <li>本次考核的内容和形式同2024年年终考核。</li>
                <li>第一评分负责人建议选择项目/部门负责人</li>
                <li>其他评分负责人选其他领导</li>
                <li>带有*号的项目为必填项</li>

                <li style="color:var(--error)">注意* 本次考核姓名、部门、职务、第一负责人等内容由事业部配置，如存在不准确的情况，请联系事业部修改</li>
              </ol>
            </div>
            
            <Form :label-width="80" style="margin:20px 30px;padding:20px;border:1px solid var(--border);">
              <Row>
                <Col :span="8">
                  <FormItem label="姓名" required>
                    <div style="color:var(--primary);margin:1px;">{{formData.name}}</div>
                  </FormItem>
                </Col>
                <Col :span="8">
                  <FormItem label="部门" required>
                    <div style="color:var(--primary);margin:1px;">{{deps[formData.dep]}}

                      <span v-if="current && current.id && formData.dep !== current.dep" style="color:var(--error);margin-left:5px;font-size:12px;">(已由管理员修改)</span>
                    </div>
                    
                  </FormItem>
                </Col>
                <Col :span="8">
                  <FormItem label="职务" required>
                    <div style="color:var(--primary);margin:1px;">{{positions[formData.position]}} <span v-if="current && current.id && formData.position !== current.position" style="color:var(--error);margin-left:5px;font-size:12px;">(已由管理员修改)</span></div>
                    
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col :span="8">
                  <FormItem label="职称" required>
                    <Select clearable style="width:120px" v-model="formData.rank" placeholder="请选择">
                      <Option v-for="(d,i) in ranks" :key="i" :label="d" :value="d">{{ d }}</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col :span="8">
                  <FormItem label="学历" required>
                    <Select clearable style="width:140px" v-model="formData.education" placeholder="请选择">
                      <Option v-for="(d,i) in educations" :key="i" :label="d" :value="d">{{ d }}</Option>
                    </Select>
                  </FormItem>
                </Col>
                <Col :span="8">
                  <FormItem label="证书" required>
                    <Select clearable style="width:140px" v-model="formData.certificate" placeholder="请选择">
                      <Option v-for="(d,i) in cerificates" :key="i" :label="d" :value="d">{{ d }}</Option>
                    </Select>
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col :span="8">
                  <FormItem label="入职时间" required>
                    <BaseDate v-model="formData.hire_date" clearable />
                  </FormItem>
                </Col>
                
                <Col class="flex-wrap" :span="16" >
                  <FormItem>
                    <div class="flex-wrap">
                  <div>所在部门/项目部名称</div>
                  <Input
                    clearable
                    style="width:250px;margin-left:20px;"
                    v-model="formData.projects"
                    placeholder="请填写"
                  /></div>
                </FormItem>
                </Col>
                
              </Row>
              <Row v-if="formData.position !== 0">
                <Col class="flex-wrap" style="padding:20px 30px">
                  <div>
                    <span style="color:red">*</span> 第一负责人
                  </div>
                  <BaseUserSelectA
                    clearable
                    :editable="false"
                    class="pm-not-embed"
                    show-name
                    style="width:250px;margin-left:20px;"
                    v-model="formData.first_to"
                    placeholder="请填写"
                    :option="{ent:true}"
                  />
                  
                  <div style="color:var(--error);font-size:12px;">
                    <div v-if="current && current.id && formData.first_to !== current.first_to" style="color:var(--error);font-size:12px;"> *已由管理员修改</div>
                    * 此为系统预设，如有问题请联系管理员</div>
                </Col>
                <Col class="flex-wrap" style="padding:20px 40px" v-if="this.others_to.length > 0">
                  <div>平行负责人</div>
                  <BaseUserSelectA
                    clearable
                     :editable="false"
                     class="pm-not-embed"
                     show-name
                    style="width:250px;margin-left:20px;"
                    :option="{
                      multiple:true,
                      ent:true,
                      limit:3,
                    }"
                    :value="this.others_to"
                    placeholder="请填写"
                  />
                  <div style="color:var(--error);font-size:12px;">* 此为系统预设，如有问题请联系管理员</div>
                </Col>
                <Col class="flex-wrap" style="padding:20px 40px" v-if="3-this.others_to.length > 0">
                  <div>其他平行负责人</div>
                  <BaseUserSelectA
                    clearable
                    show-name
                     class="pm-not-embed"
                    style="width:250px;margin-left:20px;"
                    :option="{
                      multiple:true,
                      ent:true,
                      limit:3 - this.others_to.length,
                    }"
                    v-model="formData.others_to"
                    placeholder="请填写"
                  />
                  <div style="color:var(--warning)"></div>
                </Col>
              </Row>
              <div v-else style="padding:10px 40px;color:var(--primary);">经理/总监岗直接由事业部进行评分，无需选择负责人</div>
              <div class="flex-wrap flex-right">
                <Button style="margin-right:10px;" v-if="state>0" @click="handleUndo" :loading="executingGoBack">返回上一步</Button>
                <Button type="primary" @click="handleSubmit" :loading="executing">下一步</Button>
              </div>
            </Form>
          </div>
          </div>
        </template>
        <template v-else-if="state==1">
          <div
            style="padding:40px 20px;margin:10px 0;border:1px solid var(--border);background:var(--bg3);"
          >
            <div style="margin:0 30px;background:var(--bg2);padding:20px;">
              <div style="font-weight:bold">
                Step2:
                个人自评
              </div>
              <ol style="color:var(--subtext2);padding:10px 20px;font-size:12px;">
                <li>上传年度述职报告</li>
                <li>填写岗位评分</li>
                <li>填写问卷调查</li>
              </ol>
              <BaseEmpty style="min-height:300px;background:var(--bg3)" v-if="loadingQuestionary">
                问卷加载中
                
                <BaseLoading style="margin:10px">
                  
                </BaseLoading>
              </BaseEmpty>
              <div v-else-if="QASheets && QASheets.label" style="background:var(--bg3);padding:40px;border-radius:10px;margin-top:20px;box-shadow:1px 1px 2px 3px var(--border);">
                <h2>述职报告上传</h2>
                <div  style="font-size:12px;color:var(--error);">为了方便事业部审核查看，上传述职报告请尽量避免未压缩的图片、复杂的格式</div>
                <DOCXInput :option="{}" editable style="margin:30px 0;" :env="{uploader:$cos.upload}" v-model="formData.report" />
  
                <h2>岗位评价 - {{ QASheets.label }}</h2>
                <pre style="font-size:12px;color:var(--error);">{{ QASheets.desc }}</pre>
                <div style="padding:10px;">
                <div style="color:var(--primary);font-size:14px;"><div style="display:inline-flex;justify-content:center;align-items:center;width:20px;height:20px;border-radius:3px;background:var(--subtext2);color:var(--hover-text);box-shadow:1px 1px 1px 1px var(--border);margin-right:10px;" :style="errors[0]?'background:red':''">1</div>职业道德</div>
                <div style="margin-top:5px;margin-left:30px;margin-bottom:15px;color:var(--subtext3);">实行一票否决制，凡存在问题，考核结论即为不称职 </div>
                    <div class="flex-wrap split10">
                      <RadioGroup v-model="answer.q1[0]">
                        <Radio style="min-width:60px;" v-for="(o,j) in QASheets.options[0]" :label="j" :key="j">
                          {{ o }}
                        </Radio>
                      </RadioGroup>
                    </div>
                    </div>
                <template v-for="(q,i) in QASheets.cats">
                  <div style="padding:10px;" :key="i">
                    
                    <div style="color:var(--primary);font-size:14px;margin-bottom:15px;"  :ref="'QA'+i"><div style="display:inline-flex;justify-content:center;align-items:center;width:20px;height:20px;border-radius:3px;background:var(--subtext2);color:var(--hover-text);box-shadow:1px 1px 1px 1px var(--border);margin-right:10px;" :style="errors[i+1]?'background:red':''">{{i+2}}</div> {{ q }} <span style="color:var(--subtext3)">{{ QASheets.heavy[i] }}%</span></div>
                    <div class="flex-wrap split10">
                      <RadioGroup v-model="answer.q1[i+1]">
                        <Radio style="min-width:60px;" :key="o" v-for="(o,j) in QASheets.options[i+1]" :label="j">
                          {{ o }}
                        </Radio>
                      </RadioGroup>
                    </div>
                  </div>
  
                </template>
                <div class="flex-wrap flex-right" style="font-size:16px;margin:0 50px;">
                 总分 <span style="color:var(--primary);font-weight:bold;margin-left:10px;font-size:40px">{{ computedScore }}</span>
                </div>
                <h2 style="margin:20px 0;">问卷调查 {{ EVSheets.label }}</h2>
                
                <template v-for="(q,i) in EVSheets[0].questions">
                  <div style="padding:10px;" :key="'ev'+i">
                    <div style="color:var(--primary);font-size:16px;margin-bottom:15px;"  :ref="'EV'+i"><div style="display:inline-flex;justify-content:center;align-items:center;width:20px;height:20px;border-radius:3px;background:var(--subtext2);color:var(--hover-text);box-shadow:1px 1px 1px 1px var(--border);margin-right:10px;">{{i+1}}</div> {{ q.title }}</div>
                    <div class="flex-wrap split10">
                      <RadioGroup v-model="answer.q3[i]">
                        <Radio style="min-width:60px;" :key="o" v-for="(o,j) in q.options" :label="j">
                          {{ o }}
                        </Radio>
                      </RadioGroup>
                    </div>
                  </div>
                  </template>
  
              </div>
            </div>
            <Form :label-width="80">
              <div class="flex-wrap flex-right" style="margin-top:20px;">
                <Button style="margin-right:10px;" v-if="state>0" @click="handleUndo">返回上一步</Button>
                <Button type="primary" @click="handleSubmit">提交考核表</Button>
              </div>
            </Form>
          </div>
        </template>
        <template v-else-if="state==2 || state==3">
          <div style="margin-top:50px;background:var(--bg3);">
            <div class="flex-wrap" style="flex-direction:column">
              <img
                src="https://cdn-1301671707.cos.ap-nanjing.myqcloud.com/assets/undraw_Sync_files_re_ws4c.png"
                style="width:200px;"
              />
              <div
                style="font-size:20px;font-weight:bold;margin-bottom:10px;color:yellowgreen;"
              >事业部评分中</div>
              <template v-if="state==2">
                <div style="color:#333;">您的问卷已提交完毕，恭喜您已完成本次考核的自评部分。</div>
  
              <Button
                style="margin-top:20px;margin-bottom:100px;"
                v-if="state==2"
                @click="handleUndo"
              >我可能填错了，返回修改</Button>
              </template>
              <template v-else>
                <div style="margin-bottom:20px;color:var(--subtext3)">本次考核评分已归档, 如需要撤回请联系事业部负责人进行操作</div>
              </template>
             
            </div>
          </div>
        </template>
        <template v-else>
          <div style="margin-top:50px;background:var(--bg3);padding:40px;">
            <div class="flex-wrap" style="flex-direction:column">
              <img
                src="https://cdn-1301671707.cos.ap-nanjing.myqcloud.com/assets/undraw_Sync_files_re_ws4c.png"
                style="width:200px;"
              />
              <div style="font-size:20px;font-weight:bold;margin-bottom:10px;color:yellowgreen;">考核完成</div>
              <div style="color:#333;">恭喜您已完成2024年终考核</div>
              <Button style="margin-top:20px;margin-bottom:100px;" @click="goPrev()">查看问卷</Button>
            </div>
          </div>
        </template>
      </div>

      <BaseLoadingModal :value="loading" title="正在加载中,请稍后" />
    </div>
  </template>
  
  <script>
  import A2024 from '@/components/appraisals/2024.js'
  import {pick,cloneDeep,uniq} from 'lodash'
  import DOCXInput from '@/components/appraisals/docx'
  import UserProfile from '@/components/self/profile'
  import UserCompany from '@/components/self/company'
  import UTIL from '@/utils'
  export default {
    components:{DOCXInput,UserProfile,UserCompany},
    data() {
      return {
        state: -1,
        loading: false,
        executing:false,
        executingGoBack:false,
        showPreview:false,
        currrent:{},
        reportURL:'',
        others_to:[],
        current:{},
        QASheets:{},
        EVSheets:{},
        errors:{},
        answer:{
          q1:[],
          q3:[]
        },
        formData: {
          position:2,
          rank:'无',
          cerificate:'其他',
          education:'本科(全日制)'
        },
        loadingQuestionary:true,
      };
    },
    mounted() {
      this.$nextTick(() => {
        this.formData.name = this.session.name;
        this.getData()
      });
    },
    computed: {
      session() {
        return this.$store.getters["session/session"];
      },
      ...A2024.computed,
      computedScore(){
        let score = 0
        if(this.answer.q1[0] === 1){
          score -= 50
        }
        const scores = [10,9.5,9,8.5,8,7.5,7,6.5,6,5.5,5]
        for(let i=1;i<9;i++){
          if(isNaN(this.answer.q1[i]) == false && this.answer.q1[i]!== null){
            score += this.QASheets.heavy[i-1] * scores[this.answer.q1[i]] / 10
          }
        }
        return score
      }
    },
    methods: {
      handleSelectPosition(e){
        if(e == 0){
          delete this.formData.first_to
          delete this.formData.others_to
        }
      },
      getData() {
        this.loading = true
        this.$api.get("/oa/appraisal_instances/self?appraisal_id=2024").then(res => {
          let current = res.data.data    
          this.$api.get("/oa/appraisal_instances/self?appraisal_id=2023&reuse=1").then(res=>{
              this.oldData = res.data.data
              this.others_to = this.oldData.others_to || []
              console.log('current:',current)
              if(!current || !current.id){
                this.state = 0
                this.formData = pick(this.oldData,[
                    'name',
                    'dep',
                    'position',
                    'education',
                    'certificate',
                    'rank',
                    'hire_date',
                    'project',
                    'first_to',
                  ])
                  this.formData.others_to = []
                
                  this.formData.appraisal_id = '2024'
                  console.log(this.formData)
                    this.state = 0
                    this.loading =false
                }else{
                  this.current = current
                  
                  this.formData = cloneDeep(current)
                  this.formData.position = this.oldData.position
                  this.formData.dep = this.oldData.dep
                  this.formData.first_to= this.oldData.first_to
                  if(current.state == 0){
                    if(Array.isArray(this.formData.others_to)){
                      this.formData.others_to = this.formData.others_to.filter(v=>this.others_to.includes(v)==false)
                    }else{
                      this.formData.others_to = []
                    }
                  }
                
                  console.log('loaded:',this.formData)
                  this.formData.appraisal_id = '2024'
                  this.state = current.state || 0
                  if(current.state == 1){
                    this.loadQuestionary(current)
                  }
                  this.loading =false
                }
             
            })
          
        }).catch(()=>{
          this.loading = false
        })
      },
      loadQuestionary(current){
        this.loadingQuestionary = true
        setTimeout(()=>{
          if(current.answer)
            this.answer = current.answer
          else
            this.answer = A2024.initAnswer(current.id,current.dep,current.position,'n1')
          this.QASheets = A2024.getQASheet(current.dep,current.position)
          this.EVSheets = A2024.getEVSheets(current,['n1'])
      
          this.loadingQuestionary = false
        },500)
      },
      
      handleUndo(){
        if(this.executingGoBack)
          return
        this.executingGoBack = true
          this.$api.patch(`/oa/appraisal_instances/${this.current.id}?back=1`).then(res=>{
            this.getData()
          }).finally(()=>{
            this.executingGoBack = false
          })
        },    
      handleSubmit(){
        if(this.executing)
          return
        if(!this.current || !this.current.id){
          this.executing = true
          let data = cloneDeep(this.formData)
          data.others_to = uniq([...this.others_to,...this.formData.others_to])
          this.$api.post('/oa/appraisal_instances',data).then(res=>{
            this.getData()
            this.Success({title:'操作成功',desc:'您的2024年度考核记录已创建完成，请开始进行自评'})
          }).catch((e)=>{
            this.Error("错误:"+e)
          }).finally(()=>{
            this.executing = false
          })
        }else{
          let data = {}
          if(this.state == 1){
             data = {
              report:this.formData.report,
              answer:this.answer
            }
  
            if(!data.report){
              this.Error("请提交述职报告!")
              return
            }
            let status = this.answer.q1.map(v=>isNaN(v) || v === null)
  
            if(!Array.isArray(this.answer.q1) || this.answer.q1.length < this.QASheets.cats.length || status.filter(v=>v).length > 0){
              this.$set(this,'errors',status)
              this.$refs['QA'+status.findIndex(v=>v)][0].scrollIntoView(true)
              this.Error("岗位评价未填写完整，请返回修改")
    
              return
            }
  
          }else if(this.state == 0){
            data = pick(this.formData,[
                  'name',
                  'dep',
                  'position',
                  'education',
                  'certificate',
                  'rank',
                  'hire_date',
                  'project',
                  'first_to',
                ])
                data.others_to = uniq([...this.others_to,...this.formData.others_to])
          }else{
            return
          }
          this.executing = true
          
          this.$api.patch(`/oa/appraisal_instances/${this.current.id}`,data).then(res=>{
            this.getData()
          }).catch((e)=>{
            this.Error("错误:"+e)
          }).finally(()=>{
            this.executing = false
          })
        }
        
      }
    }
  };
  </script>
  
  <style>
  </style>