<template>
  <div class="container" style="background:var(--bg3);">
    <div class="flex-wrap flex-between" style="height:40px;padding:0 10px;border-bottom:1px solid var(--border)">  
      <div style="font-weight:bold;">CVM服务器状态</div>
      <div style="width:20px;height:8px;border-radius:5px;background:var(--success)"></div>
    </div>
    <div style="padding:10px;border-bottom:1px solid var(--border)">
      <div style="font-weight: bold;color:var(--primary);">主服务器</div>
      <div class="flex-wrap flex-between">
        <div>平台</div><div>{{data.father.platform}}</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>内存</div><div>已使用 {{parseInt(data.father.freememPercentage * 100)}} %</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>服务器运行时间</div><div>{{data.father.sysUptime}} 分钟</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>系统运行时间</div><div>{{parseInt(data.father.processUptime)}} 分钟</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>CPU</div><div>{{parseInt(data.father.cpuUsage * 100)}} %</div>
      </div>
      <div class="flex-wrap flex-between">
        <div>CPU内核</div><div>{{data.father.cpuCount}}</div>
      </div>
    </div>
    <div style="padding:10px;border-bottom:1px solid var(--border)">
      <div style="font-weight: bold;color:var(--primary);">集群服务器</div>
      <div> 未连接</div>
    </div>

  </div>
</template>

<script>
  export default {
    props:['data']
  }
</script>

<style lang="scss" scoped>

</style>