<template>
  <div class="m-form-item">
    <div>{{label}}</div>
    <slot></slot>
    <div>{{message}}</div>
  </div>
</template>
<script>
export default {
  props:{
    label:{
      type:String,
      default:''
    },
    message:{
      type:String,
      dfeault:''
    }
  }
}
</script>