import { CircleNode, CircleNodeModel, h } from '@logicflow/core';

// 自定义开始节点（圆形）
class StartNode extends CircleNode {
  getShape() {
    const { model } = this.props;
    const { x, y, r } = model; // 使用半径 r 来定义圆形

    // 定义圆形中间的三角形 SVG 图标，尺寸更大，尖朝右
const triangleIcon = h('svg', {
    x: x - 12, // 调整以便三角形居中
    y: y - 12,
    width: 24,
    height: 24,
    viewBox: '0 0 1024 1024', // 保持和方形一致的坐标系大小
    fill: '#52C41A' // 绿色三角形图标，表示开始
  }, [
    h('polygon', {
      points: '256,128 768,512 256,896' // 更大的三角形，尖朝右
    })
  ]);
  
  

    return h('g', {}, [
      // 绘制圆形背景
      h('circle', {
        cx: x, // 圆心 x 坐标
        cy: y, // 圆心 y 坐标
        r, // 圆的半径
        fill: '#F6FFED', // 圆形填充颜色
        stroke: '#52C41A', // 边框颜色
        strokeWidth: 2
      }),
      // 添加文本（居中）
      triangleIcon
    ]);
  }
}

// 自定义开始节点模型
class StartNodeModel extends CircleNodeModel {
  initNodeData(e) {
    super.initNodeData(e);
    this.r = 30; // 设置圆形节点的半径
    
  }

  getTools(){
    return []
  }

  getProps(){
    return [{
      key:'users',
      label:'发起人',
      control:'PermissionSelect',
      group:'basic'
  },{
      key:"form_permissions",
        label:'视图权限',
        control:'FormPermission',
      
    }]
  }

  // 限制节点的连接逻辑：只能连出，不能连入
  setAllowLinkIn() {
    return false; // 禁止进入的连线
  }

  setAllowLinkOut() {
    return true; // 允许输出连线
  }
}

export default {
    type:'start_node',
    view:StartNode,
    model:StartNodeModel
};
