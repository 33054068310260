<template>
  <Layout style="min-height:500px">
    <Sider style="background:#eee;border-right:1px solid #aaa;">
      新模板
      <template v-for="item in items">
        <div :key="item.id">
          {{item.name}}
        </div>
      </template>
    </Sider>
    <Content>
      预览
    </Content>
     <Sider style="background:#eee;border-left:1px solid #aaa;">
      <BaseTree :data="fields" style="height:100%" />
    </Sider>
  </Layout>
</template>

<script>
  export default {
    props:['form'],
    computed:{
      items(){
        return this.form.templates || []
      },
      fields(){
        let res=[{
          id:1,
          name:'表单数据项'
        },{
          id:2,
          name:'外部数据项'
        },...this.form.fields.map(v=>({...v,parent_id:1}))]
        return res
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>