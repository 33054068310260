<template>
  <div class="pd-embed-control" :class="{'pd-embed-contorl-disabled':!editable}">
    <template v-if="editable">
      <div class="flex-wrap hs-container" style="padding:0 8px;cursor:pointer;" @click="modalFileSelector=true">
        {{value?value.length+'个文件':'上传文件'}}
      </div>
    </template>
    <template v-else>
        {{value?value.length+'个文件':'不可用'}}
    </template>

    <Modal v-model="modalFileSelector" footer-hide title="选择文件">
      <div>
        File Select
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data(){
    return {
      modalFileSelector:false
    }
  },
  props:['value','editable','option']
}
</script>

<style>

</style>