<template>
  <App class="memo-task">
    <!--system-bar -->
    <AppBar style="padding:0">
      <div class="flex-wrap" style="padding:0 10px;height:100%;">
        <AppTitle icon="date" name="计划管理" />
        <AppTabs style="margin-left:50px" :items="tabs" :actived="mode" @on-click="changeMode" v-if="tabs.length > 1" />
      </div>
      <AppButton icon="config" @on-click="handleConfig" style="padding-right:0"></AppButton>
    </AppBar>
    <!--toolbar -->
    <AppBar style="padding:0;background:var(--bg2);border-bottom:1px solid var(--border);height:50px;">
      <div class="flex-wrap" style="font-size:14px;height:100%;flex-grow:1;">
        <BaseBoardButton
          icon="md-add"
          type="primary"
          name="新增计划"
          size="small"
          @on-click="handleCreate"
          style="margin-left:5px;flex-shrink:0;"
        ></BaseBoardButton>
        <BaseBoardButton
          v-if="!projectId"
          icon="md-add"
          type="warning"
          name="分解计划"
          size="small"
          @on-click="handleCreateComplex"
          style="margin-left:10px;flex-shrink:0;"
        ></BaseBoardButton>
        <BaseBoardButton
          icon="md-add"
          @on-click="getOutDatedTasks"
          name="逾期计划"
          v-if="!projectId"
          style="margin-left:10px;flex-shrink:0;"
          size="small"
        ></BaseBoardButton>
        <Input
          style="width:240px;margin-left:10px;flex-shrink:0;"
          v-model="searchText"
          placeholder="输入名称查询"
          search
          clerable
        />

        <Select
          placeholder="选择部门"
          v-model="filterGroup"
          @on-change="getData()"
          style="width:180px;margin-left:10px;"
          clearable
        >
          <BaseIcon slot="prefix" icon="organization" />
          <template v-for="g in groups">
            <Option :key="g.id" :value="g.id">{{g.name}}</Option>
          </template>
        </Select>
        <Select
          :placeholder="`选择项目(${my_projects.length})`"
          v-if="!projectId"
          v-model="filterProject"
          @on-change="getData()"
          style="overflow:hidden;margin-left:10px;max-width:400px;"
          clearable
        >
          <BaseIcon slot="prefix" icon="pm2" />

          <template v-for="g in my_projects">
            <Option :key="g.id" :value="g.id" :label="g.name">{{g.name}}</Option>
          </template>
        </Select>
        <div class="flex-wrap" style="margin-left:10px" v-if="filterProject || filterGroup">
          协同
          <i-switch v-model="filterCooperating" style="margin-left:10px"></i-switch>
        </div>
        <AppButton icon="md-refresh" @on-click="load" style="margin-left:10px">刷新</AppButton>
      </div>
      <div class="flex-wrap" style="height:100%;flex-shrink:1;">
        
      </div>
    </AppBar>
    <AppClient class="flex-wrap flex-line" :top="92" :loading="loading" :empty="tasks.length == 0">
      <div
        class="flex-line flex-col flex-strentch"
        style="flex-grow:1;height:100%;position:relative;width:100%;background:var(--bg2);overflow:hidden;"
      >
        <div style="height:70%;width:100%;background:var(--bg3);flex-grow:1;background:var(--bg2);">
          <div class="flex-wrap flex-between" style="padding:5px;font-size:14px;font-weght:bold;">
            <div class="flex-wrap">
            <BaseIcon icon="md-list" />
            进行中({{filteredTasks.length}})
          </div>
          <div class="flex-wrap">
            <template v-for="t in cur_tags">
              <BaseTag2 :key="t.name" style="margin-right:3px" :count="t.count" :selectable="true" v-model="filterTags[t.name]">{{ t.name }}</BaseTag2>
            </template>
            <BaseTag2 @on-click="filterTags={}" v-if="Object.values(filterTags).filter(v=>v).length > 0">x</BaseTag2>
          </div>
          <div></div>
          </div>
          <div
            class="l-tast-title flex-wrap flex-between"
            style="height:30px;border-bottom:1px solid var(--border);border-top:1px solid var(--border);"
          >
            <div class="flex-wrap flex-center l-code" style="width:40px;text-align:center">序</div>
            <div class="flex-wrap flex-center l-code hover-lighter" style="width:65px;text-align:center" @click="handleChangeSort('priority')">优先级 <BaseIcon v-if="sort_key=='priority'" :icon="sort_order=='asc'?'ios-arrow-up':'ios-arrow-down'" color="var(--subtext3)" /></div>
            <div
              class="flex-wrap flex-center l-code"
              style="width:80px;text-align:center"
              v-if="isCooperating"
            >责任人 <BaseIcon icon="down" color="var(--subtext3)" /></div>
            <div class="flex-wrap" style="flex-grow:1;text-align:left;padding:0 15px;">计划内容</div>
            <div class="flex-wrap hover-lighter" @click="handleChangeSort('deadline')" style="text-align:center;padding:0 5px;width:190px;">计划时间 <BaseIcon v-if="sort_key=='deadline'" :icon="sort_order=='asc'?'ios-arrow-up':'ios-arrow-down'" style="margin-left:5px;" color="var(--subtext3)" /></div>
            <div class="flex-wrap" style="text-align:center;padding:0 5px;width:100px;"></div>
            <div class="flex-wrap flex-center" style="width:90px;text-align:center">操作</div>
          </div>
          <div style="height:calc(100% - 60px);overflow-y:auto;background:var(--bg3)">
            <BaseEmpty v-if="filteredTasks.length == 0"></BaseEmpty>
            <template v-for="(t,i) in filteredTasks">
              <TaskItem
                :value="t"
                :index="i"
                :key="'task_'+i"
                @event="handleTaskEvent(t,$event)"
                :multi-users="isCooperating"
                :hide-group="filterGroup|| groupId?true:false"
                :hide-project="filterProject || projectId?true:false"
                :self="session.id"
              />
            </template>
          </div>
        </div>
        
        <div
          style="border-top:1px solid var(--border);height:30%;width:100%;flex-shrink:0;background:var(--bg3);"
          v-if="mode != 'task' &&  mode !='outdate' && filteredFinishedTasks.length > 0"
        >
          <div
            style="padding:5px;font-size:14px;font-weght:bold;background:var(--bg2);color:var(--text2);border-top:1px solid var(--border);border-bottom:1px solid var(--border)"
          >
            <BaseIcon icon="md-list" />
            已完成({{filteredFinishedTasks.length}})
          </div>
          <div style="height:calc(100% - 30px);overflow-y:auto">
            <template v-for="(t,i) in filteredFinishedTasks">
              <TaskItem
                :value="t"
                :index="i"
                :key="'task_'+i"
                @event="handleTaskEvent(t,$event)"
                :hide-group="filterGroup|| groupId?true:false"
                :hide-project="filterProject || projectId?true:false"
                :multi-users="isCooperating"
                :self="session.id"
              />
            </template>
          </div>
        </div>
      </div>
      <div
        style="flex-shrink:0;height:100%;width:600px;border-left:1px solid var(--border);"
        v-if="mode!='outdate' && mode != 'task' &&  mode != 'total'"
      >
        <div class="flex-wrap flex-between" style="height:46px;padding:0 10px;">
          <div
            style="padding:2px 5px;font-size;20px;color:var(--primary);font-weight:bold;margin-right:10px;"
          >{{currentPrompt}}</div>
          <div class="flex-wrap">
            <template v-if="mode != 'total' && mode != 'template' && mode !='outdate'">
              <DatePicker
                :type="mode"
                transfer
                v-model="currentDate"
                style="margin-right:10px;width:140px;"
                @on-change="handleSelectDate"
              ></DatePicker>
              <Button @click="$refs.calendar.prevMonth()" style="margin-right:10px">上一月</Button>
              <Button @click="$refs.calendar.Today()" style="margin-right:10px">今天</Button>
              <Button @click="$refs.calendar.nextMonth()" style="margin-right:10px">下一月</Button>
            </template>
          </div>
        </div>
        <div style="width:calc(100%);height:350px;position:relative;background:var(--bg3);">
          <PanelCalendar
            ref="calendar"
            style="height:350px;width:calc(100% - 10px);margin:0 5px;padding:5px 0;border:none;"
            hide-date
            v-if="mode!='outdate'"
          />
        </div>
        <div style="height:calc(100% - 400px);overflow-y:auto;background:var(--bg3);">
          <template v-for="t in finished">
            <TaskHistoryItem :value="t" @event="handleTaskEvent(t,$event)" :key="t.id" />
          </template>
        </div>
      </div>
    </AppClient>

    <Modal v-model="showConfig" footer-hide title="系统配置">
      <div
        class="flex-wrap flex-between align-start"
        style="padding:20px;border:1px solid var(--border);border-radius:5px;background:var(--bg3);color:var(--text3)"
      >
        <div>
          <template v-for="mode in modes">
            <div class="flex-wrap" :key="mode.key" style="margin-bottom:8px;">
              {{ mode.name }}
              <i-switch
                :value="!config[`task.hidden_mode.${mode.key}`]"
                @on-change="handleSetConfig(mode.name,$event)"
                style="margin-left:20px"
              />
            </div>
          </template>
        </div>
        <Divider type="vertical" style="height:200px" />
        <div>
          同步钉钉备忘录
          <i-switch
            :value="config.sync_ding"
            @on-change="handleSetConfig('task.sync_ding',$event)"
            style="margin-left:20px"
          />
        </div>
      </div>
    </Modal>

    <Modal v-model="showAddOutdated" footer-hide title="添加逾期任务" :width="900">
      <div class="flex-wrap">
        <RadioGroup v-model="selected_outdated_type" type="button">
          <Radio label>所有</Radio>
          <Radio label="key">年</Radio>
          <Radio label="month">月</Radio>
          <Radio label="date">日</Radio>
        </RadioGroup>
        <Button
          style="margin-left:5px"
          @click="selected_outdated = outdatedTasks.filter(v=>(!selected_outdated_type) || v.datetype == selected_outdated_type).map(v=>v.id)"
        >全选</Button>
        <Button style="margin-left:5px" @click="selected_outdated=[]">取消选中</Button>
      </div>
      <div style="height:500px;overflow-y:auto;margin-top:10px;padding:10px;background:var(--bg3);">
        <CheckboxGroup v-model="selected_outdated">
          <template
            v-for="t in outdatedTasks.filter(v=>(!selected_outdated_type) || v.datetype == selected_outdated_type)"
          >
            <div class="flex-wrap" :key="t.id">
              <Checkbox :label="t.id">
                <span
                  style="padding:2px;color:#fff;background:var(--primary);font-size:12px;"
                >{{datetype_names[t.datetype]}}</span>
                <span
                  style="color:var(--primary);background:var(--bg2);padding:2px;font-size:12px;margin-right:5px;"
                >{{moment(t.created_at).format("YYYY/MM/DD")}}</span>
                {{t.content}}
              </Checkbox>
            </div>
          </template>
        </CheckboxGroup>
      </div>
      <div class="flex-wrap flex-between" style="margin-top:10px">
        <div class="flex-wrap"></div>
        <div class="flex-wrap">
          <Button type="primary" @click="handleSubmitAddOutdated">提交</Button>
          <Button style="margin-left:10px" @click="showAddOutdated = false">取消</Button>
        </div>
      </div>
    </Modal>

    <Drawer v-model="showEditTask" footer-hide :mask-closable="false" :title="task.id?'修改计划':'新建计划'" width="712">
      <div>
        <Input
          size="large"
          ref="taskContent"
          :type="task_mode?'text':'textarea'"
          style="border:1px solid #c0c0c0"
          :rows="task_mode?1:5"
          :maxlength="512"
          v-model="task.content"
          show-word-limit
          placeholder="输入计划名称"
          @on-enter="handleEnter"
        />
        <BaseTagsInput 
          v-model="task.tags" 
          style="margin-top:10px" 
          :tags="cur_tags.map(v=>v.name)" 
        />
        <div 
          style="height:300px;position:relative;margin-top:10px;" 
          v-if="task_mode"
        >
          <BaseTextEditor
            size="large"
            ref="taskContent"
            type="textarea"
            :rows="5"
            v-model="task.note"
            :maxlength="1024"
            show-word-limit
            style
            placeholder="输入计划的详细描述"
            @on-enter="handleEnter"
          />
        </div>
        <div style="margin:10px">
          
          <div class="flex-wrap">
          切换{{ task_mode == 0 ?'详细模式':'精简模式' }}
          <i-switch v-model="task_mode" style="margin-left:10px"></i-switch>
        </div>
        <div style="color:var(--primary);font-size:10px;margin-top:5px;">说明：{{ task_mode == 0?'[详细模式] 可以填写任务的具体内容，计划时间等补充内容':'[精简模式] 精简的填写内容,适用于快速创建' }}</div>
        </div>
        <div
          class="memo-task-form"
          style="padding:20px;border:1px solid var(--border);margin-top:10px;border-radius:5px"
        >
          <div class="flex-wrap flex-left" style="margin-bottom:15px">
            <div class=flex-wrap  style="margin-right:20px"  v-if="task_mode" >
            <div class="l-field-name"><BaseIcon icon="md-calendar" size="17" /> 计划开始</div>
            <BaseDate
              style="margin-left:10px;width:140px;margin-right:40px"
              v-model="task.plan_started_at"
            />
          </div>
            <div class="flex-wrap">
              <div class="l-field-name"><BaseIcon icon="md-calendar" size="17" /> 计划完成</div>
            <BaseDate
              style="margin-left:10px;width:140px;margin-right:20px"
              v-model="task.deadline"
            />
          </div>
          
          </div>
          <div class="flex-wrap align-start">
            <div class="flex-wrap">
            <div class="l-field-name"><BaseIcon icon="xiangmu" size="17" /> 优先级别 </div>
            <RadioGroup v-model="task.priority" style="width:100px;margin-left:10px;">
              <div class="flex-wrap">
                <Radio v-for="(p,i) in priorities" :label="i" :key="p.name">
                  <span :style="`color:${p.color}`">{{ p.name }}</span>
                </Radio>
              </div>
            </RadioGroup>
           
          </div>
            <div  style="margin-left:100px">
            <div class="flex-wrap" >
              <div class="l-field-name"><BaseIcon icon="md-mail" size="17" /> 短信提醒</div> <i-switch v-model="task.sms" style="margin-left:10px"></i-switch></div>
            <div style="color:var(--primary);font-size:10px;margin-top:5px;">系统会在截止日期前一周发送短信</div>
          </div>
            
          </div>
          <div class="flex-wrap" style="margin-top:15px" v-if="!groupId">
            <div class="l-field-name"><BaseIcon icon="organization" size="17" /> 归属部门</div>
            <Select
              placeholder="选择部门"
              v-model="task.group_id"
              style="width:180px;margin-left:10px;margin-right:20px;overflow:hidden;"
              clearable
            >
              <BaseIcon slot="prefix" icon="organization" />
              <template v-for="g in groups">
                <Option :key="g.id" :value="g.id" :label="g.name"  style="white-space:nowrap">{{g.name}}</Option>
              </template>
            </Select>
          </div>
          <div class="flex-wrap" style="margin-top:15px" v-if="!projectId">
            <div class="l-field-name"><BaseIcon icon="pm2" size="17" /> 归属项目</div>
            <Select
              :placeholder="`选择项目(${my_projects.length})`"
              v-model="task.project_id"
              style="width:380px;margin-left:10px;overflow:hidden;"
              clearable
            >
              <BaseIcon slot="prefix" icon="pm2" />
              <template v-for="g in my_projects">
                <Option :key="g.id" :value="g.id" :label="g.name">{{g.name}}</Option>
              </template>
            </Select>
          </div>
          
          <div class="flex-wrap align-start" style="margin-top:20px" >
           <div class="flex-wrap"  style="margin-right:80px;" v-if="task_mode && (task.project_id || task.group_id)">
            <div class="l-field-name"><BaseIcon icon="md-person" size="17" /> 负责人员</div>
            <BaseUserSelectGrouped v-model="task.charger_id" style="width:120px;margin-left:10px" />
          </div>
          <div>
          <div class="flex-wrap">
            <div class="l-field-name"><BaseIcon icon="huiyi" size="17" /> 开启协同</div>
            <i-switch v-model="task.public" style="margin-left:10px"></i-switch>
           
          </div>
          <div style="color:var(--primary);font-size:10px;margin-top:5px;">开启协同功能后，此任务对其他成员可见</div>
        </div>
          </div>
          
        </div>

        <div class="flex-wrap flex-right" style="margin-top:10px">
          <Button type="primary" @click="saveTask">提交 Ctrl+Enter</Button>
          <Button style="margin-left:10px" @click="showEditTask=false">取消</Button>
        </div>
      </div>
    </Drawer>

    <Drawer v-model="showTask" footer-hide :title="task.content" :width="1400" :padding="0">
      <div class="flex-wrap flex-line align-start" style="height:100%;overflow:hidden;position:relative;">
        <div
          style="flex-grow:1;margin-right:10px;overflow-y:auto;"
        >
          <div class="flex-wrap flex-between">
            <div :style="`background:${task.finished?'var(--success)':'var(--primary)'};color:var(--hover-text);padding:1px 5px;border-radius:5px;`">任务{{ task.finished?'已完成':'进行中' }}</div>
            <div class="flex-wrap">
              <div
                class="flex-wrap flex-center"
                v-if="task.plan_started_at || task.created_at"
                :style="`height:24px;padding: 10px;color:var(--primary);font-size:13px;`"
              >计划开始 {{moment(task.plan_started_at || task.created_at).format("L")}}</div>
              <span v-if="(task.plan_started_at || task.created_at) && task.deadline"><BaseIcon icon="md-arrow-forward" /></span>
              <div
                class="flex-wrap flex-center"
                v-if="task.deadline"
                :style="`height:24px;padding: 10px;margin-right:10px;color:var(--primary);font-size:12px;`"
              >计划结束 {{moment(task.deadline).format("L")}}</div>
              <div
                class="flex-wrap flex-center"
                v-if="task.deadline"
                :style="`height:24px;padding: 10px 0;width:140px;border-radius:3px;border:1px solid ${moment(task.deadline).isAfter(moment())?'var(--primary)':'var(--error)'};background:linear-gradient(to right,#ffffff00,${moment(task.deadline).isAfter(moment())?'var(--primary)':'var(--error)'});margin-right:10px;font-size:12px;`"
              >
                <BaseIcon icon="clock" style="margin-right:5px" :color="moment(task.deadline).isAfter(moment())?'var(--primary)':'var(--error)'" />
                <span :style="`color:${moment(task.deadline).isAfter(moment())?'var(--primary)':'var(--error)'}`">
                {{moment(task.deadline).fromNow()}}
                </span>
                <span
                  :style="`color:var(--hover-text);margin-left:15px;font-size:12px;`"
                >{{ moment(task.deadline).isAfter(moment())?'截止':'已逾期' }}</span>
              </div>
            </div>
          </div>
          <div class="flex-wrap" style="margin-top:15px">
            负责人
            <BaseNameBoard :uid="task.charger_id || task.created_by" style="margin-left:15px" />

          </div>
          <div style="margin-top:15px;">
            任务详情描述
            <div
              style="padding:10px;background:var(--bg2);border:1px solid var(--border);border-radius:5px;min-height:100px;margin-top:5px;box-shadow:0 0 2px 1px var(--border)"
              v-html="task.note || '无'"
            ></div>
            <div
              style="border-top:1px solid var(--border);border-radius:5px;min-height:100px;margin-top:10px;font-size:10px;padding:10px;"
            ><div class="flex-wrap">
                <div style="margin-right:10px;width:80px;">创建人</div>
                <BaseNameBoard :uid="task.created_by" />
              </div>
              <div class="flex-wrap" style="margin-top:2px;">
                <div style="margin-right:10px;width:80px;">创建时间</div>
                <div>{{ moment(task.created_at).format('YYYY-MM-DD HH:mm:ss') }}</div>
              </div>
              
              <div class="flex-wrap" style="margin-top:5px;">
                <div style="margin-right:10px;width:80px;">短信提醒</div>
                {{ task.sms?'开':'关' }}
              </div>
            </div>
          </div>
        </div>
        <div style="width:700px;flex-shrink:0;overflow:hidden;border-left:1px solid var(--border);padding-left:10px;height:100%;">
          <div>
            任务跟踪及记录
            <div style="width:700px;height:200px;margin-top:10px;">
              <BaseTextEditor
                auto-focus
                placeholder="输入跟踪记录"
                @on-enter="handleEnterComment"
                v-model="comment.content"
                :rows="4"
              />
            </div>
            

            <div class="flex-wrap flex-between" style="margin-top:10px">
              <div><Checkbox v-model="comment.finished"></Checkbox> 同时完成此任务</div>
              <div class="flex-wrap">
              <Button @click="addComment" type="primary" >提交 Ctrl+Enter
                <BaseIcon icon="ios-arrow-forward" style="margin-left:5px"  />
              </Button>
              <Button @click="showTask=false" style="margin-left:10px" >取消
                
              </Button>
            </div>
            </div>
          </div>
          <div class="flex-wrap flex-center" style="height:30px" v-if="loading_comments">
            <BaseLoading />
          </div>
          <Divider style="margin:10px 0"></Divider>
          <div v-if="task.comments && task.comments.length > 0" style="height:calc(100% - 320px);overflow-y:auto;padding:10px;">
           
            <template v-for="c in task.comments">
              <div :key="c.id">
                <div class="flex-wrap flex-between">
                  <BaseNameBoard :uid="c.created_by" />
                  <div>
                    <span
                      style="color:var(--success);font-size:10px;margin-right:10px;"
                    >{{moment(c.created_at).format("YYYY-MM-DD")}} {{moment(c.created_at).fromNow()}}</span>

                    <a @click="removeComment(c.id)" v-if="c.created_by == session.id">删除</a>
                  </div>
                </div>
                <div
                  style="background:var(--bg3);padding:10px;margin:10px 0;border-radius:5px;white-space:pre-wrap;"
                  v-html="c.content"
                ></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </Drawer>
  </App>
</template>

<script>
import App from "@/components/app/app";
import AppBar from "@/components/app/bar";
import AppTitle from "@/components/app/title";
import AppButton from "@/components/app/button";
import AppClient from "@/components/app/client";
import moment from "moment";
import { mapGetters } from "vuex";
import AppTabs from "@/components/app/tabs2";
import TaskItem from "@/components/task/memos/TaskItem";
import TaskHistoryItem from "@/components/task/memos/TaskHistoryItem";
import { cloneDeep } from "lodash";
import UTIL from "@/utils";
moment.locale("zh-CN");
export default {
  components: {
    App,
    AppBar,
    AppClient,
    AppTabs,
    AppTitle,
    AppButton,
    TaskItem,
    TaskHistoryItem
  },
  watch: {
    $route() {
      this.load();
    },
    immediate: true,
    deep: true
  },
  mounted() {
    setTimeout(() => {
      let stateJSON = localStorage.getItem(this.session.id + "/core/tasks");
      if (stateJSON) {
        try {
          let state = JSON.parse(stateJSON);
          for (let key in state) {
            this.$set(this, key, state[key]);
          }
        } catch (e) {
          localStorage.removeItem(this.session.id + "/core/tasks");
        }
      }
      if(this.projectId || this.groupId){
        this.filterCooperating = true
        this.task_mode = 1
        this.mode = 'total'
      }
      // get user data
      this.load(true);
    }, 300);
  },
  data() {
    return {
      sort_key:'',
      sort_order:'asc',
      datetype_names: {
        total: "普",
        year: "年",
        month: "月",
        date: "日"
      },
      current: moment().format("YYYYMMDD"),
      cached_current: {
        date: moment().format("YYYYMMDD"),
        month: moment().format("YYYYMM"),
        year: moment().format("YYYY")
      },
      task_mode: 0,
      selected_outdated: [],
      selected_outdated_type: "",
      loading_comments: false,
      showAddOutdated: false,
      showConfig: false,
      config: {},
      formConfig: {},
      counts: {
        total: 0,
        task: 0,
        year: 0,
        month: 0,
        date: 0,
        weeK: 0,
        outdate: 0
      },
      cur_tags:[],
      mode: "date",
      searchText: "",
      filterGroup: "",
      filterProject: "",
      outdatedTasks: [],
      showTask: false,
      showEditTask: false,
      loading: false,
      task: {},
      comment: {},
      tasks: [],
      finished: [],
      filterTags:{},
      filterCooperating: false
    };
  },
  props:{
    projectId:{
      type:String
    },
    groupId:{
      type:String
    }
  },
  computed: {
    ...mapGetters("session", ["session", "my_projects"]),
    isCooperating() {
      return (this.projectId || this.groupId || this.filterProject || this.filterGroup) && this.filterCooperating ? true:false
    },
    priorities() {
      return [
        {
          name: "普通",
          color: "var(--bg1)"
        },
        {
          name: "重要",
          color: "var(--primary)"
        },
        {
          name: "紧急",
          color: "var(--error)"
        }
      ];
    },
    types() {
      return {
        total: "计划",
        date: "每日计划",
        year: "年度计划",
        week: "周计划",
        month: "月度计划"
      };
    },
    modes() {
      return [
        {
          name: "长期计划",
          iconText: "总",

          iconColor: "var(--primary)",
          key: "total"
        },
        {
          name: "工作任务",
          iconText: "任",
          iconColor: "var(--warning)",
          key: "task"
        },
        {
          name: "逾期计划",
          iconText: "逾",
          iconColor: "var(--error)",
          key: "outdate"
        },
        {
          name: "年度计划",
          iconText: "年",
          key: "year"
        },
        {
          name: "当月计划",
          iconText: "月",
          key: "month"
        },
        {
          name: "本周计划",
          iconText: "周",
          key: "week"
        },
        {
          name: "今日计划",
          iconText: "日",
          key: "date"
        }
      ];
    },
    tabs() {
      let items = [
        {
          name: "长期计划",
          iconText: "总",

          iconColor: "var(--primary)",
          key: "total"
        },
        {
          name: "工作任务",
          iconText: "任",
          iconColor: "var(--warning)",
          key: "task"
        },
        {
          name: "逾期计划",
          iconText: "逾",
          iconColor: "var(--error)",
          key: "outdate"
        },
        {
          name: "年度计划",
          iconText: "年",
          key: "year"
        },
        {
          name: "当月计划",
          iconText: "月",
          key: "month"
        },
        {
          name: "本周计划",
          iconText: "周",
          key: "week"
        },
        {
          name: "今日计划",
          iconText: "日",
          key: "date"
        }
      ];

      items.forEach(v => {
        v.count = this.counts[v.key];
      });

      if(this.projectId || this.groupId){
        items = [items[0]]
      }

      return items.filter(v=>!this.config[v.key]);
    },
    datePattern() {
      const pats = {
        date: "YYYYMMDD",
        month: "YYYYMM",
        year: "YYYY"
      };
      return pats[this.mode];
    },
    groups() {
      if (Array.isArray(this.session.groups)) {
        return this.session.groups.filter(v => v.namespaced);
      } else return [];
    },
    currentPrompt() {
      if (this.mode == "date") {
        return moment(this.current).format("M月");
      } else if (this.mode == "week") {
        return moment(this.current).format("YYYY年M月 - 第W周");
      } else if (this.mode == "month" || this.mode == "year")
        return moment(this.current).format("YYYY年");
    },
    currentDate: {
      get() {
        return moment(this.current, this.datePattern).format("YYYY/MM/DD");
      },
      set(v) {
        this.cached_current[this.mode] = this.current = moment(v).format(
          this.datePattern
        );
      }
    },
    filteredTasks() {
      
        let items = [...this.tasks.filter(
          v => {
            if(v.finished)
              return false
            if(this.searchText && (!v.content || !v.content.includes(this.searchText)))
              return false
            let filterTags = Object.keys(this.filterTags).filter(key=>this.filterTags[key])
            if(filterTags.length > 0 && v.tags){
              for(let i=0;i<v.tags.length;i++){
                if(filterTags.includes(v.tags[i]))
                  return true
              }
              return false
            }
            return true
          }
    
        )]

        if(this.sort_key){
          items.sort((a,b)=>(this.sort_order=='asc'?1:-1) * (a[this.sort_key] > b[this.sort_key]?1:-1))
        }
        return items
    },
    filteredFinishedTasks() {
      if (this.searchText) {
        return this.tasks
          .filter(
            v => v.finished && v.content && v.content.includes(this.searchText)
          )
          .sort((a, b) =>
            moment(a.finished_at).isBefore(b.finished_at) ? 1 : -1
          );
      } else {
        return this.tasks
          .filter(v => v.finished)
          .sort((a, b) =>
            moment(a.finished_at).isBefore(b.finished_at) ? 1 : -1
          );
      }
    }
  },
  methods: {
    changeSort(key){
      this.sortKey = key
      if(this.sortKey == key){
        this.sortOrder = !this.sortOrder
      }
    },
    add_tags(tags=[]){
      tags.forEach(v=>{
        let tag = this.cur_tags.find(c=>c.name == v)
        if(tag)
          tag.count++
        else
          this.cur_tags.push({name:v,count:1})
      })
    },
    getProjectUsers(){
      let project_id = this.projectId || this.filterProject
      this.$api.get(`projects/${project_id}`)
    },
    handleTaskEvent(task, event) {
      let eventMap = {
        reset: this.resetTask,
        open: this.openTask,
        finish: this.finishTask,
        cancel: this.unfinishTask,
        edit: this.editTask,
        delete: this.removeTask
      };
      if (eventMap[event]) eventMap[event](task);
    },
    saveState() {
      localStorage.setItem(
        this.session.id + "/core/tasks",
        JSON.stringify({
          mode: this.mode,
          current: this.current,
          filterProject: this.filterProject,
          filterGroup: this.filterGroup
        })
      );
    },
    handleChangeSort(key){
      if(this.sort_key == key){
        this.sort_order = this.sort_order== 'desc'?'asc':'desc'
      }else{
        this.sort_key = key
      }
    },
    load(forced) {
      let day = this.$route.query.day;
      if (day) {
        this.mode = "date";
      } else if (this.mode == "date") {
        day = moment().format("YYYYMMDD");
        this.current = day;
      }
      this.getData();
      this.getCount();
      this.getProjects();
      if (forced) this.getFinished();
    },
    getProjects() {
      this.$store.dispatch("session/getMyProjects");
    },
    getOutDatedTasks() {
      let url = `memos?key=outdate`;
      this.$api.get(url).then(res => {
        this.outdatedTasks = res.data.data;
        this.showAddOutdated = true;
      });
    },
    unfinishTask(t) {
      this.Confirm(
        "撤销此任务会接触任务的完成状态，并将此任务作为当前新增任务添加，是否继续？",
        () => {
          this.$api
            .patch("memos/" + t.id, {
              finished: false,
              finished_at: null,
              datekey: moment().format(this.datePattern)
            })
            .then(res => {
              let updateInfo = res.data.data;
              Object.assign(t, updateInfo);
              if ((index = this.finished.findIndex(v => v.id == t.id)))
                if (index != -1) this.finished.splice(index, 1);
              this.getCount();
            });
        }
      );
    },
    removeTask(t) {
      this.$api.delete(`memos/` + t.id).then(res => {
        let index = this.tasks.findIndex(v => v.id == t.id);
        if (index != -1) this.tasks.splice(index, 1);

        let index2 = this.finished.findIndex(v => v.id == t.id);
        if (index != -1) this.finished.splice(index2, 1);

        this.$Notice.success({ title: "操作成功",desc:"计划已删除" });
        this.getCount();
      });
    },
    resetTask(t) {
      this.$api.patch("memos/" + t.id + "?q=reset").then(res => {
        let index = this.tasks.findIndex(v => v.id == t.id);
        if (index != -1) this.tasks.splice(index, 1);
        this.$Notice.success({ title: "操作成功" });
        this.getCount();
      });
    },
    getCount() {
      let url = `memos?q=count`
      if(this.projectId)
        url += '&project=' + this.projectId
      this.$api.get(url).then(res => {
        this.counts = res.data.data;
      });
    },
    getFinished() {
      
      this.$api.get(`memos?q=finished&key=${this.mode}`).then(res => {
        this.finished = res.data.data;
      });
    },
    getData() {
      if (this.loading) return;
      this.loading = true;
      setTimeout(() => {
        let url = `memos?key=${this.mode}`;
        if (
          this.mode != "total" &&
          this.mode != "outdate" &&
          this.mode != "template"
        )
          url += `&datekey=${this.current}`;
        let group = this.groupId || this.filterGroup
        let project = this.projectId || this.filterProject
        if (group) url += `&group=${group}`;
        if (project) url += `&project=${project}`;

        let coorperating = this.filterCooperating
        if(this.groupId || this.projectId)
          coorperating = true

        if (coorperating) 
          url += `&coor=1`;
        this.$api
          .get(url)
          .then(res => {
            let items = res.data.data;
            items.forEach(v => {
              v.sms = v.sms ? true : false;
              v.public = v.public ? true : false;
            });

            let tagmap = {}
            items.forEach(v=>{
              if(v.tags){
                v.tags.forEach(t=>{
                  if(tagmap[t])
                    tagmap[t].count++
                  else
                    tagmap[t] = {
                      name:t,
                      count:1
                    }
                })
              }
            })
            this.cur_tags  =Object.values(tagmap)
            this.saveState();
            this.tasks = items;
          })
          .finally(() => {
            this.loading = false;
          }, 1500);
      }, 500);
    },
    finishTask(t) {
      this.$api.patch("memos/" + t.id, { finished: 1 }).then(res => {
        let updateInfo = res.data.data;
        let item = Object.assign(t, updateInfo);
        let index = this.tasks.findIndex(v => v.id == t.id);
        this.tasks.splice(index, 1, item);
        this.finished.splice(0, 0, t);
        this.getCount();
      });
    },
    async loadConfig() {
      return new Promise((resolve, reject) => {
        return this.$api
          .get("settings/taskconfig")
          .then(res => {
            let config = Object.parse(res.data.data);
            if (config) this.config = config;
            resolve(config);
          })
          .catch(e => {
            this.$Notice.error({ title: "加载配置失败" });
            reject();
          });
      });
    },
    handleConfig() {
      this.formConfig = cloneDeep(this.config);
      this.showConfig = true;
    },
    handleSaveConfig() {
      this.$api
        .patch("settings/taskconfig", configData)
        .then(() => {
          this.$Notice.success({ title: "操作成功", desc: "配置已保存" });
          this.config = this.formConfig;
        })
        .catch(e => {
          this.$Notice.error({ title: "操作失败", desc: e });
        });
    },
    handleSetConfig(key, value) {
      this.$api
        .patch(`sessions/${key}`, value)
        .then(res => {
          this.config[key] = value;
        })
        .catch(e => {
          this.$Notice.error({ title: "操作失败", desc: e });
        });
    },
    handleSelectDate(e) {
      let key = e.replaceAll("-", "");
      this.RouteTo("/core/tasks?day=" + key);
    },
    handleSubmitAddOutdated() {
      this.$api.post("/memos/recover", this.selected_outdated).then(res => {
        this.showAddOutdated = false;
        this.getData();
        this.getCount();
        this.getFinished();
      });
    },
    addComment() {
      let comment = this.comment;
      if (!comment.content) {
        this.Error("请输入内容再提交");
        return;
      }
      this.$api.post(`memos/${this.task.id}/comments`, comment).then(res => {
        let updateInfo = res.data.data;
        Object.assign(comment, updateInfo);
        if(comment.finished){
          delete comment.finished
          this.task.finished = true
          this.task.finished_at = moment().format()
        }
        this.task.comments.splice(0, 0, comment);
        this.comment = {};
      });
    },
    removeComment(cmt_id) {
      this.$api.delete(`memos/${this.task.id}/comments/${cmt_id}`).then(res => {
        let index = this.task.comments.findIndex(v => v.id == cmt_id);
        if (index != -1) this.task.comments.splice(index, 1);
      });
    },
    mapColor(i) {
      const colors = ["red", "orange", "green", "#3af"];
      if (i > 3) i = 3;
      return colors[i];
    },
    changeMode(m) {
      this.mode = m;
      this.current = this.cached_current[m];
      this.getData();
      this.getFinished();
    },
    openTask(t) {
      if (t.task_id) {
        this.RouteTo("/core/itasks/" + t.id + "/dashboard");
      } else {
        this.loading_comments = true;
        this.$api
          .get("/memos/" + t.id + "/comments")
          .then(res => {
            this.$set(this.task, "comments", res.data.data);
          })
          .finally(() => {
            this.loading_comments = false;
          });
      }
      this.task = t;
      this.showTask = true;
    },
    editTask(t) {
      this.task = cloneDeep(t);
      this.oldTask = t;
      this.showEditTask = true;
    },

    handleCreateComplex() {
      this.$store.commit("session/push", {
        name: "计划编辑器",
        icon: "organization",
        path: "/core/itasks/create/editor",
        base_url: "/core/itasks/create"
      });
      this.RouteTo("/core/itasks/create/editor");
    },
    handleCreate() {
      let task = {
        daykey: this.current,
        plan_started_at:moment().format(),
        priority: 0,
        public: true,
        charger_id:this.session.id
      };
      if (this.task.group_id) {
        task.group_id = this.task.group_id;
      }

      
      if (this.filterDep) {
        task.group_id = this.filterDep;
      }

      if (this.task.project_id) task.project_id = this.task.project_id;
      if (this.filterProject) {
        task.project_id = this.filterProject;
      }

      if(this.projectId){
        task.project_id = this.projectId
      }
      if(this.groupId)
        task.group_id = this.groupId

      this.task = task;

      this.showEditTask = true;
      setTimeout(() => {
        this.$refs.taskContent.focus();
      }, 500);
    },
    handleEnterComment(e) {
      if (e.ctrlKey) {
        this.addComment();
      }
    },
    handleEnter(e) {
      if (e.ctrlKey) {
        this.saveTask();
      }
    },
    saveTask() {
      if (this.task.id) {
        let modified = UTIL.compare_patch_object(this.oldTask, this.task);
        if (modified) {
          this.$api.patch(`memos/${this.task.id}`, modified).then(() => {
            Object.assign(this.oldTask, this.task);
            this.$Notice.success({ title: "修改成功" });
            this.showEditTask = false;
          });
        }
        return;
      }

      let item = {
        content: this.task.content,
        datekey: this.current,
        datetype: this.mode,
        public: this.task.public,
        priority: this.task.priority,
        note: this.task.note,
        sms: this.task.sms,
        finished:false,
        tags:this.task.tags
      };

      if (this.mode == "week") {
        item.datekey = moment(this.current).format("YYYYMMWW");
      }

      if (this.task.group_id) item.group_id = this.task.group_id;
      if (this.task.project_id) item.project_id = this.task.project_id;

      if (this.task.plan_started_at) {
        item.plan_started_at = this.task.plan_started_at;
      }

      if (this.task.charger_id) item.charger_id = this.task.charger_id;

      if (this.task.deadline) {
        item.deadline = this.task.deadline;
      }

      this.$api.post("memos", item).then(res => {
        Object.assign(item, res.data.data);
        this.tasks.splice(0, 0, item);
        this.$Notice.success({ title: "创建成功" });
        this.showEditTask = false;
        this.add_tags(item.tags)
        this.getCount();
      });
    }
  },
  routerOption: {
    as_index: true
  }
};
</script>

<style lang="less">
.memo-task-form {
  .value-wrap {
    height: 32px;
  }

  .l-field-name{
    font-weight: bold;
    color:var(--subtext2);
    background:var(--bg2);
    height:32px;
    line-height:32px;
    padding:0 8px;
    border-radius: 5px;
    border:1px solid var(--border);
  }

}

.list-enter-active,
.list-leave-active {
  animation-duration: 0.5s;

  animation-fill-mode: both;
}

.list-enter-active {
  animation-delay: var(--idelay);
}
.list-leave-active {
  animation-delay: calc(var(--idelay) - 0.1s);
  animation-duration: 0.2s;
}

.list-enter,
.list-leave-active {
  animation-name: fadeOutUp;
}

.list-leave,
.list-enter-active {
  animation-name: fadeInDown;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}
</style>