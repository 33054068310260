/* @/plugins/api.js
 * author : rackyz
 * date   : 2021/10/21
 * update : 2022/05/27
 * desc   : Enclose 'axios' with HSAPI, to create default api object and binding them to VUE.prototype. You can use 'this.api' to call each service.
 *   2022-05-27: 
 *     1. removed API name style interfaces, replaced with axios original methods to adapt restful API more natrually
 *     2. [store] In vuex/store only need to 
 *        import {API,COS} from '@/plugins/api'
 *     3. [vue] Installed $api,$cos to Vue prototype
 *     4. api methods:
 *        - SetAuthentication: set headers.Authentication to identify user cerification
 *        - SetEnterprise: set headers.Enterprise to identify Enterprise certification
 *        - Clear: clear all user login session state in api state and localStorage
 *        - 
 * 
 *      
 */

import axios from 'axios'
import config from '../config'
let CancelToken = axios.CancelToken
let source = CancelToken.source
import bus from './bus'
var o = {
  config
}

// Axios Server to backends Server
export let API = axios.create({
  baseURL: config.server,
  timeout: 20000
})

export let AI = axios.create({
  baseURL:config.ai_server,
  timeout:600000
})

export let COS = axios.create({
  baseURL: config.cosServer
})

// default api-version
API.defaults.headers = {
  "api-version": config.apiVersion || "v0"
}


const requestThreads = {}

API.interceptors.request.use(async config => {
  // if (requestThreads[config.url+(config.query?JSON.parse(config.query):"")] == undefined) {
  //   config.cancelToken = new CancelToken(function (c) {
  //     requestThreads[config.url+(config.query?JSON.parse(config.query):"")] = c
  //   })
  //   return config
  // } else {
  //   if (config.url.includes('files') == false &&  typeof requestThreads[config.url+(config.query?JSON.parse(config.query):"")] == 'function') {
  //     requestThreads[config.url+(config.query?JSON.parse(config.query):"")](config.url)
  //     requestThreads[config.url+(config.query?JSON.parse(config.query):"")] = setTimeout(() => {
  //       delete requestThreads[config.url+(config.query?JSON.parse(config.query):"")]
  //     }, 500)
  //   }

  //   return config
  // }
  return config
}, error => Promise.reject(error))

// axios response hooks
API.interceptors.response.use(data => {
  if (data.config && data.config.url) {

    clearTimeout(requestThreads[data.config.url])
    delete requestThreads[data.config.url]
  }

  if (data && data.data && data.data.code === -1) {
    return Promise.reject((data.data.error ? data.data.error : (data.data.message ? data.data.message : '未知')))
  }
  return data;
}, err => {
  if (axios.isCancel(err)) {
    return Promise.reject("抖动已清除:"+err)
  }
  if (err.message && err.message.includes('timeout')) {
    return Promise.reject('网络连接超时')
  }

  if (err.response) {
    if (err.response.status == 504 || err.response.status == 404) {
      return Promise.reject(`网络连接失败:[${err.response.status}]`)
    } else if (err.response.status == 403) {
      return Promise.reject('403-权限不足,请联系管理员!')
    } else if (err.response.status == 401) {
      API.ClearAll()
      bus.$emit('401')
      return Promise.reject('401-登录状态已过期')
    } else {
      return
    }
  }

  if (err.config && err.config.method == 'options') {
    return Promise.reject('服务器访问失败')
  }
  return Promise.reject(err);
})

// set header.Authorization
AI.SetAuthorization = function (token) {
  if (token){
    AI.defaults.headers.Authorization = token
  }
}

// set header.Authorization
API.SetAuthorization = function (token) {
  if (token)
    API.defaults.headers.Authorization = token
}

// set header.Enterprise
API.SetEnterprise = function (ent_id) {
  if (ent_id)
    API.defaults.headers.Enterprise = ent_id
}

API.afterLogin = (fn,handler,delayTime=100)=>{
  if(handler)
    clearTimeout(handler)
  return setTimeout(()=>{
    if(API.defaults.headers.Authorization){
      fn()
    }else{
      bus.$once('login',fn)
    }
  },delayTime)
  
}

// remove header.Enterprise
API.ClearEnterprise = function () {
  delete API.defaults.headers.Enterprise
}

API.getCounts = async (url,{filter,count_by})=>{
  let computedUrl = url+'?q=count'
  if(count_by)
    computedUrl += `&count_by=${count_by}`
  if(filter && typeof filter == 'object'){
    for(let key in filter){
      if(key && filter[key] !== undefined)
      computedUrl += `&${key}=${filter[key]}`
    }
  }
  return API.get(computedUrl)
}

API.getAll = async (url,{filter,order,related})=>{
  let computedUrl = url+'?'
  if(filter && typeof filter == 'object'){
    for(let key in filter){
      if(key && filter[key] !== undefined){
        let value = filter[key]
        if(Array.isArray(value)){
          if(value.length > 0)
            value = value.join(',')
          else{
            continue
          }
        }
        computedUrl += `&${key}=${value}`
      }
        
    }
  }
  if(order && typeof order == 'object' && order.key){
    computedUrl += `&order=${order.key}&desc=${order.order == 'desc'?1:0}`
  }

  if(Array.isArray(related)){
    computedUrl += `&related=${related.join(",")}`
  }

  return API.get(computedUrl)
}

API.getPaged = async (url,{page,pagesize,filter,order,related})=>{
  let computedUrl = url+'?q=page'
  computedUrl += `&page=${page}`
  if(pagesize)
  computedUrl += `&pagesize=${[pagesize]}`
  if(filter && typeof filter == 'object'){
    for(let key in filter){
      if(key && filter[key] !== undefined){
        let value = filter[key]
        if(Array.isArray(value)){
            if(value.length > 0){
              if(Array.isArray(value[0])){
                value = value.map(v=>v.join(',')).join(';')
              }else{
                
                value = value.join(',')
              }
            }
            else{
              continue
            }
          
          
        }
        computedUrl += `&${key}=${value}`
      }
        
    }
  }
  if(order && typeof order == 'object' && order.key){
    computedUrl += `&order=${order.key}&desc=${order.order == 'desc'?1:0}`
  }

  if(Array.isArray(related)){
    computedUrl += `&related=${related.join(",")}`
  }

  return API.get(computedUrl)
}

// clear all login session state
API.ClearAll = function () {
  delete API.defaults.headers.Authorization
  delete API.defaults.headers.Enterprise
  localStorage.removeItem('hs-token')
  localStorage.removeItem('current_enterprise')
}

o.install = async (vue) => {
  let token = localStorage.getItem('hs-token')
  if (token) {
    API.SetAuthorization(token)
    AI.SetAuthorization(token)
  }
  vue.prototype.$api = API
  vue.prototype.$cos = COS
  vue.prototype.$ai = AI
}

o.API = API
o.COS = COS
o.AI = AI


function GetObjectURL(file) {
  if(file && file.uri)
    return file.uri
  var url = null;
  if (window.createObjectURL != undefined) {
    // basic
    url = window.createObjectURL(file);
  } else if (window.URL != undefined) {
    // mozilla(firefox)
    url = window.URL.createObjectURL(file);
  } else if (window.webkitURL != undefined) {
    // webkit or chrome
    url = window.webkitURL.createObjectURL(file);
  }
  return url;
}

function GetFileName(url) {
  if (url) {
    let fileName = url.replace(/(.*\/)*([^.]+)/i, "$2");
    return fileName;
  }
}

function GetFileExt(url) {
  if (url) {
    let ext = url.substring(url.lastIndexOf(".") + 1);
    return ext;
  }
}


/** upload
 *  params:
 *    files - e.srcElement.files
 *    callback:{
 *       onPreload
 *       onProgress
 *       onSuccess
 *       onFail
 *    }
 *   config:{
 *      
 *   }
 */

function isImage(ext){
  return ['webp','gif','jpg','jpeg','png'].includes(ext)
}

COS.upload = async (files, {
  onPreload,
  onProgress,
  onFail,
  onSuccessFile,
  onSuccess
}, {
  coskey,
  vdisk
}={}) => {
  if (!files) return;
  let fileObjects = files.map((f) => {
    return {
        name: f.name,
        size: f.size,
        ext: GetFileExt(f.name),
        url: GetObjectURL(f),
        percent:0,
        loading: true,
        vdisk: vdisk || "ref",

        cancel() {
          if (this.source)
            this.source.cancel("取消")
        }
      }
    })


  // 1 - 预加载：将图片文件转换为ObjectURL
  //     此处可以获取当前文件对象的信息
  if (onPreload)
    onPreload(fileObjects)

  return new Promise((resolve,reject)=>{
    

  // 2 - 上传文件信息到文件服务器，换取文件ID
  API.post('files', fileObjects.map(v=>({
    name:v.name,
    size:v.size,
    ext:v.ext,
    url:v.url,
    vdisk:v.vdisk,
    loading:v.loading
  }))).then(res => {
    // 返回文件ID
    let resFiles = res.data.data
    console.log('pre-upload-files success')
    // 3 - 将文件以新ID命名，上传文件到cos
    return Promise.all(
      fileObjects.map((v, i, a) => {
        a[i].loading = true
        a[i].id = resFiles[i].id
        let file = files[i]
        let filekey = "files/" + resFiles[i].url
        file.loading = true
        var formdata = new FormData()
        formdata.append('key', filekey)
        formdata.append('file', files[i])
        fileObjects[i].source = CancelToken.source()

        // 对较大的图片 上传略缩图
        if(isImage(v.ext) && v.size > 1024*3){
          console.log('upload mini')
          createImageBitmap(files[i]).then(image=>{
            var canvas = document.createElement('canvas');
            var context = canvas.getContext('2d');
            canvas.width = 32;
            canvas.height = 32;
            // 核心JS就这个
            context.drawImage(image,0,0,32,32);
            canvas.toBlob((blob)=>{
              let file = new File([blob], 'image_32x32.png', { type: blob.type });
              var s_formdata = new FormData()
              let s_filekey = "files/" + resFiles[i].url.replace(`.${v.ext}`,`-32x32.${v.ext}`)
              console.log(s_filekey)
              s_formdata.append('key', s_filekey)
              s_formdata.append('file', file)
              COS.post('',s_formdata, {
                'content-type':'multipart/form-data',
                headers: {
                  Authorization: coskey
                }}).then(()=>{
                  console.log('32x32-success',s_filekey)
                }).catch((e)=>{
                  console.error('32-error',e)
                })
            })
            canvas.remove()
          })
         
        }

        
        return new Promise((resolve,reject)=>{
          return COS.post('', formdata, {
            'content-type':'multipart/form-data',
            headers: {
              Authorization: coskey
            },
            cancelToken: fileObjects[i].source.token,
            onUploadProgress: progressEvent => {
              if(!progressEvent)
                return
              let complete = (progressEvent.loaded / progressEvent.total * 100 | 0) + '%'
              fileObjects[i].percent = complete
              if (onProgress)
                onProgress(fileObjects[i])
              console.log('progress')
            }
          }).then(() => {
            // 3.Success - 单项文件上传完毕
            //    替换链接地址为cos地址
            console.log('cos-success')
            //    移除控制器和加载状态
             return API.post('files/success', fileObjects.map(v=>v.id)).then(() => {
              fileObjects[i].url = COS.defaults.baseURL + filekey
              delete a[i].loading
              delete a[i].percent
              delete fileObjects[i].source
              if (onSuccessFile)
                 onSuccessFile(a[i], i)
               resolve(a[i])
             })
          }).catch(e => {
            console.log('cos-error',e)
            
            if (e && e.message == "取消上传") {
              a[i].canceled = true
              return
            }
            API.post('files/cancel', fileObjects.map(v =>v.id)).then(()=>{
             
             reject(e)
            }).catch(e=>{
              reject(e)
            })
          })
        }) 
        
        
      })
      ).then(() => {
        console.log('promise-all success')
        if (onSuccess){
          onSuccess(fileObjects)
        }
        resolve(fileObjects)
      }).catch(e=>{
        console.error("promise.all-error",e)
        if (onFail) 
          onFail(e)
        
      })
      }).catch(e=>{
        if (onFail) 
          onFail(e)
        console.error("文件信息上传失败",e)
      })
  })
}

export default o
