<template>
  <div style="padding:10px;background:#eee">
    以下为引用数据
    <UgoForm
      ref = "form"
      :form="fields"
      :editable="false"
      :value="formData"
    >
    </UgoForm>
  </div>
</template>

<script>
  export default {
    cname:"数据引用",
    icon:'danxuan',
    data(){
      return {
        formData:{}
      }
    },
    computed:{
      fields(){
        return []
      }
    },
    properties:[
      {
        label:'引用内容',
        key:'ref',
        control:'FieldSelect',
      }
    ]
  }
</script>

<style lang="scss" scoped>

</style>