var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pm-page",staticStyle:{"width":"100%","position":"relative","height":"auto !important"}},[_c('div',{staticStyle:{"overflow":"hidden","height":"auto","margin":"20px","background":"none !important"}},[_c('Form',{staticClass:"ugo-form",attrs:{"label-width":_vm.form.labelWidth || 100,"inline":""}},[(_vm.form_id && _vm.fields.length == 0)?_c('BaseEmpty',{attrs:{"msg":"表单配置错误, 请联系管理员"}}):_vm._e(),(_vm.form.html)?[_c('BaseForm',{attrs:{"form":{
            layout:_vm.form.html,
            def:_vm.formDef,
            option:{
              hideTools:true
            }
          },"editable":_vm.editable},on:{"submit":function($event){_vm.formData=$event}},model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})]:[_vm._l((_vm.fields),function(p){return [_c('div',{key:p.id,staticClass:"form-item"},[(_vm.isContainer(p.control))?[_c(p.control,{tag:"component",attrs:{"config":p.config || {},"list":_vm.fields.filter(v=>v.parent_id == p.key),"model":_vm.model,"editable":_vm.editable}})]:[_c(p.control,{tag:"component",attrs:{"config":p.config || {},"editable":_vm.editable,"label":p.name,"required":p.required,"value":_vm.model[p.key]==undefined?_vm.value[p.key]:undefined},on:{"input":function($event){_vm.model[p.key] = $event}}})]],2)]})]],2)],1),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }