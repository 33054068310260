<template>
    <Modal 
      :value="value"
      footer-hide
      icon="md-document"
      :title="(data.id?'编辑':'新建') +' - ' + this.viewDef.name"
      @input="$emit('input',$event)"
      :width="width"
      >  
      <BaseLoading v-if="loading_model">加载中</BaseLoading>
      <Form 
          :model="formData"
          v-else
          :rules="viewDef.rules"
          :label-width="110"
          ref="form"
      >
      <template v-for="s in viewDef.layout">
      <BaseFormSection :title="s.name" :key="s.key"> 
      <template v-for="f in s.children">
          <Row :key="f.key" v-if="f.control=='Row'" :gutter="16">
            <template v-for="t in f.children">
                <Col :key="t.id" :span="t.span">
                    <FormItem 
                        :label="t.name"
                        :prop="t.key"
                        :required="t.requried"
                    >
                        <component 
                            :is="`M${t.control}`"
                            
                            v-model="formData[t.key]"
                            v-bind="t"
                            :db="formData"
                        />
                    </FormItem>
                </Col>
            </template>
          </Row>
          <Row   :key="f.key" v-else-if="!f._if || $util.eval_array(f._if,formData)">
              <Col :span='22'>
              <FormItem 
                  :label="f.name"
                  :prop="f.key"
                  :required="f.requried"
              >
                  <component 
                    :is="`M${f.control}`"
                    
                    v-model="formData[f.key]"
                    v-bind="f"
                    :db="formData"
                  />
              </FormItem>
          </Col>
          </Row>
          
        </template>
      </BaseFormSection>
    </template>
      <div class="flex-wrap flex-right" style="margin-right:10px">
          <Button 
              type="primary"
              style="margin-right:10px"
              icon="md-checkmark"
              @click="handleSubmit"
              :loading="submitting"
          >
              提交
          </Button>
          <Button @click="handleCancel">取消</Button>
         </div>
      </Form>
    </Modal>
  </template>
  
  <script>
  import UTIL from '@/utils'
  import {cloneDeep} from "lodash"
  import MInput from './controls/Input'
  import MSelect from './controls/Select'
  import MCheckbox from './controls/Checkbox.vue'
  import MRadio from './controls/Radio.vue'
  import MDate from './controls/Date.vue'
  import MFile from './controls/File.vue'
  import MSwitch from './controls/Switch.vue'
  import MUserSelect from './controls/UserSelect.vue'
  import MDepSelect from './controls/DepSelect.vue'
  import MCertificationSelect from './controls/CertificationSelect.vue'
  import MProjectSelect from './controls/ProjectSelect.vue'
  import MTagsInput from '@/components/base/TagsInput'
  import MFileSelect from '@/components/base/FileSelect'
  import MMultiForm from './controls/MultiForm'
  export default {
    components:{
        MInput,
        MSelect,
        MCheckbox,MRadio,MDate,MFile,MSwitch,MUserSelect,MCertificationSelect,MProjectSelect,MTagsInput,MFileSelect,MMultiForm,MDepSelect},
      props:{
          value:{
              type:Boolean
          },
          data:{
              type:Object,
              default:()=>({})
          },
          model:{
            type:String,
            default:""
          },
          width:{
            type:Number,
            default:600
          },
          view:{
            type:String,
            default:"edit"
          },
          loading:{
              type:Boolean,
              default:false
          }
      },
      watch:{
        modelWatchKeys:{
            handler(v){
                if(v && v.model && v.view){
                    this.$api.afterLogin(()=>{
                        this.getData()
                    })
                }
            },
            deeply:true,
            immediate:true
        },
          data:{
              handler(v){
                  if(this.data && this.data.id)
                       this.formData = cloneDeep(this.data)
                  else
                      this.formData = this.initFormData()
              },
              deep:true
          }
      },  
      mounted(){
           
      }, 
      computed:{
        modelWatchKeys(){
            return {
                model:this.model,
                view:this.view
            }
        }
      },
      data(){
          return {
                flow:{},
                formData:{},
                deps:[],
                submitting:false,
                loading_model:false,
                updated:{},
                viewDef:{
                    name:'',
                    layout:[],
                    rules:{}
                },
          }
      },
      methods:{
        
          getData(){
            this.loading_model = true
            this.$api.afterLogin(()=>{
                this.$api.get(`models/${this.model}?view=${this.view}`).then(res=>{
                
                this.viewDef = res.data.data
                if(this.data && this.data.id)
                  this.formData = cloneDeep(this.data)
              else
                  this.formData = this.initFormData()
            }).catch(e=>{
                this.Error(e)
            }).finally(()=>{
                this.loading_model = false
            })   
            })
              
          },
          initFormData(){
              return cloneDeep(this.viewDef.defaults || {})
          },
          stopLoading(){
              this.submitting = false
          },
          handleSubmit(){
              this.$refs.form.validate((valid)=>{
                  if(valid){
                     this.submitting = true
                      if(this.data.id){
                          let updateData = UTIL.gpt_compare_patch_object(this.data,this.formData,null,this.viewDef.unpatch_fields)
                          if(!updateData){
                              this.handleCancel()
                              return
                          }
                          this.$emit('on-event',{type:'update',value:updateData})
                      }else{
                          this.$emit('on-event',{type:'create',value:this.formData})
                      }
                      
                  }
              })
          },
          handleCancel(){
            this.$emit('input',false)
              this.$emit('on-event',{type:'cancel'})
          }
      }
  }
  </script>
  
  <style>
  
  </style>