<template>
<div class="flex-wrap" style="flex-wrap:wrap">
  <template v-for="uid in localUids">
    <BaseNameBoard style="width:15px;overflow:visible;" :style="`width:${showName?80:15}px`" :key="uid" :hide-name="!showName" :uid="uid" />
    
  </template>
  <Tooltip>
    <BaseAvatar size="small"  v-if="maxShow >0 && maxShow < uids.length">+{{uids.length - maxShow}}</BaseAvatar>
    <div class="flex-wrap" slot="content" style="padding:10px">
       <template v-for="uid in localUids">
        <BaseNameBoard  :key="uid" hide-name :uid="uid" />
      </template>
    </div>
  </Tooltip>
  
</div>
</template>

<script>
export default {
  props:{
    uids:{
      type:Array,
      default:()=>([])
    },
    maxShow:{
      type:Number,
      default:3
    },
    showName:{
      type:Boolean,
      default:false
    }
  },
  computed:{
    localUids(){
      if(Array.isArray(this.uids)){
        if(this.maxShow > 0){
          return this.uids.slice(0,this.maxShow)
        }else{
          return this.uids
        }
        
      }else{
        return []
      }
    },
    users(){
      return this.$store.getters["session/users"]
    }
  }
}
</script>

<style lang="less">
.name-board{
  
}
</style>