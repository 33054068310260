import { CircleNode, CircleNodeModel, h } from '@logicflow/core';
// 自定义结束节点（圆形，带方形图标）
class EndNode extends CircleNode {
    getShape() {
      const { model } = this.props;
      const { x, y, r } = model; // 圆形半径
  
      // 定义圆形中间的方形 SVG 图标
      const squareIcon = h('svg', {
        x: x - 8, // 方形图标居中
        y: y - 8,
        width: 16,
        height: 16,
        viewBox: '0 0 1024 1024',
        fill: '#FF4D4F' // 红色方形图标，表示结束
      }, [
        h('rect', {
          x: 0,
          y: 0,
          width: 1024,
          height: 1024
        })
      ]);
  
      return h('g', {}, [
        // 绘制圆形背景
        h('circle', {
          cx: x,
          cy: y,
          r, // 半径
          fill: '#FFF1F0', // 背景颜色
          stroke: '#FF4D4F', // 边框颜色
          strokeWidth: 2
        }),
        // 添加方形图标
        squareIcon
      ]);
    }
  }

// 自定义结束节点模型
class EndNodeModel extends CircleNodeModel {
  initNodeData(e) {
    super.initNodeData(e);
    this.r = 30; // 设置圆形节点的半径
    this.text = ''; // 结束节点通常不需要文本，所以设为空
  }

  // 限制节点的连接逻辑：只能连入，不能连出
  setAllowLinkIn() {
    return true; // 允许进入的连线
  }

  setAllowLinkOut() {
    return false; // 禁止输出的连线
  }
}

export default {
    type:'finish_node',
    view:EndNode,
    model:EndNodeModel
};
