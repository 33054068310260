<style lang="less">
.multi-form{
  .ivu-form-item-content{
    margin-left:100px !important;
    flex-grow:1;
  }
}

</style>
<template>
    <div class="container multi-form"> 
        <Draggable 
          v-model="items" 
          :animation="500" 
          @input="emitChange"
        >
          <template v-for="(item,index) in items">
            
            <Form style="border:1px solid var(--border);position:relative;padding:20px;margin-bottom:10px" :key="'_f'+index" :label-width="100">

                <div class="flex-wrap flex-center" style="position:absolute;left:10px;top:10px;color:var(--subtext2);background:var(--border);border:1px solid var(--subtext2);width:20px;border-radius:3px;height:20px;" v-if="!single">{{ index+1 }}</div>
                <div class="flex-wrap align-start" style="flex-wrap:wrap;">
                <template v-for="c in columns">
                    <FormItem :label="c.name" :key="'_colc'+c.key+index" style="margin:0 10px;"
                    class="flex-wrap"
                    :style="`flex-wrap:no-wrap;width:${c.width && c.width.includes('%')?c.width:'calc(50% - 20px);margin:0 10px;'};position:relative;`">
                        <component :is="c.control" v-bind="c.option"  @click="handleClick(c,item,index)" @input="emitChange" v-model="item[c.key]" />
                    </FormItem>
                
            </template>
        </div>
                <BaseIcon icon="md-copy" v-if="!single" style="position:absolute;right:30px;top:10px;" @click="copy(index)" /> 
                <BaseIcon icon="md-trash" v-if="!single" style="position:absolute;right:10px;top:10px;" @click="remove(index)" /> 
            </Form>
            
          </template>
        </Draggable>
        
        <BaseEmpty v-if="items.length == 0" image style="padding:20px;">
          <div class="flex-wrap flex-center" style="margin-bottom:20px" >
           <Button icon="md-add" size="small" @click="add">新增一行</Button>
          </div>
        </BaseEmpty>
        <div v-else class="flex-wrap flex-between" style="margin:5px 10px">
          <Alert v-if="error" type="error" size="small" style="padding:3px 5px">{{error}}</Alert>
          <div class="flex-wrap" v-if="!single"  style="margin-bottom:20px;margin-top:5px;">
  
            <Button icon="md-add" size="small" @click="add">新增一行</Button>
            <slot name="button"></slot>
          </div>
        </div>
    </div>
  </template>
  <script>
  
  import Draggable from 'vuedraggable'
  import {cloneDeep} from 'lodash'
  function evil(fn,params) {
      params.fix = (total,items,key,index)=>{
        return formatNumber(total - formatNumber(items.filter((v,i)=>i!=index).reduce((acc,item)=>{
          return acc + item[key]
        },0)))
      }
      var Fn = Function;  //一个变量指向Function，防止有些前端编译工具报错
      return new Fn('params','console.log(params);console.log('+fn+');return ' + fn)(params);
  }
  function formatNumber(e){
    if(isNaN(e))
      return 0
    else 
      return parseInt(e*100)/100
    
  }
  export default {
    components: { Draggable },
    data() {
      return {
        items: [{}],
        vid:0
      }
    },
    created(){
     
      this.vid = parseInt(Math.random()*10000)
    },
    props: {
      value: {
        type: Array,
        default: () => ([])
      },
      columns:{
        type:Array,
        default:()=>([])
      },
      error:{
        type:String,
        default:()=>""
      },
      single:{
        type:Boolean
      },
      condition:{
        type:Object,
        default:()=>({})
      },
      model:{
        type:Object,
        default:()=>({})
      }
    },
    watch:{
      value:{
        handler(v){
           if (Array.isArray(this.value) && this.value.length > 0)
            this.items = cloneDeep(this.value)
          else{
            this.items = [{}]
            this.emitChange()
          }
          
        },
        immediate:true,
        deep:true
      }
    },
    mounted() {
      if (Array.isArray(this.value)&& this.value.length > 0)
        this.items = cloneDeep(this.value)
      else {
        this.items = [{}]
        this.vid = parseInt(Math.random()*1000)
        this.emitChange()
      }
    },
    methods: {
      handleClick(c,item,index){
        let model = this.model
        if(c.control=='Button'){
          this.$set(item,c.to,formatNumber(evil(c.from,{model,item,index})))
        }
      },
      validate(){
        if (this.check_result.state == "success")
          return true
      },
      getError(){
        return this.check_result.message
      },
      emitChange() {
        this.items.forEach((v,i)=>{
           v.order=(i+1)
            Object.assign(v,this.condition || {})
        })
        this.$emit('input',this.items)
      },
      add() {
        this.items.push({})
      },
      copy(i){
        this.items.splice(i,0,{...this.items[i],id:undefined})
        this.$emit('input',this.items)
      },
      remove(i) {
        this.items.splice(i, 1)
        this.$emit('input',this.items)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  </style>