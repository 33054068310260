<template>
  <App>
    <AppBar>
        <AppTitle name="流程管理" icon="lianjieliu" />
        <div></div>
    </AppBar>
    <AppMaskLoading 
        :loading="loading" 
        iconRight="lianjieliu"
        title="流程管理"
    />
    <AppLeft>
        <div class="flex-wrap" style="height:35px;border-bottom:1px solid var(--border);padding:0 5px;">
            <Button size="small" @click="handleCreate()" icon="md-add" style="margin-right:5px;">新流程</Button>
            <Input size="small" />

        </div>
        <div style="height:calc(100% - 35px);overflow-y:auto;">
            <BaseTree 
            ref="tree"
            
            :data="nodes" 
            :nodeRenderer="renderNode"
            @event="handleTreeEvent"
        />
        </div>
        
    </AppLeft>
    <AppRight style="padding:20px;">
        <div>数据详情</div>
        <div>是否启用</div>
        <Divider />
        <div>
            
            <div>发起权限</div>

        </div>
        <Divider />
        <div>
            <div>历史版本</div>
        </div>
    </AppRight>
    <AppClient 
        :collapse-left="true" 
        :collapse-right="true" 
        style="background:#fff;border-left:1px solid var(--border);border-right:1px solid var(--border);"
    >
        <BaseEmpty v-if="!selected || selected.id=='nbgz'"></BaseEmpty>
        <BaseEmpty v-else-if="!selected.versions || selected.versions.length == 0">
            <div>流程还未创建完成，点击 <a style="margin:0 5px;" @click="handleCreateVersion">设计流程</a></div>
        </BaseEmpty>
        <div class="full-container" style="padding:0 20px;height:100%;overflow:hidden;" v-else>
            <div class="flex-wrap" style="margin-top:20px;"> <div class="flex-wrap flex-center" style="height:50px;width:50px;border-radius:5px;" :style="`background:${selected.color || 'var(--border)'};margin-right:10px;`">
                <BaseIcon :icon="selected.icon" :size="35" color="#fff" />
            </div> 
                <div>
                    <h2>{{ selected.name }} </h2>
                    <div>{{ selected.description }}</div>
                </div>
            </div>
            <div class="flex-wrap flex-between" style="margin-top:10px;">
            <BaseVersion  :value="selected.active_version || 1" :menus="selected.versions">
                <span  style="margin-right:5px;color:var(--subtext2)">启用版本</span>
            </BaseVersion>
            <div class="flex-wrap">
            <Button size="small" style="margin-left:5px;" icon="md-create">
                修改
            </Button>
            <Button size="small" type="primary" style="margin-left:5px;" icon="ios-cube" @click="handleEditVersion(selected.versions[0])">设计
            </Button>

            <Button size="small" style="margin-left:5px;" icon="md-trash">删除</Button>
        </div>
        </div>
        <Divider style="margin:0;margin-top:10px;" />
            <div class="flex-wrap flex-between" style="height:50px">
                <div class="flex-wrap">
                <Button style="margin-right:10px;" icon="ios-paper-plane">发起流程</Button>
                <Input search style="width:200px" /><Button icon="md-refresh" style="margin-left:10px;" @click="getFlow(selected.id)" /></div>
           
                <div>
                    <ButtonGroup>
                        <Button type="success">在途 23</Button>
                        <Button>已完成 132</Button>
                        <Button>异常 4</Button>
                    </ButtonGroup>
                </div>
            </div>
            
            <div style="position:relative;height:calc(100% - 100px);">
                <BaseTable ref="table" full :columns="cols" :data="selected.instances" @event="handleTableEvent" />
            </div>
        </div>
    </AppClient>

    <Modal v-model="modalEditFlow" footer-hide :title="`${formData.id?'编辑':'新建'}流程`" :width="300">
        <Form ref="form" :model="formData" :rules="rules">
            <Row style="width:100%;">
                <Col :span="9"><FormItem label="图标" prop="name" required>
                <BaseIconSelect v-model="formData.icon" />
            </FormItem></Col>
                <Col :span="9"><FormItem label="颜色" prop="name" required>
                <BaseColorSelect v-model="formData.color" />
            </FormItem></Col>
            <Col :span="6">
            <div class="flex-wrap flex-center" style="height:50px;width:50px;border-radius:5px;" :style="`background:${formData.color || 'var(--border)'};`">
                <BaseIcon :icon="formData.icon" :size="35" color="#fff" />
            </div>
            </Col>
            </Row>
            
            <FormItem label="名称" prop="name" required>
                <Input v-model="formData.name" auto-focus clearable placeholder="请输入" />
            </FormItem>
            <FormItem label="描述" prop="description">
                <Input type="textarea" :rows="4" v-model="formData.description" clearable placeholder="请输入" />
            </FormItem>
            <div class="flex-wrap flex-center">
                <Button type="primary" @click="handleSubmit" :loading="submitLoading">提交</Button>
                <Button style="margin-left:20px;" @click="modalEditFlow=false">取消</Button>
            </div>
        </Form>
    </Modal>
  </App>
</template>

<script>
import App from '@/components/app/app'
import AppBar from '@/components/app/bar'
import AppClient from '@/components/app/client'
import AppTitle from '@/components/app/title'
import AppMaskLoading from '@/components/app/mask-loading'
import AppLeft from '@/components/app/left'
import AppRight from '@/components/app/right'
import PageStateMixin from '@/mixins/page_state'
import {pick} from 'lodash'
import { mapGetters } from 'vuex'
import UTIL from '@/utils'
export default {
  components:{App,AppBar,AppClient,AppTitle,AppMaskLoading,AppLeft,AppRight},
    routerOption:{
        as_index:true
    },
    mixins:[PageStateMixin],
    data(){
        return {
            loading:false,
            selected:null,
            flows:[],
            nodes:[],
            flow:{},
            formData:{},
            pageStateFields:['selected'],
            submitLoading:false,
            versions:[],
        }
    },
    mounted(){
        this.getData()
    },
    computed:{
        rules(){
            return {
                name:[{required:true,message:'必须输入流程名称'}]
            }
        },
        version_cols(){
            return [{
                type:'index',
                width:60,
                title:"序号"
            },{
                type:"text",
                key:"name",
                width:130,
                title:"版本",
                linkEvent:"open"
            },{
                type:"text",
                key:"note",
                width:200,
                title:"备注",
                linkEvent:"open"
            },{
                key:'count',
                title:'实例数',
                width:120
            },{
                type:"time",
                key:"created_at",
                width:120,
                title:"创建时间"
            },{
                type:"user",
                key:"created_by",
                width:120,
                title:"创建人"
            },{
                title:"操作",
                render:(h,{row})=>{
                    let edit = h('Button',{props:{size:'small'},on:{
                        click:()=>{
                            this.handleEditVersion(row)
                        }
                    }},'编辑')
                    let del = h('Button',{
                        style:'margin-left:5px',
                        props:{size:'small'},on:{
                        click:()=>{
                            this.handleDeleteVersion(row)
                        }
                    }},'删除')
                    let btns = [edit,del]
                    return h('div',{},btns)
                }
            }]
        },
        cols(){
            return [{
                type:'index',
                width:60,
                title:"序号"
            },{
                type:"text",
                key:"name",
                width:200,
                title:"名称",
                linkEvent:"open"
            },{
                type:"state",
                title:"状态",
                width:120,
                key:'state',
                option:{
                    states:['准备中','审核中','已通过','已退回','已闭合']
                }
            },{
                type:"time",
                key:"created_at",
                width:120,
                title:"创建时间"
            },{
                type:"user",
                key:"created_by",
                width:120,
                title:"创建人"
            },{
                title:"操作"
            }]
        }
    },
    methods:{
        handleCreate(){
            this.formData = {
                color:'var(--primary)',
                icon:'lianjieliu'
            }
            this.modalEditFlow = true
        },
        handleSubmit(){
            this.$refs.form.validate((valid)=>{
                if(valid){
                    this.submitLoading = true
                    this.$api.post('/oa/flows',this.formData).then(res=>{
                        this.getData()
                        this.modalEditFlow = false
                        this.Success('创建成功')
                    }).catch(e=>{
                        this.Error(e)
                    }).finally(()=>{
                        this.submitLoading =false
                    })
                }
                
            })
           
        },
        handleEditVersion(row){
            let path = `/core/flows/${row.id}/editor?flow-id=${this.selected.id}`
            this.$store.commit("session/push", {
                name: "流程编辑器",
                icon: "liujisuan",
                path,
                base_url: `/core/flows/${row.id}`
            });
            this.RouteTo(path);
        },
        handleDeleteVersion(v){
            this.Confirm(`将删除<span style='color:var(--primary);margin:0 3px;'>${this.selected.name} / ${v.version}</span>此版本，同时会删除此节点的所有实例，是否继续？`,()=>{
                this.$api.delete(`/oa/flow-versions/${v.id}`).then(res=>{
                    this.Success('删除成功')
                    this.getFlow(this.selected.id)
                }).catch(e=>{
                    this.Error(e)
                })
            })
        },
        handleCreateVersion(){
            let path = "/core/flows/create/editor?flow-id="+this.selected.id
            this.$store.commit("session/push", {
                name: "流程编辑器",
                icon: "liujisuan",
                path,
                base_url: "/core/flows/create"
            });
            this.RouteTo(path);
        },
        handleTableEvent({type,data}){
            if(type == 'open'){
                this.handleOpenInstance(data)
            }
        },
        handleCreateInstance(){
            let flow = this.selected
          let url = `/core/flows/${flow.id}/create`
          this.$store.commit("session/push_flow_create",{
            id:flow.id,
            name:'流程创建-'+flow.name,
          })
          this.RouteTo(url)
        },
        handleOpenInstance(inst){
          let url = `/core/flows/${inst.id}/instance`
          this.$store.commit("session/push_flow_create",{
            id:inst.id,
            name:inst.name,
          })
          this.RouteTo(url)
        },
        renderNode(h,node){
            let domIcon = h('BaseIcon' ,{style:'margin-right:5px',props:{icon:node.icon,color:node.color,size:20}})
            let domName = h('div',{style:'white-space:nowrap;width:100px;overflow:hidden;text-overflow:ellipsis',attrs:{
                title:node.name
            }},node.name+node.icon)
            let domCount = h('span',{style:'margin-left:3px'},`(${node.count || 0})`)
            let domStatus = h('span',{style:{marginLeft:'20px',color:node.enabled?'var(--success)':'var(--text2)'}},node.enabled?'已启用':'未启用')
            if(node.type !== 'dir'){
                return h('div',{
                class:"flex-wrap flex-between",
                style:'width:100%',
                },[domIcon,domName,domStatus])
            }else{
                return h('div',{
                class:"flex-wrap",
                style:{color:node.count > 0?'var(--text2)':'var(--subtext2)'}
            },[domName,domCount])
            }
            
           

           
            
        },
        handleTreeEvent({type,data}){
            if(type == 'select'){
                this.selected = data
                this.getFlow(data.id)
                 this.$refs.table.calcTableHeight()
            }
        },
        getData(){
            this.loading = true
            this.$api.get("oa/flows").then(res=>{
                let flows = res.data.data
                let nodes = [{
                    id:'gzcloud',
                    name:'系统流程',
                    type:'dir',
                    count:0,
                    icon:'nbgz'
                },{
                    id:'dd',
                    name:'钉钉流程',
                    count:0,
                    type:'dir',
                    icon:'ding'
                }]
                
                nodes = nodes.concat(flows.map(v=>{
                    return {
                        id:v.id,
                        name:v.name,
                        icon:v.icon,
                        color:v.color,
                        enabled:v.enabled,
                        parent_id:v.parent_id || 'gzcloud'
                    }
                }))
                this.flows = flows
                this.nodes = nodes
                if(this.$refs.tree && this.selected && this.selected.id){
                    this.$refs.tree.select(nodes.find(n=>n.id == this.selected.id))
                     this.getFlow(this.selected.id)
                       this.$refs.table.calcTableHeight()
                }
            }).finally(()=>{
                this.loading = false
            })
        },
        getFlow(id){
            this.loadingData = true
            this.$api.get(`oa/flows/${id}?related=versions`).then(res=>{
                let flow = res.data.data
                //flow.instances = flow.versions[0].instances
                
                this.selected = flow
            }).catch(e=>{
                this.Error(e)
            }).finally(()=>{
                this.loadingData = false
            })
        },
        getDingFlow(){
            this.$api.get("flows?q=ding").then(res=>{
                this.ding_flows=  res.data.data
                let nodes = nodes.concat(this.ding_flows.map(v=>{
                    return {
                        id:v.id,
                        name:v.name,
                        parent_id:v.parent_id || 'dd'
                    }
                }))
                this.nodes =  this.nodes.concat(nodes)
            })
        }
    }

}
</script>

<style>

</style>