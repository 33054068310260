<template>
  <Container>
    <div class="flex-wrap flex-between" style="padding:0 10px">
      <Toolbar 
        :items="tools"
        v-model="filter"
        :counts="counts"
        @on-event="handleEvent" 
      />
        
      <div class="flex-wrap">
      <Button style="margin-right:5px" icon="md-add" type="primary" @click="showCreateForm">新建项目</Button>
      <Button style="margin-left:5px" icon="md-download" @click="exportExcel" /> 
      </div>
    </div>
    <div style="height:calc(100% - 40px);padding:0 5px;">
        <BaseTable
            ref="table"
            :columns="columns"
            :data="items"
            :page="page"
            :page-size="pagesize"
            @event="handleEvent"
            :loading="loading"
            :count="total"
            border
        />
    </div>

    <Editor
      ref="editor" 
      v-model="showEditor"
      :data="current"
      model="BU_Sign_Project"
      @on-event="handleEvent"
    />
  </Container>
</template>

<script>
import Container from '@/components/app/container'
import Toolbar from '@/components/app/toolbar'
import Editor from '@/components/model/editor'
import moment from 'moment'
import {cloneDeep} from 'lodash'
export default {
  routerOption: {
    as_default: true
  },
  components:{Container,Toolbar,Editor},
    data(){
        return {
          showEditor:false,
            items:[],
            loading:false,

            current:{},
            formData:{},
            filter:{
              status:"草稿"
            }, counts:{
              "草稿":0,
              "审核中":0,
              "审核通过":0,
              "不通过":0
            },order:{
              key:'created_by',
              order:'desc'
            }, page:1,
            pagesize:20,
            total:10,
        }
    },
    computed:{
        tools(){
      return [{
        key:'search',
        placeholder:"输入项目名称/招标单位/搜索",
        type:"search",
        width:300
      },{
        key:"status",
        type:"tabs",
        subs:[{
          key:"草稿",
          icon:"file",
          countable:true,
          name:"草稿"
        },{
          key:"审核中",
          icon:"date",
          countable:true,
          name:"审核中"
        },{
          key:"审核通过",
          icon:"pm2",
          countable:true,
          name:"审核通过"
        },{
          key:"未通过",
          icon:"xiangmu2",
          countable:true,
          name:"未通过"
        }]
      },{
        key:'refresh',
        icon:'md-refresh',
        type:"icon-button"
      },{
        key:'clearFilter',
        icon:'md-filter',
        type:"icon-button"
      },]
    },
    columns(){
            return [{
                key:'id',
                title:'序号',
                align:"center",
                type:'index',
                width:60
            }, {key:'project_name',width:200,title:"项目名称",sortable:"custom",
            render:(h,{row})=>{
              let icon = h('BaseIcon' ,{props:{icon:'Building-',size:16,color:'var(--primary)'},style:'margin-left:10px;margin-right:5px;'})
              let tag = h('img',{attrs:{
                 src: "https://nbgz-pmis-1257839135.cos.ap-shanghai.myqcloud.com/beauty/new.gif"
              },style:'width:30px;height:15px;margin-left:5px;margin-right:10px;'})
              if(moment().subtract(3,'days').isAfter(moment(row.created_by)))
                tag = ''
              let name = h('div',{attrs:{title:row.project_name},style:"white-space:nowrap;text-overflow:ellipsis;width:calc(100% - 50px;);overflow:hidden;"},row.project_name)
              return h('div',{
                class:"flex-wrap",
                style:{
                  color:"var(--primary)",
                  fontWeight:'bold',
                  textAlign:"left",
                  cursor:'pointer'
                },
                on:{
                  click:()=>{
                   this.Edit(row)
                  }
                }
              },[icon,name,tag])
            }
          },{
                key:'entrust_form',
                title:'委托形式',
                type:'text',
                width:120,
                sortable:'custom'
            },{
                key:'filing_personnel_selection_process',
                title:'备案人员选配流程',
                type:'text',
                width:120,
                sortable:'custom'
            },{
                key:'contract_review_process',
                title:'合同编审流程',
                type:'text',
                width:120,
                sortable:'custom'
            },{
              key:'status',
              title:'合同履约担保',
              align:'center',
              width:300,
              children:[{
                key:'payment_form',
                width:100,
                title:'缴纳形式'
              },{
                key:'payment_amount',
                width:100,
                title:'缴纳金额'
              },{
                key:'payment_date',
                width:100,
                title:'缴纳日期'
              }]
            },{
                key:'personnel_contract_system_entry',
                title:'人员及合同系统录入',
                type:'text',
                width:160,
                sortable:'custom'
            },{
                key:'contract_distribution_record',
                title:'合同发放记录',
                type:'text',
                width:120,
                sortable:'custom'
            },{
                key:'contract_code',
                title:'合同编码',
                type:'text',
                width:120,
                sortable:'custom'
            },{
              key:'archives',
              title:'资料归档',
              align:'center',
              width:240,
              children:[{
                key:'bid_notice_files',
                width:120,
                title:'中标通知书'
              },{
                key:'contract_files',
                width:100,
                title:'合同'
              }]}]
        }
    },
    mounted(){
      this.getData()
    },  
    methods:{
      showCreateForm(){
      this.current = {}
      this.formData = {}
      this.showEditor = true
    },
    Edit(item){
      this.curent = item
      this.formData = {}
      this.showEditor = true
    },
      getData(){
      this.loading = true
      this.$api.afterLogin(()=>{
        this.$api.getPaged("/business/sign_projects",{
        page:this.page,
        pagesize:this.pagesize,
        order:this.order,
        filter:this.filter,
      }).then(res=>{
        let {count,list} = res.data.data
        this.items = list
        this.total = count
        this.loading = false
      })
      })
      
    },
        createItem(model){
      this.$api.post('/business/sign_projects',model).then(res=>{
        this.$Notice.success({title:"创建成功"})
        this.showEditor = false
        this.getData()
      }).finally(()=>{
        this.$refs.editor.stopLoading()
      })
    },exportExcel(){
      this.$refs.table.exportExcel()
    },
        handleEvent({type,key,value,data}){
      const eventMap = {
        add:this.showCreateForm,
        search:this.getData,
        create:this.createItem,
        refresh:()=>{
          this.getStatusCounts()
          this.getData()

        },
        update:this.updateItem,
        'tab-change':this.getData,
        'page-change':()=>{
          this.page = data
          this.getData()
        },
        'page-size-change':()=>{
           this.pagesize = data
          this.getData()
        },
        'sort':()=>{
           if(!data || !data.key)
            this.order = null
          else
            this.order = data
          this.getData()
        }
      }


      let eventHandler = eventMap[type]
      if(eventHandler){
        eventHandler(value)
      }
    
    },
    }
}
</script>

<style>

</style>