<template>
  <App>
    <AppBar>
      <div class="flex-wrap" style="font-size:16px;">
      <BaseIcon icon="table" :size="24" style="margin-right:5px" /> 表单/流程
      </div>
      <div>
        <Input search />
      </div>
    </AppBar>
     <AppLeft style="width:200px"></AppLeft>
    <AppClient style="background:var(--bg2);padding:10px;">
    
        <div class="flex-wrap">
          <Input search style="width:200px;margin-right:10px;" placeholder="输入名称关键字" />
          <Button icon="md-add" @click="handleEdit" style="">新建表单</Button>
     
        </div>
    <div class="flex-wrap flex-between" style="margin-top:10px;">
      <Tabs :items="tabs" :actived="activeTab" @on-click="activeTab=$event" />
      <div>
      
      </div>
    </div>
    <div style="width:100%;height:calc(100% - 80px);position:relative;">
      <BaseTable :columns="cols" :data="items" class="container"  @on-click="showInfo" />
      
      </div>
    </AppClient>

    <Drawer width="720" v-model="drawerInfo" :title="selected.name">
      <div style="width:680px;margin:0 auto">
        
        <div style="margin-top:20px">
          <FormInstance  class="docx" :form="selected" :data="formData" @change="changed=$event" :editable="true" />
        </div>
        <div class="flex-wrap split10" style="margin-top:30px">
        <Button type="primary" long @click="handleUse(selected)">创建</Button>
        <Button  long @click="handleEdit(selected)">编辑</Button>
         <Button  long  @click="handleDelete(selected)">删除</Button>
         </div>
         <div style="margin-top:10px">
          上传时间 {{moment(selected.created_at).format("LL")}}
        </div>
        <div>
          描述：{{selected.description || '无'}}
        </div>
      </div>
    </Drawer>
  </App>
</template>

<script>
import App from "@/components/app/app"
import AppBar from '@/components/app/bar'
import AppLeft from '@/components/app/left'
import AppClient from '@/components/app/client'
import Tabs from '@/components/app/tabs2'

import FormInstance from '@/components/form/html'
  let gloablId = 1
  export default {
    components:{App,AppBar,AppClient,Tabs,FormInstance,AppLeft},
    routerOption:{
      as_default:true
    },
    data(){
      return {
        items:[],
        activeTab:'all',
        drawerInfo:false,
        selected:{},
        formData:{}
      }
    },
    computed:{
      tabs(){
        return [{
          key:"all",
          iconText:"user",
          name:"全部"
        },{
          key:"mine",
          icon:"xiangmu2",
          name:"我的项目"
        },{
          key:"iso",
          icon:"nbgz",
          name:"ISO标准表单"
        },{
          key:"tmp",
          icon:"lianjieliu",
          name:"内嵌表单"
        }]
        
      },cols(){
          return [{
            type:'index',
            width:60,
            title:'序号'
          },{
            type:'state',
            width:120,
            key:'type',
            title:'类型'
          },{
            type:'text',
            width:200,
            align:'left',
            key:'name',
            title:'名称'
          },{
            type:'text',
            width:200,
            align:'left',
            key:'description',
            title:'备注'
          },{
            type:'text',
            width:120,
            sortable:false,
            title:'查看数据',
            render:(h,{row})=>{
              return h('Button',{props:{size:'small'}},'查看')
            }
          },{
            type:'text',
            width:120,
            sortable:false,
            title:'绑定模型',
            render:(h,{row})=>{
              return h('Button',{props:{size:'small'}},'绑定')
            }
          },{
            type:'user',
            width:120,
            align:'left',
            key:'created_by',
            title:'创建人'
          },{
            type:'time',
            width:120,
            align:'left',
            key:'created_at',
            title:'创建时间'
          },{
            type:"tool",
            title:'操作',
            buttons:['edit','delete'],
            width:140
          }]
        }
    },
    mounted(){
      this.getData()
    },
    methods:{
      showInfo(e){
        this.selected = e
        this.getFormData(e)
        this.drawerInfo = true
      },
      handleEdit(e){
        let id = e?e.id:gloablId++
        let url = "/core/forms/"+(id)+"/editor"
        this.RouteTo(url)
        this.$store.commit("session/push",{path:url,name:'编辑表单'+(e?`:${e.name}`:''),icon:"edit",base_url:url})
      },
      handleUse(e){
         let url = "/core/forms/"+(e.id)+"/info"
        this.RouteTo(url)
        this.$store.commit("session/push",{path:url,name:'填写表单'+(e?`:${e.name}`:''),icon:"edit",base_url:url})
      },
      handleDelete(e){

      },
      getData(){
        this.$api.get("forms").then(res=>{
          this.items = res.data.data
        })
      },
      	getFormData(e) {
			this.$api.get("forms/" + e.id).then((res) => {
				this.selected = res.data.data;
			});
		},
    }
  }
</script>

<style lang="scss" scoped>

</style>