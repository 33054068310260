<style lang="less">
article {
	table {
		margin: 10px auto;
	}
	p{
		img {
		margin: 10px 200px;
		}
	}
	
}
.v-show-content{
	background:none !important;
}
.v-note-wrapper{
	background:none !important;
}
</style>
<template>
	<div class="l-wrapper" style="overflow-y: auto; height: 100%;scroll-behavior:smooth" @scroll="getScroll">
		
		<div class="l-page" ref="page">
     
			<Spin fix v-if="loading">文章加载中</Spin>
			<template v-else>
        
        <Breadcrumb style="margin-top: 20px;border-bottom:1px solid var(--border);padding-bottom:20px;">
						<BreadcrumbItem to="/core/dashboard">首页</BreadcrumbItem>
						<BreadcrumbItem :to="`/core/articles-admin?t=${item.type}`">{{
							types[item.type] || "文章"
						}}</BreadcrumbItem>
						<BreadcrumbItem>详情</BreadcrumbItem>
					</Breadcrumb>
				<div style="padding: 40px 80px; padding-bottom: 10px">
					<h1
						style="
							font-weight: bold;
							font-size: 30px;
							padding-bottom: 20px;
							text-align: center;
						"
					>
						{{ item.name }}
					</h1>
					<div
						class="flex-wrap flex-between"
						style="
							color: #aaa;
							width:560px;
              margin:0 auto;
							font-size: 12px;
						"
					>
						<div class="flex-wrap">
							发布人
							<BaseNameBoard style="margin-left: 10px" :uid="item.created_by" />
						</div>
						<div style="margin-top: 5px">
							发布时间
							<span style="margin-left: 10px">{{
								moment(item.created_at).format("L HH:mm:ss")
							}}</span>
						</div>
					</div>
				</div>

				<article
					style="
						background: var(--bg3);
						padding: 20px 40px;
						overflow-y: auto;
					"
					class="hs-md"
				>
					<BaseMarkdownEditor
						style="min-height: 500px; margin-top: 10px;border:none;border-bottom:2px dashed var(--border);"
						:value="item.content"
						:preview="true"
						v-if="item.file_type == 'md' && item.content"
					>
					</BaseMarkdownEditor>
					<div
						v-else-if="item.file_type == 'html'"
						v-html="item.content"
						style="margin: 10px auto; width: 560px; zoom: 1.3"
					></div>
					<div style="padding: 20px" v-if="item.files">
						<div>文章配图</div>
						<img style="max-width:100%;" v-for="f in item.files" :src="f" />
					</div>
					
				</article>
          <div class="flex-col" style="position:fixed;right:50px;bottom:100px" v-if="scrollOffset > 50">
            <div class="float-button" @click="moveUp" v-if="scrollTop > 100">
            <BaseIcon icon="ios-arrow-up" size="50" color="var(--subtext3)"  />
            </div>
            <div class="float-button" style="margin-top:10px" @click="moveDown" v-if="scrollTop < scrollOffset - 100">
            <BaseIcon icon="ios-arrow-down" size="50" color="var(--subtext3)" />
            </div>
          </div>
		  <div style="padding:10px 80px;">
           <span style="">相关文章推荐</span>
           <div style="margin-top:5px;">
				<template v-for="a in item.recents">
					<div :key="a.id"><a @click="RouteTo(`/core/articles/${a.id}`)">{{ a.name }}</a></div>
				</template>
           </div>
          </div>
			</template>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { debounce } from "lodash";
export default {
	data() {
		return {
			loading: true,
      scrollTop:0,
      scrollOffset:0,
			item: {},
		};
	},
	watch: {
		id: {
			handler(v) {
				this.getData();
			},
		},
	},
	created() {
		var that = this;
		this.getData = debounce(function () {
			that.loading = true;
			let id = that.$route.params.id;
			that.$api
				.get(`articles/${id}`)
				.then((res) => {
					that.item = res.data.data;
					if(that.item.files){
						that.item.files = JSON.parse(that.item.files)
					}
          setTimeout(()=>{
            that.scrollOffset = that.$refs.page.clientHeight - that.$el.clientHeight 
          },500)
				})
				.finally(() => {
					that.loading = false;
				});
		}, 1000);

    this.getScroll = debounce(function(){
        that.scrollTop = that.$el.scrollTop
      },500)
	},
	mounted() {
		this.getData();
    
	},
	computed: {
		...mapGetters("session", ["getUser"]),
		types() {
			return {
				notice: "通知公告",
				"public-file": "公文发布",
				policy: "政策解读",
				knowledge: "技术文章",
				exp:'经验分享'
			};
		},
		id() {
			return this.$route.params.id;
		},
	},
	routerOption: {
		as_object: true,
	},
	metaInfo: {
		title: "公告",
	},
	methods: {
    moveUp(){
      this.scrollTop = this.$el.scrollTop = 0
      
    },
    moveDown(){
      this.scrollTop = this.$el.scrollTop = 99999
    }


  },
};
</script>

<style lang="less" scoped>
.l-wrapper {
	width: 100%;

	height: 100%;
	position: relative;
	overflow: hidden;
	
}
.l-page {
	margin: 0 auto;
	padding: 10px;
	width: 1200px;
	min-height: 800px;
	padding-bottom: 100px;
	overflow: hidden;
}

.float-button{
  cursor: pointer;
}

.float-button:hover{
  i{
    color:var(--primary) !important;
  }
}

.float-button:active{
  position: relative;
  bottom:-1px;
  right:-1px;
}
</style>