<template>
  <div class="container" style="height:100%;overflow-y:auto;">
    <div class="flex-wrap align-start l-page-wrap">
      <div class="l-page" style="width:962px;">
        <div style="color:var(--subtext3)">{{ flow.flow?flow.flow.name:'' }}</div>
        <h2>{{ flow.name }}</h2>
        <Divider />
        <DynamicForm
          ref="form"
          v-model="formData"
          v-bind="flow"
          :loading="loading"
         to-update
          :submit="{
                      icon:'lianjieliu',
                      name:'发送流程'
                  }"
          @on-submit="$emit('event',{type:'submit',value:$event})"
        >
          <div class="flex-wrap" slot="buttons" style="margin-right:10px;">
            <Button size="large" @click="handleRetrive">
              <BaseIcon icon="md-undo" :size="20" />
              <Divider type="vertical" />撤回
            </Button>
          </div>
        </DynamicForm>
      </div>

      <div style="width:400px;margin-left:20px;">
        <BaseSegment title="流程状态" :disabled="true">
          <dynamic_FlowSettings
            :value="flow.data?flow.data.FlowSetting:{}"
            :option="flow.flow && flow.flow.version?flow.flow.version.option:{}"
            :editable="false"
            :db="{...formData,...flow.data}"
            :ediable="false"
            :current="flow.state"
          ></dynamic_FlowSettings>
        </BaseSegment>
        <BaseSegment title="历史记录" :disabled="true">
          <Timeline>
            <template v-for="n in flow.nodes">
              <TimelineItem :key="n.id">
                <div class="flex-wrap flex-between" style="color:var(--subtext2);font-weight:bold">
                  <span style="color:var(--primary)">start - 基础信息填报</span>
                  {{ moment(n.created_at).format('YYYY-MM-DD HH:mm:ss') }}
                </div>
                <BaseTpl :data="`\${user:${n.created_by}}提交了表单 (查看)`" />
              </TimelineItem>
            </template>
          </Timeline>
        </BaseSegment>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { mapGetters } from "vuex";
import DynamicForm from "@/components/form/dynamic";
import dynamic_FlowSettings from "@/components/form/dynamic_controls/FlowSettings";
export default {
  components: { DynamicForm, dynamic_FlowSettings },
  computed: {
    ...mapGetters("session", ["session"])
  },
  data() {
    return {
      formData: {},
      loading: false
    };
  },
  props: {
    flow: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    flow: {
      handler(f) {
        this.formData = { ...f.data };
      },
      immediate: true,
      deeply: true
    }
  },
  methods: {
    submit() {
      this.$refs.form.handleSubmit();
    },
    handleRetrive() {}
  }
};
</script>
  
  <style lang="less" scoped>
.l-page-wrap {
  margin: 40px auto;
  width: 1400px;
}

.l-page {
  background: var(--bg3);
  padding: 20px;
}
</style>