<style lang="less">
.tox-edit-area {
	cursor: text;
}
.tox-tinymce {
	border-color: var(--border);
}
</style>
<template>
	<textarea class="container"></textarea>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	data() {
		return {
			data: "",
			height: 500,
		};
	},
	computed: {
		...mapGetters("session", ["session"]),
	},
	props: {
		value: {
			type: String,
			default: "",
		},
	},
	watch:{
		value:{
			
			handler(v){
				if(!v && tinymce.activeEditor)
					tinymce.activeEditor.setContent(v);
			}
		},
	},
	methods: {
		submit() {
			return tinymce.activeEditor.getContent();
		},
		calcHeight() {
			let bottom = this.$el.getBoundingClientRect().bottom;
			let height = bottom - this.$el.getBoundingClientRect().top;
			let newHeight = height;
			if (Math.abs(newHeight - this.height) > 10) this.height = newHeight;
		},
		init() {
			var that = this;
			tinymce.init({
				setup: function (e) {
					e.on("change", (e) => {
						that.$emit("input", e.level.content);
					});
				},
				target: this.$el,
				language: "zh-Hans",
				height: "100%",
				width: "680px",
				branding: false,
				statusbar: false,
				forced_root_block: "",
				toolbar:
					"bold italic underline strikethrough aligncenter alignnone fontselect fontsizeselect image link tpImportword",
				font_formats:
					"微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;仿宋=仿宋;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif",
				fontsize_formats: "11px 12px 14px 16px 18px 24px 36px 48px",
				menubar: false,
				plugins: "tpImportword image link",
				images_upload_handler: (blobinfo, on_success, on_fail) => {
					let file = blobinfo.blob();
					this.$cos.upload(
						[file],
						{
							onSuccess: (files) => {
								on_success(files[0].url);
							},
							onFail: (err) => {
								on_fail(err);
							},
						},
						{
							coskey: this.session.coskey,
						}
					);
				},
				file_picker_callback: (callback, value, meta) => {
					var that = this;
					var filetype =
						".pdf, .txt, .zip, .rar, .7z, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .mp3, .mp4";
					switch (meta.filetype) {
						case "image":
							filetype = ".jpg, .jpeg, .png, .gif";
							break;
						case "media":
							filetype = ".mp3, .mp4";
							break;
						case "file":
						default:
					}
					var input = document.createElement("input");
					input.setAttribute("type", "file");
					input.setAttribute("accept", filetype);
					input.click();
					input.onchange = function () {
						var file = this.files[0];
						that.$cos.upload(
							[file],
							{
								onSuccess: (files) => {
									callback(files[0].url);
								},
							},
							{
								coskey: that.session.coskey,
							}
						);
					};
				},
			});
		},
	},
	beforeDestroy() {
		if(tinymce.activeEditor)
			tinymce.remove(tinymce.activeEditor)
	},
	mounted() {
		this.$nextTick(() => {
			this.init();
			if(this.value && tinymce.activeEditor)
				tinymce.activeEditor.setContent(this.value);
		});
	},
};
</script>

<style>
</style>