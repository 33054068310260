<style lang="less">

.ugo-item-user{
  width:116px;height:38px;margin:1px;padding:0 10px;background:#fff;
  cursor: pointer;

}

.ugo-item-user:hover{
  background:gold;
  transition: all 0.3s;
}

.ugo-item-user-selected{
  background:gold;
  color:#fff;
}

.ivu-select-dropdown{
  max-height:400px;
}
</style>
<template>
  <div class="pm-embed-control flex-wrap" style="color:#aaa;align-items:flex-start;width:100%;position:relative;height:100%">
    
    <Dropdown trigger="custom" :visible="showDropdown" transfer placement="bottom-start" style="width:100%;display:block;height:100%;" @on-clickoutside="handleClickOut">
      <div class="value-wrap" style="cursor:pointer" @click="showDropdown=true;searchText=''">
        <template v-if="selected.length == 0">
          请选择
        </template>
        <template v-else>
          <template v-for="user in selected_users">
            <template v-if="user.id">
            <BaseAvatar :key="user.id" size="20" :src="user.avatar">{{user.name?user.name[0]:'-'}}</BaseAvatar>
            <span :key="user.id">
              {{user.name}}
            </span>
            </template>
          </template>
        </template>
        </div>
      <DropdownMenu slot="list">
      <div class="" style="max-width:600px;background:#eee;position:relative;max-height:300px;overflow:hidden;">
        <template v-if="option.groups">
          <template v-if="prev">
            <div @click="current=prev;prev=null">Back</div>
          </template>
          <template v-if="current.groups">
            <template v-for="o in current.groups">
              <div :key="o.key">
                <BaseAvatar shape="rect" :src="o.avatar">{{o.name}}</BaseAvatar>
              </div>
            </template>
          </template>
          <template v-if="current.users">
            <template v-for="o in current.groups">
              <div :key="o.key">
                <BaseAvatar shape="rect" :src="o.avatar">{{o.name}}</BaseAvatar>
              </div>
            </template>
          </template>
        </template>
        <template v-else>
          <div class="flex-wrap split5" style="width:100%;padding:5px"><Input v-model="filterText" search clearable style="width:calc(100% - 70px)"  /><Button @click="$emit('input',[]);selected=[];showDropdown=false;">清除</Button></div>
           
           <div class="flex-wrap" style="max-height:140px;overflow-y:auto;flex-wrap:wrap;overflow-x:hidden;">
              <template v-for="o in filteredUsers">
                <div class="flex-wrap ugo-item-user" :class="{
                  'ugo-item-user-selected':selected && selected.includes(o.id)
                }" :key="o.key" style="" @click="handleClick(o)">
                  <BaseAvatar :src="o.avatar">{{o.name[0]}}</BaseAvatar>
                  <div style="margin-left:5px">{{o.name}}</div>
                </div>
              
              </template>
              </div>
            </template>
     
       
          </div>
         <div class="flex-wrap flex-between" v-if="multiple" style="width:100%;height:40px;padding:0 10px;background:#fff;border-top:1px solid #ddd;">
          <div class="flex-wrap split5">
            <div>已选择 {{selected.length}}</div>
            <Button>全选</Button>
            <Button>取消</Button>
            </div>
             <Button icon="md-checkmark" @click="handleConfirm" type="primary" size="small">确认</Button>
        </div>
      </DropdownMenu>
    </Dropdown>
      
  </div>
</template>

<script>
export default {
  name:"用户选择",
  icon:'xialaliebiao',
  data(){
    return {
      prev:false,
      current:{},
      showDropdown:false,
      filterText:"",
      selected:[]
    }
  },
  watch:{
    value:{
      handler(v){
        this.filterText = ""
        if(Array.isArray(this.value)){
          this.selected = this.value
        }else if(this.value){
          this.selected = this.multiple?[...this.value]:(this.value?[this.value]:[])
        }else{
          this.selected = []
        }
      }
    },
  },
  mounted(){
    this.filterText = ""
    if(this.option.groups)
      this.current  = {groups:this.option.groups}
    if(Array.isArray(this.value)){
      this.selected = this.value
    }else if(this.value){
      this.selected = this.multiple?[...this.value]:(this.value?[this.value]:[])
    }else{
      this.selected = []
    }
  },
  props:['option','value','editable'],
  computed:{
    users(){
      return this.option.users.filter(v=>v.name)
    },
    filteredUsers(){
      if(this.filterText){
              return this.option.users.filter(v=>v.name && v.name.includes(this.filterText))
      }else{
        return this.option.users.filter(v=>v.name)
      }
    },
    multiple(){
      return this.option.multiple
    },
    user(){
      return this.users.find(v=>v.id == this.value) || {}
    },
    selected_users(){
      return this.selected.map(v=>this.users.find(u=>u.id == v) || {})
    }
  },
  methods:{
    handleClickOut(e){
      if(!e.path || !e.path.find(v=>v.className=='ivu-dropdown-menu'))
        this.showDropdown = false
    },
    handleClick(o){
      if(this.multiple){
        let index = this.selected.findIndex(v=>v == o.id)
        if(index == -1)
          this.selected.push(o.id)
        else
          this.selected.splice(index,1)
      }else{
        this.selected = [o.id]
        this.$emit('input',o.id)
        this.showDropdown = false
      }
    },
    handleConfirm(){
      this.$emit('input',this.selected)
      this.showDropdown = false
    }
  }
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>