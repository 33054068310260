<template>
  <div class="l-full" style="color:#aaa;padding:5px;color:var(--primary);">
    {{config ? config.text : ''}}
  </div>
</template>

<script>
export default {
  cname:"说明文字",
  initModel(e){
    e.config = {text:"填写说明文字"}
  },
  props:['config'],
  properties:[{
    label:"文本内容",
    key:"text",
    control:"Text",
    editable:true
  }]
}
</script>

<style lang="less">
.l-full{
  position: relative;
  height:100%;
  width:100%;
}
</style>