<template>
    <div class="container flex-wrap flex-line">
      <div style="flex-shrink:0;width:140px;height:100%;border-right:1px solid var(--border);overflow-y:auto;"
      >
      
         <BaseMenu 
            :menus="menus"
            :actived="actived"
            @on-click="handleOpen"
        >
        </BaseMenu>
      </div>
      <div style="width:calc(100% - 140px);height:100%;position:relative;">
        <div class="flex-wrap" style="height:60px;padding:0 15px;">
          <div>
            <div style="font-size:16px;font-weight:bold;">{{ activedMenu.name }}</div>
            <div style="font-size:12px;color:var(--subtext2)">{{ activedMenu.description }}</div>
          </div>
          <div></div>
          </div>
          <div style="height:calc(100% - 60px);position:relative;">
            
          <router-view></router-view>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  import { mapGetters } from 'vuex'
  import responsableMixin from '@/mixins/responsable'
  export default {
    routerOption: {
      as_default: true,
      as_frame:true
    },
    mixins:[responsableMixin],
    mounted(){
      
    },
    computed: {
      ...mapGetters('cm', ['permission']),
      sm(){
        return this.w < 1200
      },
      activedMenu(){
        return this.menus.find(m=>m.key == this.actived) || {}
      },
      menus(){
        const items = [
          {
            name:"投标情况",
            key:"info",
            icon:"bid",
            description:"投标情况分析"
          },
          {
            name:"投标工作",
            key:"work",
            icon:"examine",
            description:"投标工作安排"
          },
          {
            name:"开标情况",
            key:"open",
            icon:"renwu1",
            description:"开标情况登记"
          },
          ]
          return items
        },
        actived(){
          return this.$route.path.slice(this.$route.path.lastIndexOf('/')+1)
        },
        
    },methods:{
      handleOpen(e){
        this.RouteTo(e)
        localStorage.setItem('cm-route-key',e)
      }
    }
  }
  </script>
  
  <style lang="less">
  </style>