<template>
  <div class="container flex-wrap flex-line align-stretch relative" style="padding:20px;background:var(--bg3);">
    <div style="width:300px;border:1px solid var(--border);padding:10px;background:var(--bg3);flex-shrink:0;">
      <Button icon="md-add" type="primary" @click="handleCreate">新建角色</Button>
      <div style="margin-top:10px;">
        <template v-for="r in roles">
          <div :key="r.id" class="role-item" :class="{'role-item-selected':selected == r.id}" @click="handleSelect(r)">
            <div class="flex-wrap">
              <div class="role-item-icon-wrap">
                <BaseIcon :icon="r.icon || 'md-person'" :size="20"></BaseIcon>
              </div>
              <div style="margin-left:14px">
                <div class="role-item-title">{{r.name}}</div>
                <div class="role-item-sub">{{r.description}}</div>
              </div>
            </div>

          </div>
        </template>
      </div>
    </div>
    <div style="flex-grow:1;margin-left:20px;background:var(--bg3);overflow:hidden;border:1px solid var(--border);position:relative;">
      <Spin fix v-if="loadingRole">
        <BaseLoading />
      </Spin>
      <template v-if="selected_item.id">
        <div class="flex-wrap flex-between" style="border-bottom:1px solid var(--border);height:40px;padding:0 10px;">
          <div class="flex-wrap">
            <BaseIcon :icon="selected_item.icon" :size="22" style="margin-right:10px" />
            <div style="font-size:16px;font-weight:bold">{{selected_item.name}}</div>
          </div>
          <div class="split5">
            <template v-if="role.systemd">
              系统预设角色
            </template>
            <template v-else>
              <a style="color:var(--primary)" @click="handleEdit(selected_item)">修改角色</a>
            <a style="color:var(--error)" @click="handleDelete(selected_item)">删除角色</a>
            </template>
            
          </div>
        </div>
        <div style="padding:10px;height:calc(100% - 30px);overflow-y:auto;">
         
          <BaseSegment title="操作权限">
          
            <div style=";max-width:400px;">
              <Row  justify="space-between" style="font-size:16px;margin-bottom:5px;border-bottom:1px solid var(--border);">
                <Col :span="12">
                  模块
                </Col>
                <Col :span="2" align="right">
                  状态
                </Col>
              </Row>
              <template v-for="p in permissions">
                <Row :key="p.key" justify="space-between" style="margin-top:10px;font-size:16px;">
                  <Col :span="12">

                  <BaseIcon :icon="p.icon"></BaseIcon> {{p.name}}
                  </Col>
                  
                  <Col :span="4" align="right">
                  <i-switch  size="large" :value="permission_mods.includes('all') || permission_mods.includes(p.key)" @on-change="handleUpdateModule(p.key)" >
                      <span slot="open">ON</span>
        <span slot="close">OFF</span>

                  </i-switch>
                  </Col>
                </Row>
              </template>
            </div>

          </BaseSegment>
        

          <BaseSegment :title="`角色成员`">

            <div style="margin-bottom:10px;">
              <a @click="handleEditUsers">
                <BaseIcon icon="md-add" /> 添加用户</a>
                
            </div>
            <template v-for="u in role.users">
              <div class="flex-wrap" :key="u.id">
                 <BaseNameBoard :uid="u.id" :key="u.id" style="width:100px;padding:2px 5px;margin-right:10px;flex-shrink:0" />

                 <Select style="width:200px" v-model="u.extra" placeholder="所有部门" clearable>
                  <Option v-for="d in def_deps" :key="d.id" :value="d.id">
                    {{d.name}}
                  </Option>
                 </Select>
                
              </div>
            </template>
             <div class="flex-wrap flex-right">
                <Button type="primary" @click="handleSaveUsers">提交</Button>
              </div>
          </BaseSegment>
        </div>
      </template>

      <BaseEmpty v-else msg="请选择角色查看详情" style="min-height:600px">
      </BaseEmpty>

    </div>
    <Modal v-model="showSelectUser" title="选择用户" footer-hide :mask-closable="false">
      <div>
        <BaseUserSelect v-model="selected_users" :option="{users,multiple:true}"></BaseUserSelect>
        <div class="flex-wrap flex-right" style="margin-top:10px">
          <Button style="margin-right:10px" @click="showSelectUser=false">取消</Button>
          <Button type="primary" @click="handleSelectChange">提交</Button>
        </div>
      </div>
    </Modal>

    <Modal v-model="showEditor" :title="formData.id?'修改角色':'创建角色'" footer-hide :width="400">
      <Form ref="formCreate" :model="formData">
        <FormItem required label="角色名称" prop="name">
          <Input v-model="formData.name" clearable placeholder="请输入" />
        </FormItem>
        <FormItem label="角色描述" prop="description">
          <Input type="textarea" v-model="formData.description" clearable placeholder="请输入" />
        </FormItem>
        <div class="flex-wrap flex-right">
           <Button style="margin-right:20px" @click="showEditor=false">取消</Button>
          <Button type="primary" @click="handleSubmit">创建</Button>
        </div>
      </Form>
    </Modal>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props:{
    permissions:{
      type:Array,
      default:()=>([])
    },
    mod:{
      type:String,
      default:"system"
    }
  },
  data() {
    return {
      showEditor: false,
      selected_users:[],
      showSelectUser:false,
      loadingRole:false,
      editing_modules:false,
      selected: 1,
      def_deps:[{
        id:"67324392",
        name:"项目管理事业部"
      },{
        id:"67324391",
        name:"房建监理事业部"
      },{
        id:"68827000",
        name:"市政监理事业部"
      },{
        id:"67324393",
        name:"造价咨询事业部"
      },{
        id:"69119063",
        name:"装修管理事业部"
      },{
        id:"69172002",
        name:"BIM"
      }],
      formData: {},
      role: {
        users: [],
        permissions: [],
      },
    }
  },
  mounted() {
    this.getRoles()
  },
  computed: {
    ...mapGetters('session',['session','users']),
    ...mapGetters('cm', ['permission','project_list', 'roles']),
    selected_item() {
      return this.roles.find(v => v.id == this.selected) || {}
    },
    permission_mods(){
      if(Array.isArray(this.role.permissions)){
        return this.role.permissions.filter(v=>v.target_type == 'cm-mod' || v.target_type =='all').map(v=>v.target_id)
      }else{
        return []
      }
    }
  },
  methods: {
    getRoles(){
      this.$api.get(`roles?mod=${this.mod}`).then(res=>{
        this.roles = res.data.data
        if(this.roles.length > 0)
          this.handleSelect(roles[0])
      })
    },
    handleSelect(role){
      this.selected = role.id
      this.loadingRole = true
      this.$api.get("roles/"+role.id).then(res=>{
        this.role = res.data.data
      }).finally(()=>{
        this.loadingRole =false
      })
    },  
    handleCreate(){
      this.formData = {}
      this.showEditor = true
    },
    handleSubmit(){
      this.$refs.formCreate.validate(valid=>{
        if(valid){
          let action = this.formData.id?"cm/patchRole":"cm/addRole"
          this.$store.dispatch(action,this.formData).then(()=>{
            this.showEditor = false
            this.formData = {}
          })
        }else{
          this.Error("验证失败")
        }
      })
    },
    handleEdit(r){
      this.formData = {...r}
      this.showEditor = true
    },
    handleDelete(r){
      this.Confirm(`确认删除角色 (<span style="color:red">${r.name}</span>)?`,()=>{
        this.$store.dispatch("cm/removeRole",r.id).then(res=>{
        if(this.roles.length == 0)
          this.selected = -1
        else
          this.selected = this.roles[0]
        this.$Notice.success({title:"删除成功"})
      })}
      )
    },
    handleSelectChange(){
      let exist_users = this.role.users.filter(v=>this.selected_users.includes(v.id))
      let new_users = this.selected_users.filter(v=>!this.role.users.find(u=>u.id == v)).map(v=>this.users.find(u=>u.id == v))
      this.role.users = exist_users.concat(new_users)
      this.showSelectUser = false
    },
    handleEditUsers(){
      this.selected_users = [...this.role.users.map(v=>v.id)]
      this.showSelectUser = true
    },
    handleSaveUsers(){
      this.$api.post(`/cm/roles/${this.selected}/user-objects`,this.role.users).then(res=>{
        this.$Notice.success({title:"提交成功"})
         this.$store.dispatch("cm/getSystemData")
        this.showSelectUser = false
      }).catch(e=>{
        this.Error(e)
      })
    },
    handleUpdateModule(key){
      this.$api.post(`/cm/roles/${this.selected}/toggle-mod`,{key}).then(res=>{
        this.$Notice.success({title:"修改成功"})
        this.handleSelect(this.role)
        if(this.permission && this.permission.roles.includes(this.selected)){
          this.$store.dispatch("cm/auth")
        }
      }).catch(this.Error)
    }
  }
}
</script>

<style lang="less">
.role-item {
  margin-bottom: 10px;
  cursor: pointer;
  background: var(--bg2);
  color: var(--text2);
  padding: 5px;
  padding-left: 10px;
  .role-item-icon-wrap {
    background: var(--bg1);
    flex-shrink: 0;
    color: var(--hover-text);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }
  .role-item-title {
    font-size: 16px;
    font-weight: bold;
  }

  .role-item-sub {
    font-size: 12px;
    color: var(--subtext3);
  }
}

.role-item:hover {
  color: var(--hover-text);
}

.role-item-selected,
.role-item:active,
.role-item-selected:hover {
  border-left: 4px solid var(--primary);
  background:linear-gradient(to left,var(--primary),var(--bg2));
  color: var(--text3);
  .role-item-icon-wrap {
    color: var(--text3);
  }
  .role-item-sub {
    color: var(--subtext3);
  }
}
</style>