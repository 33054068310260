
<style lang="less" >
.pd-embed-control{
  position: relative;
  height:100%;
  width:100%;
}

.pd-embed-control-editable{
  background:rgb(239, 239, 201) !important;
}
</style>
<template>
  <div class="pd-embed-control flex-wrap" style="padding:5px;height:100%;" :class="{'pd-embed-control-editable':editable}">
    <template v-if="options">
      <div class="flex-wrap split5">
      <template v-for="o in options">
        
        <Checkbox :key="o" v-model="value[o]" :disabled="!editable" @input="$emit('input',value)">{{o}}</Checkbox>
      </template>
      </div>
    </template>
    <Checkbox v-model="_value" v-else :disabled="!editable" @input="$emit('input',$event)">{{value?'启用':''}}</Checkbox>
  </div>
</template>

<script>
  export default {
    props:['value','editable','option'],
    mounted(){
      if(this.options){
        this.value = {}
        this.$emit('input',{})
      }
    },
    controlInfo:{
      
    },
    computed:{
      _value:{
        get(){
          return this.value?true:false
        },
        set(v){
          this.value = v
          this.$emit('input',v)
        }
      },
      options(){
        if(!this.option || !this.option.options)
          return null
        else
          return this.option.options
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>